import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import clsx from 'clsx';
import Imgix from 'services/imgix';

import Button from 'commons/components/button';
import Modal from 'commons/components/modal';

import feathers from 'services/feathers';

import ChambreForm from './chambreForm';

const useStyles = makeStyles(theme => ({
  list: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  item: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.space.medium,

    '& h2': {
      ...theme.font.weight.normal,
      textAlign: 'center',
      display: 'block',
      marginLeft: 0,
      marginBottom: theme.space.tiny,
    },
    '& h3': {
      ...theme.font.weight.normal,
      textAlign: 'center',
      display: 'block',
      marginBottom: theme.space.medium,
      color: theme.color.grey.dark,
    },
  },
  nom: {},
  details: {
    display: 'flex',

    '& ul': {
      marginLeft: theme.space.small,
    },

    '& li': {
      marginBottom: theme.space.tiny,
    },
  },
  photos: {
    display: 'flex',
    margin: `${theme.space.tiny} 0`,

    '& img': {
      display: 'block',
      marginRight: theme.space.tiny,
    },
  },
  button: {
    padding: '0.5rem 1rem',
    marginLeft: 'auto',
  },
  red: {
    background: theme.color.red.dark,
  },
}));

const Liste = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;
  const classes = useStyles();

  return (
    <div>
      <h2>{t('chambres.form.chambres')}</h2>

      <ul className={classes.list}>
        {etablissement.chambres.map(
          (
            {
              nom,
              label,
              photo,
              photo_sdb,
              autres_photos,
              quantite,
              superficie,
              capacite,
            },
            index,
          ) => (
            <li className={classes.item} key={index}>
              <h2>{nom}</h2>
              <h3>{t(`shared:chambres_labels.${label}`)}</h3>
              <div className={classes.details}>
                <Imgix photo={photo} height={70} />
                <ul>
                  <li>
                    {t('chambres.liste.quantite')} :{' '}
                    <strong>
                      {quantite}
                      {t('chambres.chambres')}
                    </strong>
                  </li>
                  <li>
                    {t('chambres.liste.superficie')} :{' '}
                    <strong>{superficie} m²</strong>
                  </li>
                  <li>
                    {t('chambres.liste.capacite')} :{' '}
                    <strong>
                      {capacite}
                      {t('chambres.personnes')}
                    </strong>
                  </li>
                </ul>
              </div>
              <div className={classes.photos}>
                {[photo_sdb, ...autres_photos].map(e => (
                  <Imgix photo={e} height={35} key={e} />
                ))}

                <Modal
                  renderContent={close => (
                    <ChambreForm chambreIndex={index} onSuccess={close} />
                  )}
                  renderButton={open => (
                    <Button className={classes.button} onClick={open}>
                      {t('common:editer')}
                    </Button>
                  )}
                />
                {etablissement.chambres.length > 1 && (
                  <Button
                    className={clsx(classes.button, classes.red)}
                    onClick={async () => {
                      if (window.confirm(t('common:etes-vous-sur'))) {
                        await feathers.service('etablissements').patch(null, {
                          action: 'chambres',
                          array: {
                            action: 'remove',
                            index,
                          },
                        });
                      }
                    }}
                  >
                    {t('common:supprimer')}
                  </Button>
                )}
              </div>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default compose(inject('store'), observer)(Liste);
