import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import * as Yup from 'yup';

import etablissementSchema from 'commons/schemas/etablissement';

import Field from 'commons/components/field';
import Button from 'commons/components/button';
import Form from 'commons/components/form';
import { notify } from 'commons/components/notifier';

const useStyles = makeStyles(theme => ({
  explication: {
    fontStyle: 'italic',
    display: 'flex',

    '& input': {
      width: '2rem',
    },
  },
  details: {
    width: 'auto',
    marginLeft: theme.space.small,
  },
}));

const ContactForm = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();
  const { etablissement } = store;

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'gestion')}
      initialValues={etablissement.gestion}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'gestion', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, errors, values }) => (
        <Form styled showDirty>
          <h2>{t('presentation.gestion.title')}</h2>
          <p className={classes.explication}>
            {t('presentation.gestion.explication')}
          </p>
          <label htmlFor="pms_details">
            {t('presentation.gestion.no_pms')}{' '}
            <Field
              className={classes.details}
              name="pms_details"
              disabled={values.no_pms}
            />
          </label>
          <p className={classes.explication}>
            <Field type="checkbox" name="no_pms" />
            {t('presentation.gestion.no_pms_explication')}
          </p>
          <label htmlFor="cm_details">
            {t('presentation.gestion.no_channel_manager')}{' '}
            <Field
              name="cm_details"
              className={classes.details}
              disabled={values.no_cm}
            />
          </label>
          <p className={classes.explication}>
            <Field type="checkbox" name="no_cm" />
            {t('presentation.gestion.no_channel_manager_explication')}
          </p>
          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(ContactForm);
