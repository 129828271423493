import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& h2': {
      marginBottom: theme.space.tiny,
      marginTop: theme.space.small,
      textAlign: 'center',
    },
    '& ul': {
      width: '100%',
    },
    '& li': {
      cursor: 'pointer',
      display: 'flex',
      position: 'relative',
      justifyContent: 'space-between',
      border: `1px solid ${theme.color.dark}`,
      padding: theme.space.tiny,
      margin: `${theme.space.tiny} 0`,
      paddingBottom: theme.space.medium,
    },
  },
  moyenne: {
    ...theme.font.weight.bold,
    color: theme.color.dark,
  },
  created_at: {
    color: theme.color.grey.medium,
    position: 'absolute',
    bottom: theme.space.tiny,
    left: theme.space.tiny,
    ...theme.font.size.tiny,
  },
}));

const LastEvaluations = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation('accueil');
  const history = useHistory();

  const { evaluations } = store;

  return (
    <div className={classes.wrapper}>
      <h2>{t('last_evaluations.titre')}</h2>
      {evaluations ? (
        evaluations.length ? (
          <ul>
            {evaluations.map(({ moyenne, dossier, created_at }) => (
              <li onClick={() => history.push('/compte/evaluations')}>
                <div>
                  {dossier.prenom} {dossier.nom}
                </div>
                {moyenne &&
                  moyenne !== -1 &&
                  (console.log('moyenne', moyenne),
                  (
                    <div className={classes.moyenne}>
                      {moyenne.toFixed(2)}/10
                    </div>
                  ))}
                <div className={classes.created_at}>
                  {dayjs(created_at).format('LLL')}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('last_evaluations.aucun')}</p>
        )
      ) : null}
    </div>
  );
};

export default compose(inject('store'), observer)(LastEvaluations);
