import React from 'react';
import { useTranslation } from 'react-i18next';

import DescriptionForm from './descriptionForm';
import ContactClientForm from './contactClientForm';
import ContactHoremeForm from './contactHoremeForm';
import GestionForm from './gestionForm';
import Infos from './infos';
import Langues from './langues';

const Presentation = () => {
  const { t } = useTranslation('etablissement');

  return (
    <>
      <Infos />
      <DescriptionForm />
      <ContactClientForm />
      <ContactHoremeForm />

      <GestionForm />

      <Langues />
    </>
  );
};

export default Presentation;
