import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

const Number = props => {
  const { t } = useTranslation('shared');

  return (
    <NumberFormat
      displayType={'text'}
      decimalScale={2}
      decimalSeparator={t('decimal_separator')}
      thousandSeparator={' '}
      {...props}
    />
  );
};

export default Number;
