import React, { useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import feathers from 'services/feathers';
import Flags from 'react-flags-select';
import ReactCountryFlag from 'react-country-flag';

import 'react-flags-select/css/react-flags-select.css';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: '8rem',
  },
  list: {
    display: 'flex',

    '& li': {
      cursor: 'pointer',
      margin: theme.space.tiny,
    },
  },
}));

const Langues = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation('etablissement');
  const flagsRef = useRef(null);

  const toggleLangue = l => {
    const langues = new Set(store.etablissement.langues);

    langues.has(l) ? langues.delete(l) : langues.add(l);
    feathers
      .service('etablissements')
      .patch(null, { action: 'langues', data: Array.from(langues) });
  };

  return (
    <div className={classes.wrapper}>
      <h2>{t('presentation.langues')}</h2>
      {store.etablissement.langues.length <= 9 && (
        <Flags
          ref={flagsRef}
          placeholder={t('presentation.ajouter-langue')}
          countries={store.etablissement.langues}
          blackList={true}
          onSelect={e => {
            toggleLangue(e);
            flagsRef.current.setState({ selected: '' });
          }}
        />
      )}
      <ul className={classes.list}>
        {store.etablissement.langues.map(l => (
          <li key={l}>
            <ReactCountryFlag
              onClick={() => toggleLangue(l)}
              countryCode={l}
              svg
              style={{
                width: '2rem',
                height: '2rem',
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default compose(inject('store'), observer)(Langues);
