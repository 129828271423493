module.exports = [
  'grand-lit-double-200+',
  'grand-lit-double-180',
  'grand-lit-double-160',
  'lit-double-140',
  'lits-jumeaux',
  'canape-lit',
  'lit-simple',
  'lit-superpose',
];
