import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';

import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import UpdateLocale from 'dayjs/plugin/updateLocale';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

import App from './App';
import './i18n';

dayjs.extend(LocalizedFormat);
dayjs.extend(UpdateLocale);
dayjs.extend(CustomParseFormat);

ReactDOM.render(<App />, document.getElementById('root'));
