import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Modal from 'commons/components/modal';

import EmailForm from './emailForm';

const useStyles = makeStyles(theme => ({
  link: {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.space.small,
  },
}));

const MotDePasseOublie = () => {
  const { t } = useTranslation('login');
  const classes = useStyles();

  return (
    <Modal
      renderButton={open => (
        <a onClick={open} className={classes.link}>
          {t('mot-de-passe-oublie')}
        </a>
      )}
      renderContent={close => <EmailForm close={close} />}
    />
  );
};

export default MotDePasseOublie;
