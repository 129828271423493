import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
  },
  green: {
    color: theme.color.green.dark,
  },
  red: {
    color: theme.color.red.dark,
  },
  title: {
    textTransform: 'uppercase',
    ...theme.font.weight.bold,
  },
  description: {},
  notice: {
    fontStyle: 'italic',
  },
}));

const Tarif = ({
  tarif,
  sections = ['title', 'description', 'notice'],
  className,
}) => {
  const { t } = useTranslation('shared');
  const classes = useStyles();

  const {
    nom,
    remboursable,
    remboursable_jours,
    remboursable_heure,
    repas,
    penalite,
    penalite_pourcentage,
    prepaiement,
    prepaiement_type,
    prepaiement_type_pourcentage,
    prepaiement_moment,
    prepaiement_moment_jours,
    preautorisation,
    preautorisation_type,
    preautorisation_type_pourcentage,
  } = tarif;

  let buffer = [];
  remboursable != null &&
    buffer.push(t(`tarif.remboursable.${remboursable.toString()}`));

  repas && repas !== 'AUCUN' && buffer.push(t(`tarif.repas.${repas}`));

  const title = buffer.join(' ');

  buffer = [];

  if (remboursable) {
    if (remboursable_jours === 0 && remboursable_heure != null) {
      buffer.push(
        t(`tarif.remboursable_heure`, {
          heure: remboursable_heure.toString().padStart(2, '0'),
        }),
      );
    } else if (remboursable_jours !== 0) {
      buffer.push(t(`tarif.remboursable_jours`, { count: remboursable_jours }));
    }
  }

  if (prepaiement != null) {
    buffer.push(t(`tarif.prepaiement.${prepaiement.toString()}`));
    if (prepaiement) {
      if (prepaiement_type) {
        buffer.push(
          t(`tarif.prepaiement_type.${prepaiement_type}`, {
            pourcentage: prepaiement_type_pourcentage,
          }),
        );
      }
      prepaiement_moment &&
        buffer.push(
          t(`tarif.prepaiement_moment.${prepaiement_moment}`, {
            count: prepaiement_moment_jours,
          }),
        );
      if (prepaiement_type) {
        if (
          prepaiement_type !== 'POURCENTAGE' ||
          prepaiement_type_pourcentage !== 100
        ) {
          buffer.push(
            t(`tarif.prepaiement_solde.${prepaiement_type}`, {
              pourcentage: prepaiement_type_pourcentage,
            }),
          );
        }
      }
    }
  }

  if (penalite && remboursable != null) {
    if (penalite === 'PREMIERE_NUIT') {
      buffer.push(t(`tarif.penalite.${penalite}.${remboursable.toString()}`));
    }
    if (penalite === 'POURCENTAGE') {
      buffer.push(
        t(`tarif.penalite.${penalite}.${remboursable.toString()}`, {
          pourcentage: penalite_pourcentage,
        }),
      );
    }
  }

  const description = buffer.join(' ');

  buffer = [];
  if (preautorisation && preautorisation_type) {
    buffer.push(t(`tarif.preautorisation`));
    buffer.push(
      t(`tarif.preautorisation_type.${preautorisation_type}`, {
        pourcentage: preautorisation_type_pourcentage,
      }),
    );
  }

  const notice = buffer.join(' ');

  return (
    <div className={clsx(className, classes.wrapper)}>
      {sections.includes('title') && <p className={classes.title}>{title}</p>}
      {sections.includes('description') && (
        <p className={classes.description}>
          <span className={repas === 'AUCUN' ? classes.red : classes.green}>
            {repas && `${t(`tarif.repas.${repas}`)}. `}
          </span>
          {description}
        </p>
      )}
      {sections.includes('notice') && (
        <p className={classes.notice}>{notice}</p>
      )}
    </div>
  );
};

export const Titre = ({ tarif }) => {
  const { t } = useTranslation('shared');
  return `${t(`shared:tarif.remboursable.${tarif.remboursable.toString()}`)}${
    tarif.repas !== 'AUCUN'
      ? ` - ${t(`shared:tarif.repas.${tarif.repas}`)}`
      : ''
  }`;
};

export default Tarif;
