const completeSchema = require('../schemas/etablissement/complete');

module.exports = ({ etablissement }) => {
  try {
    completeSchema.validateSync(etablissement, { abortEarly: false });
    return [];
  } catch (e) {
    return e.inner.map(({ path }) => path);
  }
};
