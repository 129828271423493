import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import ReactCountryFlag from 'react-country-flag';
import dayjs from 'dayjs';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  langue: {
    padding: `${theme.space.tiny} 0`,
    textAlign: 'center',
  },
  wrapper: {
    flex: 1,
    padding: theme.space.tiny,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '20rem',
    wordWrap: 'break-word',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.space.tiny,
  },
  message: {
    padding: `${theme.space.tiny} 0`,

    '&:last-child': {
      ...theme.font.weight.bold,
    },
  },
  date: {
    color: theme.color.grey.dark,
  },
  left: {
    marginLeft: theme.space.medium,
    textAlign: 'right',
    alignSelf: 'flex-end',
    maxWidth: '100%',
    overflow: 'auto',
  },
  right: {
    marginRight: theme.space.medium,
    textAlign: 'left',
    alignSelf: 'flex-start',
    maxWidth: '100%',
    overflow: 'auto',
  },
  messages: {
    maxHeight: '15rem',
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'auto',
  },
}));

const Messagerie = ({ dossier, className, renderField = () => null }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();

  const fromClient = dossier.parcours
    .filter(p => ['DEMANDE', 'CONFIRMATION'].includes(p.statut))
    .map(({ at, message }) => ({ at, message, from: 'CLIENT' }));
  const fromEtablissement = dossier.parcours
    .filter(p => ['OFFRE'].includes(p.statut))
    .map(({ at, message }) => ({ at, message, from: 'ETABLISSEMENT' }));

  const messages = [...fromClient, ...fromEtablissement]
    .sort((a, b) => new Date(a.at).getTime() - new Date(b.at).getTime())
    .filter(({ message }) => message);

  return (
    <>
      <div className={clsx(classes.wrapper, className)}>
        {messages.length > 0 && (
          <>
            <div className={classes.header}>
              <div>{t('messagerie.client')}</div>
              <div>{t('messagerie.vous')}</div>
            </div>
            <div className={classes.messages}>
              <div>
                {messages.map(({ at, message, from }) => (
                  <div
                    className={clsx(
                      classes.message,
                      from === 'ETABLISSEMENT' && classes.left,
                      from === 'CLIENT' && classes.right,
                    )}
                  >
                    <div>{message}</div>
                    <div className={classes.date}>
                      {dayjs(at).format('llll')}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={classes.langue}>
        {t('offre.langue')}{' '}
        <ReactCountryFlag
          svg
          countryCode={dossier.lang === 'fr' ? 'FR' : 'GB'}
          style={{
            width: '14px',
            height: '14px',
          }}
        />
      </div>
      {renderField()}
    </>
  );
};

export default Messagerie;
