import React from 'react';
import { useFormikContext } from 'formik';
import { withTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

import PhoneInput from 'react-phone-input-2';
import { Currency } from './number';
import { useTranslation } from 'react-i18next';
import 'react-phone-input-2/lib/style.css';

const PhoneOffreSMSField = ({ name, disabled, theme, label, freeSMS }) => {
  const { values, setFieldValue, errors, status } = useFormikContext();
  const [Price, setPrice] = React.useState(undefined);
  const [Rate, setRate] = React.useState(undefined);
  const { t } = useTranslation('shared');

  const calcFraisSMS = async country => {
    const accountSid = process.env.REACT_APP_TWILIO_ACCOUNT_SID;
    const authToken = process.env.REACT_APP_TWILIO_AUTH_TOKEN;
    // la marge que souhaite réaliser HoReMe sur les envois de sms, elle peut ainsi être modifiée à tout moment via les variables d'nevironnement
    let multiplicateur = process.env.REACT_APP_MARGE_SMS;


    let TwilioUrl =
      'https://pricing.twilio.com/v1/Messaging/Countries/' + country;

    let CurrencyConverterUrl =
      'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/eur.json';

    let TwilioHeaders = new Headers({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(accountSid + ':' + authToken),
    });

    let TwilioInit = {
      method: 'GET',
      headers: TwilioHeaders,
      mode: 'cors',
      cache: 'default',
    };

    // on obtient les tarifs SMS en $, il nous faut obtenir le taux de conversion en temps réel à chaque fois pour l'afficher au client en €
    const resC = await fetch(CurrencyConverterUrl);
    const rate = await resC.json();
    const resT = await fetch(TwilioUrl, TwilioInit);
    const prices = await resT.json();
    const price =
      country === 'gb'
        ? prices.inbound_sms_prices[0].current_price
        : prices.outbound_sms_prices[0].prices[0].current_price;

    const finalPrice = (price * rate.eur * multiplicateur).toFixed(2);
    setPrice(parseFloat(finalPrice));
  };

  const errorStyles =
    get(errors, name) != null
      ? {
          ...theme.error,
          border: '2px solid red',
        }
      : {};

  const disabledStyles =
    status && status.disabled
      ? {
          background: theme.card ? theme.card.color.light : theme.color.white,
        }
      : {};

  React.useEffect(() => {
    calcFraisSMS('fr');
  }, []);

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <PhoneInput
        containerStyle={{ height: '1.5rem' }}
        name={name}
        inputStyle={{
          height: '1.5rem',
          width: '100%',
          fontSize: '0.8rem',
          ...errorStyles,
          ...disabledStyles,
        }}
        buttonStyle={{ ...errorStyles }}
        placeholder=""
        country={'fr'}
        value={get(values, name)}
        inputProps={{ id: name }}
        onChange={(value, country) => {
          setFieldValue(name, value);
          calcFraisSMS(country.countryCode);
        }}
        disabled={disabled || (status && status.disabled)}
      />
      <div className="fraisSMS">
        <Currency
          className="frais"
          label={t('frais')}
          name="frais_SMS"
          disabled
          value={Price !== undefined && Price}
        ></Currency>
      </div>
    </>
  );
};

export default withTheme(PhoneOffreSMSField);
