import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';

import Tarif from 'commons/components/tarif';
import Form from 'commons/components/form';
import Field from 'commons/components/field';
import Button from 'commons/components/button';
import { notify } from 'commons/components/notifier';

import tarifSchema from 'commons/schemas/tarif';

import feathers from 'services/feathers';

const useStyles = makeStyles(theme => ({
  button: { display: 'block' },
}));

const TarifForm = ({ store, onSuccess = () => null, tarifIndex }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();

  const etablissement = store.etablissement;

  return (
    <Form.Formik
      validationSchema={tarifSchema}
      initialValues={
        etablissement.tarifs[tarifIndex] || {
          nom: null,
          remboursable: null,
          remboursable_jours: null,
          repas: null,
          penalite: null,
          penalite_pourcentage: null,
          prepaiement: null,
          prepaiement_type: null,
          prepaiement_type_pourcentage: null,
          preautorisation: null,
          preautorisation_type: null,
          preautorisation_type_pourcentage: null,
        }
      }
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers.service('etablissements').patch(null, {
            action: 'tarifs',
            array: etablissement.tarifs[tarifIndex]
              ? {
                  action: 'edit',
                  data: values,
                  index: tarifIndex,
                }
              : {
                  action: 'push',
                  data: values,
                },
          });

          resetForm();
          onSuccess();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, errors }) => (
        <Form styled showDirty={tarifIndex != null}>
          <Field label={t('tarifs.form.nom')} name="nom" />
          <Field
            label={t('tarifs.form.remboursable')}
            as="select"
            name="remboursable"
            onChange={e => {
              setFieldValue('remboursable', e.target.value === 'true');
            }}
          >
            <option value={null}>{t('common:choisir')}</option>
            <option value={true}>
              {t('tarifs.form.modifiable_et_annulable')}
            </option>
            <option value={false}>{t('tarifs.form.non_remboursable')}</option>
          </Field>
          <Field label={t('tarifs.form.repas.label')} as="select" name="repas">
            <option value={null}>{t('common:choisir')}</option>
            <option value={'AUCUN'}>{t(`tarifs.form.repas.aucun`)}</option>
            <option value={'PETIT_DEJEUNER'}>
              {t(`tarifs.form.repas.petit_dejeuner`)}
            </option>
            <option value={'DEMI_PENSION'}>
              {t(`tarifs.form.repas.demi_pension`)}
            </option>
            <option value={'PENSION_COMPLETE'}>
              {t(`tarifs.form.repas.pension_complete`)}
            </option>
          </Field>
          {values.remboursable && (
            <Field.Number
              label={t('tarifs.form.remboursable_jours')}
              placeholder={t('tarifs.form.jours_placeholder')}
              min={0}
              name="remboursable_jours"
              suffix={t('tarifs.form.jours_avant_arrivee')}
            />
          )}
          {values.remboursable_jours === 0 && (
            <Field name="remboursable_heure" as="select">
              {[...Array(24).keys()].map(i => (
                <option key={i} value={i}>
                  {t('tarifs.form.remboursable_heure', {
                    count: i.toString().padStart(2, '0'),
                  })}
                </option>
              ))}
            </Field>
          )}
          {values.remboursable != null && (
            <>
              <Field
                label={t(`tarifs.form.penalite.${values.remboursable}`)}
                as="select"
                name="penalite"
              >
                <option value={null}>{t('common:choisir')}</option>
                <option value={'PREMIERE_NUIT'}>
                  {t(`tarifs.form.premiere_nuit`)}
                </option>
                <option value={'POURCENTAGE'}>
                  {t(`tarifs.form.pourcentage`)}
                </option>
              </Field>
              {values.penalite === 'POURCENTAGE' && (
                <Field.Percent
                  placeholder={t('tarifs.form.pourcentage')}
                  name="penalite_pourcentage"
                />
              )}
            </>
          )}
          <Field
            label={t('tarifs.form.prepaiement')}
            as="select"
            name="prepaiement"
            onChange={e => {
              setFieldValue('prepaiement', e.target.value === 'true');
            }}
          >
            <option value={null}>{t('common:choisir')}</option>
            <option value={true}>{t('tarifs.form.prepaiement_oui')}</option>
            <option value={false}>{t('tarifs.form.prepaiement_non')}</option>
          </Field>
          {values.prepaiement && (
            <>
              <Field
                label={t('tarifs.form.prepaiement_type')}
                as="select"
                name="prepaiement_type"
              >
                <option value={null}>{t('common:choisir')}</option>
                <option value={'PREMIERE_NUIT'}>
                  {t(`tarifs.form.premiere_nuit`)}
                </option>
                <option value={'POURCENTAGE'}>
                  {t(`tarifs.form.pourcentage`)}
                </option>
              </Field>
              {values.prepaiement_type === 'POURCENTAGE' && (
                <Field.Percent
                  placeholder={t('tarifs.form.pourcentage')}
                  name="prepaiement_type_pourcentage"
                />
              )}
              <Field
                label={t('tarifs.form.prepaiement_moment')}
                as="select"
                name="prepaiement_moment"
              >
                <option value={null}>{t('common:choisir')}</option>
                <option value={'A_LA_RESERVATION'}>
                  {t(`tarifs.form.a_la_reservation`)}
                </option>
                <option value={'AVANT_ARRIVEE'}>
                  {t(`tarifs.form.avant_arrivee`)}
                </option>
              </Field>
              {values.prepaiement_moment === 'AVANT_ARRIVEE' && (
                <Field.Number
                  placeholder={t('tarifs.form.jours_placeholder')}
                  min={0}
                  name="prepaiement_moment_jours"
                  suffix={t('tarifs.form.jours_avant_arrivee')}
                />
              )}
            </>
          )}

          <Field
            label={t('tarifs.form.preautorisation')}
            as="select"
            name="preautorisation"
            onChange={e => {
              setFieldValue('preautorisation', e.target.value === 'true');
            }}
          >
            <option value={null}>{t('common:choisir')}</option>
            <option value={true}>{t(`common:oui`)}</option>
            <option value={false}>{t(`common:non`)}</option>
          </Field>
          {values.preautorisation && (
            <>
              <Field
                label={t('tarifs.form.preautorisation_type')}
                as="select"
                name="preautorisation_type"
              >
                <option value={null}>{t('common:choisir')}</option>
                <option value={'PREMIERE_NUIT'}>
                  {t(`tarifs.form.premiere_nuit`)}
                </option>
                <option value={'POURCENTAGE'}>
                  {t(`tarifs.form.pourcentage`)}
                </option>
              </Field>
              {values.preautorisation_type === 'POURCENTAGE' && (
                <Field.Percent
                  placeholder={t('tarifs.form.pourcentage')}
                  name="preautorisation_type_pourcentage"
                />
              )}
            </>
          )}
          <Tarif tarif={values} />
          <Button
            type="submit"
            loading={isSubmitting}
            className={classes.button}
          >
            {t('common:valider')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(TarifForm);
