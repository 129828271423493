import React from 'react';
import { useFormikContext } from 'formik';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    minHeight: '1.5rem',
    padding: '0.25rem 0.5rem',
    border: `1px solid ${theme.color.grey.light}`,

    '&[disabled]': {
      background: theme.color.grey.light,
    },
  },
  error: {
    ...theme.error,
  },
}));

export const Number = ({ name, label, className, disabled, ...props }) => {
  const { values, setFieldValue, errors, status } = useFormikContext();
  const classes = useStyles();
  const { t } = useTranslation('shared');

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}

      <NumberFormat
        decimalSeparator={t('decimal_separator')}
        className={clsx(
          classes.input,
          className,
          get(errors, name) != null && classes.error,
        )}
        value={get(values, name)}
        onValueChange={
          ({ floatValue }) =>
            setFieldValue(name, floatValue != null ? floatValue : null) // On évite de set undefined
        }
        disabled={disabled || (status && status.disabled)}
        {...props}
      />
    </>
  );
};

export const Currency = ({ name, label, disabled, ...props }) => {
  const { values, setFieldValue, errors, status } = useFormikContext();
  const classes = useStyles();
  const { t } = useTranslation('shared');

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}

      <NumberFormat
        decimalSeparator={t('decimal_separator')}
        className={clsx(
          classes.input,
          get(errors, name) != null && classes.error,
        )}
        value={get(values, name)}
        onValueChange={
          ({ floatValue }) =>
            setFieldValue(name, floatValue != null ? floatValue : null) // On évite de set undefined
        }
        thousandSeparator={' '}
        suffix={' €'}
        decimalScale={2}
        disabled={disabled || (status && status.disabled)}
        {...props}
      />
    </>
  );
};

export const Percent = ({ name, label, disabled, ...props }) => {
  const { values, setFieldValue, errors, status } = useFormikContext();
  const classes = useStyles();
  const { t } = useTranslation('shared');

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}

      <NumberFormat
        decimalSeparator={t('decimal_separator')}
        className={clsx(
          classes.input,
          get(errors, name) != null && classes.error,
        )}
        value={get(values, name)}
        onValueChange={
          ({ floatValue }) =>
            setFieldValue(name, floatValue != null ? floatValue : null) // On évite de set undefined
        }
        suffix={' %'}
        isAllowed={values => {
          const { formattedValue, floatValue } = values;
          return formattedValue === '' || floatValue <= 100;
        }}
        decimalScale={1}
        disabled={disabled || (status && status.disabled)}
        {...props}
      />
    </>
  );
};
