import React from 'react';

import Confirmation from './confirmation';
import Reservation from './reservation';

const Actions = ({ dossier }) => {
  if (dossier.statut === 'CONFIRMATION') {
    return <Confirmation dossier={dossier} />;
  }
  if (dossier.statut.indexOf('R') === 0) {
    return <Reservation dossier={dossier} />;
  }

  return null;
};

export default Actions;
