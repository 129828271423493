import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

let openFn;
const defaultTheme = createMuiTheme();

const useStyles = makeStyles(theme => ({
  success: {
    background: theme.color.green.light,
  },
}));

const Notifier = () => {
  const [props, setProps] = useState({});
  const [open, setOpen] = useState();
  const classes = useStyles();

  const doOpen = props => {
    setProps(props);
    setOpen(true);
  };

  useEffect(() => {
    openFn = doOpen;
  }, []);

  const { variant, message } = props;

  return (
    <ThemeProvider theme={defaultTheme}>
      <Snackbar
        ContentProps={{
          className: classes[variant],
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={300000}
        message={message}
        onClose={() => setOpen(false)}
        open={open}
        action={[
          <IconButton key="close" onClick={() => setOpen(false)}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>,
        ]}
      />
    </ThemeProvider>
  );
};

export const notify = props => {
  openFn(props);
};

export default Notifier;
