import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import * as Yup from 'yup';

import etablissementSchema from 'commons/schemas/etablissement';

import Field from 'commons/components/field';
import Button from 'commons/components/button';
import Form from 'commons/components/form';
import { notify } from 'commons/components/notifier';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',

    '& input': {
      width: 'auto',
      marginRight: theme.space.small,
    },

    '& label': {
      margin: `auto ${theme.space.tiny} !important`,
    },
  },
}));

const ContactHoremeForm = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();
  const { etablissement } = store;

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'contact_horeme')}
      initialValues={etablissement.contact_horeme}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'contact_horeme', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Form styled showDirty>
          <h2>{t('presentation.contact_horeme')}</h2>
          <label htmlFor="nom">{t('presentation.nom')}</label>
          <Field name="nom" />
          <label htmlFor="prenom">{t('presentation.prenom')}</label>
          <Field name="prenom" />
          <label htmlFor="fonction">{t('presentation.fonction')}</label>
          <Field name="fonction" />
          <label htmlFor="email">{t('presentation.email')}</label>
          <Field type="email" name="email" disabled />
          <label htmlFor="tel_fixe">{t('presentation.email_enabled')}</label>
          <div className={classes.row}>
            <Field
              label="Oui"
              id={`email_enabled-true`}
              type="radio"
              name="email_enabled"
              value={true}
              to="boolean"
            />
            <Field
              label="Non"
              id={`email_enabled-false`}
              type="radio"
              name="email_enabled"
              value={false}
              to="boolean"
            />
          </div>
          <label htmlFor="tel_mobile">{t('presentation.tel_mobile')}</label>
          <Field.Phone name="tel_mobile" disabled />
          <label htmlFor="tel_fixe">{t('presentation.tel_fixe')}</label>
          <Field.Phone name="tel_fixe" disabled />
          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(ContactHoremeForm);
