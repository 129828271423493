import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import Taxes from './taxes';
import MoyensPaiement from './moyensPaiement';

const Finance = () => {
  return (
    <>
      <Taxes />
      <MoyensPaiement />
    </>
  );
};

export default Finance;
