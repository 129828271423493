const dayjs = require('dayjs');
const { assign } = require('lodash');

const getPenaliteApplicable = ({ tarif, dossier }) => {
  const { remboursable, remboursable_heure, remboursable_jours } = tarif;
  const parcours_squashed = assign({}, ...dossier.parcours);

  if (
    ['R_ANNULEE_MODIF_DATES', 'R_ANNULEE_CB_INVALIDE'].includes(dossier.statut)
  ) {
    return {
      applicable: false,
    };
  }

  if (!remboursable) {
    return {
      applicable: true,
    };
  }

  const current =
    dossier.statut.indexOf('R_') === 0 ? dayjs(parcours_squashed.at) : dayjs();

  let date;

  if (remboursable_jours > 0) {
    date = dayjs(parcours_squashed.arrivee_date).subtract(
      remboursable_jours,
      'day',
    );
  } else {
    date = dayjs(parcours_squashed.arrivee_date).add(
      remboursable_heure,
      'hours',
    );
  }

  let applicable = current.isAfter(date);

  return { applicable, date };
};

module.exports = getPenaliteApplicable;
