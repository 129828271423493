const getOffreTotalHT = ({
  nb_nuits,
  selection,
  chambres,
  prix_lit_appoint,
  prix_lit_bebe,
  chambres_ids,
  tarifs_ids,
  tarif,
}) => {
  return (
    nb_nuits *
    selection.reduce((acc, { chambre, literie, quantite }) => {
      const [literieIndex, nbLitAppoint, nbLitBebe] = literie
        .split('-')
        .map(e => parseInt(e));

      acc =
        acc +
        quantite *
          (chambres[chambres_ids[chambre]].prix_par_nuit[tarifs_ids[tarif]] +
            nbLitAppoint * prix_lit_appoint +
            nbLitBebe * prix_lit_bebe);

      return acc;
    }, 0)
  );
};

module.exports = getOffreTotalHT;
