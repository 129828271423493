const Yup = require('yup');
const demandeSchema = require('../dossier/demande_extranet_offre');
const offreSchema = require('../dossier/offre');
const testOffre = require('../dossier/testOffre');

const offreGratuite = Yup.object({
  arrivee: Yup.reach(demandeSchema, 'arrivee'),
  depart: Yup.reach(demandeSchema, 'depart'),
  nb_chambres: Yup.reach(demandeSchema, 'nb_chambres'),
  adultes: Yup.reach(demandeSchema, 'adultes'),
  enfants: Yup.reach(demandeSchema, 'enfants'),
  email: Yup.reach(demandeSchema, 'email'),
  tel_mobile: Yup.reach(demandeSchema, 'tel_mobile'),
  frais_SMS: Yup.reach(demandeSchema, 'frais_SMS'),
  chambres: Yup.reach(offreSchema, 'chambres'),
  taxe_sejour: Yup.reach(offreSchema, 'taxe_sejour'),
  prix_lit_appoint: Yup.reach(offreSchema, 'prix_lit_appoint'),
  prix_lit_bebe: Yup.reach(offreSchema, 'prix_lit_bebe'),
  message: Yup.reach(demandeSchema, 'message'),
})
  .required()
  .test(
    'has-consistent-tarifs',
    'Les tarifs doivent être renseignés pour toutes les chambres',
    testOffre,
  );

module.exports = offreGratuite;
