import React, { useState, useEffect, useMemo } from 'react';
import i18n from 'i18n';
import { makeStyles } from '@material-ui/styles';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import ReactCountryFlag from 'react-country-flag';

import HeaderCommon from 'commons/components/header';

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.color.gold,
  },
  headerExtranet: {
    '& li': {
      ...theme.font.weight.normal,
    },
  },
  subheader: {
    background: theme.color.blue.dark,
    padding: `${theme.space.tiny} ${theme.space.medium}`,
  },
  etablissement: {
    padding: `${theme.space.small} ${theme.space.medium}`,
    '& h1': {
      display: 'inline',
      ...theme.font.size.big,
    },
    '& strong': {
      ...theme.font.size.medium,
      marginLeft: theme.space.medium,
    },
  },
  red: {
    color: theme.color.red.dark,
  },
  green: {
    color: theme.color.green.dark,
  },
  flags: {
    marginLeft: theme.space.medium,
    display: 'inline-block',
    '& > *': {
      marginLeft: theme.space.tiny,
    },
  },
  count: {
    color: theme.color.yellow.medium,
  },
  link: {
    color: theme.color.dark,

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  notice: {
    marginRight: '1rem',

    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

const Link = props => {
  const classes = useStyles();
  return (
    <NavLink
      getProps={({ isCurrent }) => {
        return { className: isCurrent ? classes.active : null };
      }}
      {...props}
    />
  );
};

const Header = ({ store }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [now, setNow] = useState(dayjs());

  useEffect(() => {
    const i = window.setInterval(() => {
      setNow(dayjs());
    }, 1000);
    return () => window.clearInterval(i);
  }, []);

  const { etablissement } = store;

  const format = {
    fr: 'DD/MM/YYYY (HH:mm)',
    en: 'MM/DD/YYYY (HH:mm)',
  }[i18n.language];

  const demandeCount = useMemo(
    () =>
      store.dossiers
        ? store.dossiers.filter(d =>
            ['DEMANDE', 'CONFIRMATION', 'RESERVATION_MODIF_DATES'].includes(
              d.statut,
            ),
          ).length
        : null,
    [store.dossiers],
  );

  const notificationsCount = useMemo(() => {
    return store.dossiers
      ? store.dossiers
          .filter(
            d =>
              d.statut.indexOf('RESERVATION') === 0 ||
              d.statut.indexOf('R_ANNULEE') === 0,
          )
          .filter(d => d.unseen).length
      : null;
  }, [store.dossiers]);

  const items = etablissement
    ? etablissement.complete
      ? [
          <Link to="/compte/accueil">{t('accueil')}</Link>,
          <Link to="/compte/demandes">
            {t('demandes')}{' '}
            {demandeCount != null && (
              <span className={classes.count}>({demandeCount})</span>
            )}
          </Link>,
          <Link to="/compte/offre-gratuite">{t('offre-100pc-gratuite')}</Link>,
          <Link to="/compte/reservations">{t('reservations')}</Link>,
          <Link to="/compte/notifications">
            {t('notifications')}{' '}
            {notificationsCount != null && (
              <span className={classes.count}>({notificationsCount})</span>
            )}
          </Link>,
          <Link to="/compte/evaluations">{t('evaluations')}</Link>,
          <Link to="/compte/etablissement">{t('etablissement')}</Link>,
          <Link to="/compte/horeme">HoReMe</Link>,
          <Link to="/compte/parametres">{t('compte')}</Link>,
        ]
      : [
          <Link to="/compte/etablissement">{t('etablissement')}</Link>,
          <Link to="/compte/parametres">{t('compte')}</Link>,
        ]
    : [];

  return (
    <>
      <HeaderCommon
        className={classes.headerExtranet}
        subtitle={t('subtitle')}
        items={items}
        i18n={i18n}
        onLogoClick={() => history.push('/')}
        notice={
          <div className={classes.notice}>
            <a href={`/docs/notice_${i18n.language}.pdf`}>{t('notice')}</a>
          </div>
        }
      />
      <div className={classes.subheader}>
        <strong>{now.format(format)}</strong> —{' '}
        {etablissement && (
          <a
            className={classes.link}
            href={`https://www.horeme.com/e/${etablissement.slug}`}
          >
            {t('lien_front')}
          </a>
        )}
      </div>
      {etablissement && (
        <div className={classes.etablissement}>
          <h1>{etablissement.nom}</h1>
          {etablissement.connecte ? (
            <strong className={classes.green}>{t('connecte')}</strong>
          ) : (
            <strong className={classes.red}>{t('deconnecte')}</strong>
          )}
          <div className={classes.flags}>
            {etablissement.langues.map(l => (
              <ReactCountryFlag
                countryCode={l}
                svg
                style={{
                  width: '1rem',
                  height: '1rem',
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default compose(inject('store'), observer)(Header);
