import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import pick from 'lodash/pick';

import feathers from 'services/feathers';

import offreSchema from 'commons/schemas/dossier/offre';
import getTaxeSejour from 'commons/utils/getTaxeSejour';

import Form from 'commons/components/form';
import Button from 'commons/components/button';
import Field from 'commons/components/field';

import FirstTable from './firstTable';
import SecondTable from './secondTable';
import Messagerie from '../messagerie';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
  },
  main: {
    padding: `0 ${theme.space.small}`,
  },
  instruction: {
    flex: 1,
    ...theme.font.weight.bold,
    textAlign: 'left',
    padding: `${theme.space.tiny} 0`,
  },
  ou: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    padding: theme.space.small,
  },
  spacer: {
    height: theme.space.medium,
  },

  button: {
    width: '15rem',
  },
}));

const OffreForm = ({
  dossier,
  store,
  renderLeft,
  renderMain,
  isFirstOffer,
}) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();

  const { nb_chambres } = dossier.parcours_squashed;

  const disabled = dossier.statut === 'OFFRE';

  const etablissement = disabled
    ? dossier.parcours_squashed.etablissement
    : store.etablissement;

  return (
    <Form.Formik
      initialValues={
        dossier.statut.indexOf('O') === 0
          ? pick(dossier.parcours_squashed, [
              'chambres',
              'taxe_sejour',
              'prix_lit_bebe',
              'prix_lit_appoint',
              'message',
              'etablissement_updated_at',
            ])
          : {
              chambres: etablissement.chambres.map(c => ({
                quantite: 0,
                lit_bebe: 'NON_PROPOSE',
                lit_appoint: 'NON_PROPOSE',
                prix_par_nuit: etablissement.tarifs.map(() => null),
              })),
              taxe_sejour: getTaxeSejour({
                etablissement: etablissement,
                ...dossier.parcours_squashed,
              }),
              prix_lit_bebe: etablissement.finance.prix_lit_bebe,
              prix_lit_appoint: etablissement.finance.prix_lit_appoint,
              message: '',
              etablissement_updated_at: etablissement.updated_at,
            }
      }
      initialStatus={{ disabled }}
      validationSchema={offreSchema}
      validate={values => {
        const errors = {};

        // Comptage des chambres
        const chambresCount = values.chambres.reduce(
          (acc, e) => acc + e.quantite,
          0,
        );

        if (chambresCount < nb_chambres) {
          errors._general = t('offre.nombre-chambre-insuffisant');
        }

        return errors;
      }}
      onSubmit={async values => {
        try {
          await feathers.service('dossiers').patch(dossier.id, {
            action: 'OFFRE',
            ...values,
          });
          window.alert(t('offre.envoi_reussi'));
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {() => (
        <>
          {renderLeft(
            <Messagerie
              dossier={dossier}
              renderField={
                ['DEMANDE', 'CONFIRMATION'].includes(dossier.statut)
                  ? () => (
                      <Field
                        style={{ resize: 'none' }}
                        as="textarea"
                        rows={10}
                        name="message"
                        placeholder={t('offre.message')}
                      />
                    )
                  : () => null
              }
            />,
          )}
          {renderMain(
            <Form disabled={dossier.statut === 'OFFRE'}>
              <div className={classes.main}>
                {disabled ? (
                  <div className={classes.spacer} />
                ) : (
                  <div className={classes.instruction}>
                    {t('offre.etape-1')}
                  </div>
                )}
                <FirstTable
                  nb_chambres={nb_chambres}
                  nb_nuits={dossier.nb_nuits}
                  etablissement={
                    dossier.statut === 'OFFRE'
                      ? dossier.parcours.slice(-1)[0].etablissement
                      : store.etablissement
                  }
                />
                <div className={classes.spacer} />
                {!disabled && (
                  <div className={classes.instruction}>
                    {t('offre.etape-2')}
                  </div>
                )}
                <SecondTable dossier={dossier} />
                <div className={classes.spacer} />
                {!disabled && (
                  <div className={classes.row}>
                    <div className={classes.instruction} style={{ padding: 0 }}>
                      {t('offre.etape-3')}
                    </div>
                    {isFirstOffer && (
                      <>
                        <Button
                          className={classes.button}
                          type="button"
                          onClick={() => {
                            if (window.confirm(t('offre.etes_vous_sur'))) {
                              feathers.service('dossiers').patch(dossier.id, {
                                action: 'D_COMPLET',
                              });
                            }
                          }}
                        >
                          {t('offre.pas-de-dispo')}
                        </Button>

                        <div className={classes.ou}>{t('common:ou')}</div>
                      </>
                    )}
                    <Button type="submit" className={classes.button}>
                      {t('offre.envoyer-offre')}
                    </Button>
                  </div>
                )}
              </div>
            </Form>,
          )}
        </>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(OffreForm);
