import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';

import feathers from 'services/feathers';

import etablissementSchema from 'commons/schemas/etablissement';

import Button from 'commons/components/button';
import Form from 'commons/components/form';
import Field from 'commons/components/field';
import { notify } from 'commons/components/notifier';
import SERVICES from 'commons/constants/services';

const useStyles = makeStyles(theme => ({
  field: {
    display: 'inline-block',
    width: '14rem',
    '& label': {
      display: 'inline',
    },
    '& input': {
      width: 'auto',
      margin: theme.space.tiny,
    },
  },
}));

const Services = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;
  const classes = useStyles();

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'services')}
      initialValues={etablissement.services}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'services', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form styled showDirty>
          <h2>{t('services')}</h2>

          <div>
            {SERVICES.map(e => (
              <div className={classes.field} key={e}>
                <Field type="checkbox" name={e} />
                <label htmlFor={e}>{t(`shared:services.${e}`)}</label>
              </div>
            ))}
          </div>

          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(Services);
