import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import i18n from 'i18n';
import Table from 'components/table';

import Field from 'commons/components/field';
import getTaxeSejour from 'commons/utils/getTaxeSejour';

const useStyles = makeStyles(theme => ({
  table: {
    marginRight: theme.space.tiny,
  },
}));

const DemandeTable = ({ etablissement }) => {
  const { t } = useTranslation('offre_gratuite');
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  useEffect(() => {
    const { adultes, enfants } = values;

    setFieldValue(
      'taxe_sejour',
      getTaxeSejour({ etablissement, adultes, enfants }),
    );
  }, [values.adultes, values.enfants]);

  return (
    <Table className={classes.table}>
      <thead>
        <tr>
          <th colSpan={2}>{t('demande.header.personnes')}</th>
          <th>{t('demande.header.arrivee')}</th>
          <th>{t('demande.header.depart')}</th>
          <th>{t('demande.header.nuits')}</th>
          <th>{t('demande.header.chambres')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Field as="select" name={'adultes'} to="integer">
              {[...Array(30).keys()]
                .map(i => i + 1)
                .map(i => (
                  <option key={i} value={i}>
                    {t('demande.adultes', { count: i })}
                  </option>
                ))}
            </Field>
          </td>
          <td>
            <Field as="select" name={'enfants'} to="integer">
              {[...Array(11).keys()].map(i => (
                <option key={i} value={i}>
                  {t('demande.enfants', { count: i })}
                </option>
              ))}
            </Field>
          </td>
          <td>
            <Field.Date
              name="arrivee"
              onChange={val => {
                if (dayjs(val).add(1, 'day').isAfter(dayjs(values.depart))) {
                  setFieldValue('depart', dayjs(val).add(1, 'day').toDate());
                }
              }}
              locale={i18n.language}
              minDate={new Date()}
              startDate={values.arrivee}
              endDate={values.depart}
              selectsStart
            />
          </td>
          <td>
            <Field.Date
              name="depart"
              value={values.depart}
              onChange={setFieldValue}
              locale={i18n.language}
              minDate={dayjs(values.arrivee).add(1, 'day').toDate()}
              startDate={values.arrivee}
              endDate={values.depart}
              selectsEnd
            />
          </td>
          <td>
            {values.depart &&
              values.arrivee &&
              t('demande.nuits', {
                count: dayjs(values.depart).diff(dayjs(values.arrivee), 'day'),
              })}
          </td>
          <td>
            <Field as="select" name={'nb_chambres'} to="integer">
              {[...Array(30).keys()]
                .map(i => i + 1)
                .map(i => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
            </Field>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default DemandeTable;
