const Yup = require('yup');

const datesSchema = require('./dates');

const schema = Yup.object({
  arrivee: Yup.reach(datesSchema, 'arrivee'),
  depart: Yup.reach(datesSchema, 'depart'),
  nb_chambres: Yup.number().integer().min(1).max(30).required(),
  adultes: Yup.number().integer().min(1).max(30).required(),
  enfants: Yup.number().integer().min(0).max(10).required(),
  enfants_details: Yup.array()
    .of(
      Yup.object({
        age: Yup.number().integer().min(0).max(17).required(),
        literie: Yup.mixed()
          .oneOf(['lit-bebe', 'lit-independant', 'lit-parents'])
          .required(),
      }),
    )
    .min(Yup.ref('enfants'))
    .max(Yup.ref('enfants')),
  message: Yup.string().max(1000),
  email: Yup.string().email(),
  tel_mobile: Yup.string().required(),
  frais_SMS: Yup.number().required(),
}).required();

module.exports = schema;
