import React from 'react';
import { useTranslation } from 'react-i18next';
// import { makeStyles } from '@material-ui/core/styles';

// import ContactClientForm from './infosForm';
// import ContactHoremeForm from './contactHoremeForm';
// import GestionForm from './gestionForm';
import InfosForm from './infosForm';

// const useStyles = makeStyles(theme => ({
//   explication: {
//     fontStyle: 'italic',
//   },
// }));

const Informations = () => {
  const { t } = useTranslation('etablissement');
  //   const classes = useStyles();

  return (
    <>
      <InfosForm />
    </>
  );
};

export default Informations;
