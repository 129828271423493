import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useFormikContext } from 'formik';

import Imgix from 'services/imgix';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';

import feathers from 'services/feathers';

import Box from './box';

const defaultTheme = createMuiTheme();
const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'inline-flex',
  },
  boxContainer: {
    position: 'relative',
  },
  input: {
    borderColor: theme.color.blue.dark,
    outline: 'none',

    '& svg': {
      zIndex: 1,
      position: 'absolute',
      fontSize: '2.3rem',
      fill: theme.color.blue.dark,
    },

    '&:hover': {
      borderColor: theme.color.primary,
      cursor: 'pointer',

      '& svg': {
        fill: theme.color.primary,
      },

      '& img': {
        opacity: 0.5,
      },
    },
  },
  loading: {
    position: 'absolute',
    '&:hover': {
      borderColor: theme.color.blue.dark,
    },
    '& svg': {
      color: theme.color.primary,
    },
  },
  error: {
    borderColor: theme.color.red.dark,
    '& svg': {
      color: theme.color.red.dark,
    },
  },
}));

const Field = ({ name, onSuccess = () => null, array }) => {
  const { values, setFieldValue, errors, isSubmitting } = useFormikContext();
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const { t } = useTranslation();

  const onDrop = useCallback(
    async ([file]) => {
      try {
        setLoading(true);
        if (!file) return;

        if (file.size > 5 * Math.pow(1024, 2)) {
          window.alert(t('ficher_trop_volumineux'));
          return;
        }

        const { id } = await feathers
          .service('uploads')
          .create({ buffer: file });

        if (array) {
          setFieldValue(name, [...values[name], id]);
        } else {
          setFieldValue(name, id);
        }

        onSuccess();
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [values, onSuccess, array],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className={classes.wrapper}>
        <Box
          {...getRootProps()}
          className={clsx(
            classes.box,
            classes.input,
            errors[name] && classes.error,
          )}
        >
          {!array && values[name] && (
            <Imgix
              photo={values[name]}
              width={100}
              imgixParams={{ ar: '1:1', fit: 'crop' }}
            />
          )}
          <input
            {...getInputProps({
              multiple: false,
              accept: 'image/jpeg',
              disabled: loading,
            })}
          />
          {loading || isSubmitting ? (
            <CircularProgress className={classes.loading} />
          ) : isDragActive ? (
            <PublishIcon />
          ) : !array && values[name] ? (
            <EditIcon />
          ) : (
            <AddIcon />
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default compose(inject('store'), observer)(Field);
