import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';

const app = feathers();
const socket = io(process.env.REACT_APP_SERVER_URL);
app.configure(socketio(socket));

if (typeof window !== 'undefined') {
  app.configure(auth());
}

app.service('uploads').timeout = 30 * 1000;

export default app;
