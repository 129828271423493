import React from 'react';
import { useFormikContext, Field as FormikField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { has } from 'lodash';
import clsx from 'clsx';

import Date from './date';
import Phone from './phone';
import PhoneOffreSMS from './phoneOffreSMS';
import { Currency, Percent, Number } from './number';
import Country from './country';

const useStyles = makeStyles(theme => ({
  field: {
    width: '100%',
    minHeight: '1.5rem',
    padding: '0.25rem 0.5rem',
    border: `1px solid ${
      theme.card ? theme.card.color.dark : theme.color.grey.light
    }`,
    '&[disabled]': {
      color: theme.color.grey.dark,
      background: theme.card ? theme.card.color.light : theme.color.white,
      border: `1px solid ${
        theme.card ? theme.card.color.light : theme.color.grey.white
      }`,
    },
  },
  error: {
    ...theme.error,

    '&[type="radio"]': {
      boxShadow: 'none',
    },
  },
  notice: {
    color: theme.color.grey.dark,
    margin: 0,
    marginTop: '-1rem',
  },
}));

const Field = React.forwardRef(
  (
    {
      className,
      name,
      error,
      id,
      label,
      disabled,
      notice,
      to,
      onChange,
      type,
      ...props
    },
    ref,
  ) => {
    // error prop est supprimable après refacto de enfantsDetailsForm
    const { errors, status, setFieldValue } = useFormikContext();
    const classes = useStyles();

    const transform = to
      ? {
          integer: e => (parseInt(e) != null ? parseInt(e) : null),
          boolean: e => e == 'true',
        }[to]
      : e => e;

    return (
      <>
        {label && <label htmlFor={name}>{label}</label>}
        <FormikField
          {...props}
          id={id || name}
          name={name}
          innerRef={ref}
          type={type}
          onChange={e => {
            if (onChange) {
              onChange(e);
            } else if (type === 'checkbox') {
              setFieldValue(name, e.target.checked);
            } else {
              setFieldValue(name, transform(e.target.value));
            }
          }}
          className={clsx(
            className,
            classes.field,
            (has(errors, name) || error) && classes.error,
          )}
          disabled={disabled || (status && status.disabled)}
        />
        {notice && <p className={classes.notice}>{notice}</p>}
      </>
    );
  },
);

Field.Phone = Phone;
Field.PhoneOffreSMS = PhoneOffreSMS;
Field.Number = Number;
Field.Currency = Currency;
Field.Percent = Percent;
Field.Date = Date;
Field.Country = Country;

export default Field;
