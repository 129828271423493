const Yup = require('yup');

const schema = Yup.object({
  nom: Yup.string().required(),
  remboursable: Yup.bool().required(),
  remboursable_jours: Yup.mixed().when('remboursable', (remboursable, s) =>
    remboursable ? Yup.number().required() : s,
  ),
  remboursable_heure: Yup.mixed().when(
    'remboursable_jours',
    (remboursable_jours, s) =>
      remboursable_jours === 0 ? Yup.number().min(0).max(24).required() : s,
  ),
  repas: Yup.mixed()
    .oneOf(['AUCUN', 'PETIT_DEJEUNER', 'DEMI_PENSION', 'PENSION_COMPLETE'])
    .required(),
  penalite: Yup.mixed().oneOf(['PREMIERE_NUIT', 'POURCENTAGE']).required(),
  penalite_pourcentage: Yup.mixed().when('penalite', (penalite, s) =>
    penalite === 'POURCENTAGE' ? Yup.number().required() : s,
  ),
  prepaiement: Yup.bool().required(),
  prepaiement_type: Yup.mixed().when('prepaiement', (prepaiement, s) =>
    prepaiement ? s.oneOf(['PREMIERE_NUIT', 'POURCENTAGE']).required() : s,
  ),
  prepaiement_type_pourcentage: Yup.mixed().when(
    'prepaiement_type',
    (prepaiement_type, s) =>
      prepaiement_type === 'POURCENTAGE' ? Yup.number().required() : s,
  ),
  prepaiement_moment: Yup.mixed().when('prepaiement', (prepaiement, s) =>
    prepaiement ? s.oneOf(['A_LA_RESERVATION', 'AVANT_ARRIVEE']).required() : s,
  ),
  prepaiement_moment_jours: Yup.mixed().when(
    'prepaiement_moment',
    (prepaiement_moment, s) =>
      prepaiement_moment === 'AVANT_ARRIVEE' ? Yup.number().required() : s,
  ),
  preautorisation: Yup.bool().required(),
  preautorisation_type: Yup.mixed().when(
    'preautorisation',
    (preautorisation, s) =>
      preautorisation
        ? s.oneOf(['PREMIERE_NUIT', 'POURCENTAGE']).required()
        : s,
  ),
  preautorisation_type_pourcentage: Yup.mixed().when(
    ['preautorisation', 'preautorisation_type'],
    (preautorisation, preautorisation_type, s) =>
      preautorisation && preautorisation_type === 'POURCENTAGE'
        ? Yup.number().required()
        : s,
  ),
}).required();

module.exports = schema;
