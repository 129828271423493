import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import sharedEn from 'commons/translations/shared_en.json';
import sharedFr from 'commons/translations/shared_fr.json';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS: 'common',
    debug: false,
  });

i18n.addResourceBundle('en', 'shared', sharedEn);
i18n.addResourceBundle('fr', 'shared', sharedFr);

export default i18n;
