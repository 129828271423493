import React, { useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import PasswordSchema from 'commons/schemas/password';

import Field from 'commons/components/field';
import Button from 'commons/components/button';

import feathers from 'services/feathers';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& input': {
      width: 'auto',
      margin: `${theme.space.tiny} 0`,
      display: 'block',
    },
  },
}));

const NouveauMdp = () => {
  const { t } = useTranslation('compte');
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Formik
        initialValues={{ password: '', old_password: '' }}
        validationSchema={Yup.object({
          password: PasswordSchema.required(),
          old_password: PasswordSchema.required(),
        })}
        onSubmit={async (values, { setStatus, resetForm }) => {
          try {
            await feathers.service('users').patch(null, {
              action: 'nouveau-mdp-connecte',
              ...values,
            });

            window.alert(t('success'));
            resetForm();
          } catch (e) {
            console.error(e);
            window.alert(t('nouveau-mdp-erreur'));
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={classes.form}>
              <label htmlFor="password">{t('ancien-mdp')}</label>
              <Field name="old_password" type="password" />
              <label htmlFor="password">{t('nouveau-mdp')}</label>
              <Field name="password" type="password" />
              <Button type="submit" loading={isSubmitting}>
                {t('common:valider')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default compose(inject('store'), observer)(NouveauMdp);
