module.exports = function ({ chambres }) {
  const tarifsIndexes = chambres
    .filter(c => c.quantite !== 0)
    .map(e => {
      return Array.from(
        e.prix_par_nuit.reduce((acc, e, i) => {
          if (e) {
            acc.add(i);
          }
          return acc;
        }, new Set()),
      );
    })
    .reduce((acc, e) => {
      e.forEach(acc.add, acc);
      return acc;
    }, new Set());

  chambres.forEach((c, i) => {
    if (c.quantite === 0) {
      return;
    }

    c.prix_par_nuit.forEach((p, j) => {
      if ((p == null || p === 0) && tarifsIndexes.has(j)) {
        throw this.createError({
          message: 'Le prix par nuit pour ce tarif doit être défini',
          path: `chambres[${i}].prix_par_nuit[${j}]`,
        });
      }
    });
  });

  return true;
};
