import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Countdown, { zeroPad } from 'react-countdown';
import dayjs from 'dayjs';

import feathers from 'services/feathers';

import Button from 'commons/components/button';
import Modal from 'commons/components/modal';

import Header from './header';
import OffreForm from './offreForm';
import Historique from './historique';
import Reservation from './reservation';

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    border: `1px solid ${theme.color.dark}`,
    backgroundColor: theme.color.blue.medium,
  },
  row: {
    display: 'flex',
  },
  left: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '18rem',
    flexShrink: 0,

    '& $content': {
      borderRight: `1px solid ${theme.color.blue.dark}`,
      padding: theme.space.small,
    },

    '& $top': {
      padding: `0 ${theme.space.medium}`,
    },
  },
  top: {
    color: theme.color.yellow.dark,
    backgroundColor: theme.color.dark,
    position: 'relative',
  },
  historique: {
    color: theme.color.grey.medium,
    paddingLeft: theme.space.medium,
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  footer: {
    textAlign: 'right',
    minHeight: '2rem',
    marginTop: theme.space.tiny,

    '& button': {
      backgroundColor: theme.color.dark,
      padding: `${theme.space.tiny} ${theme.space.medium}`,
    },
  },
  open: {
    '& $content': {
      borderTop: `1px solid ${theme.color.blue.dark}`,
    },
  },
  alert: {
    margin: 'auto',
    color: theme.color.red.dark,
    textTransform: 'uppercase',
  },
  nomClient: {
    '& a': {
      textDecoration: 'underline',
      color: theme.color.primary,
    },
  },
  offreGratuite: {
    color: theme.color.green.lighter,
    position: 'absolute',
    right: theme.space.small,
    top: 0,
  },
  countdown: {
    ...theme.font.weight.bold,
    ...theme.font.size.normal,
  },
}));

const Dossier = ({ dossier, initialOpen = true, handleUnseen = false }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();
  const [open, setOpen] = useState(initialOpen);

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        <div className={classes.left}>
          <div className={classes.top}>{dossier.shortid}</div>
          <div className={classes.content}>
            {dossier.parcours_squashed.coordonnees ? (
              <span className={classes.nomClient}>
                {dossier.parcours_squashed.coordonnees.nom}{' '}
                {dossier.parcours_squashed.coordonnees.prenom}
              </span>
            ) : dossier.offre_gratuite ? (
              <span className={classes.nomClient}>
                {dossier.tel_mobile !== 'NULL' ? (
                  <a href={'tel:+' + dossier.tel_mobile}>
                    +{dossier.tel_mobile}
                  </a>
                ) : dossier.email !== 'NULL' ? (
                  <a href={'mailto:+' + dossier.email}>{dossier.email}</a>
                ) : (
                  // impossible normalement car validation formulaire mais schema db ne requiert ni tel ni email
                  'Erreur : pas de coordonnées renseignées pour ce dossier'
                )}
              </span>
            ) : null}
            <span className={classes.alert}>
              {dossier.statut === 'DEMANDE' &&
                dossier.parcours[0].message &&
                t('nouveau-message')}
            </span>
            {dossier.timer_at && dayjs(dossier.timer_at).isAfter(dayjs()) && (
              <span className={classes.countdown}>
                <Countdown
                  date={dossier.timer_at}
                  renderer={({ minutes, seconds }) => (
                    <span className={classes.countdown}>
                      {zeroPad(minutes)}:{zeroPad(seconds)}
                    </span>
                  )}
                />
              </span>
            )}
          </div>
        </div>
        <div className={classes.main}>
          <div className={classes.top}>
            {t(`common:statut.${dossier.statut}`)}{' '}
            <Modal
              renderButton={open => (
                <span className={classes.historique} onClick={open}>
                  {t('historique')}
                </span>
              )}
              renderContent={close => <Historique dossier={dossier} />}
            />
            {dossier.offre_gratuite && (
              <div className={classes.offreGratuite}>
                {t('offre_SMS')} | {dossier.frais_SMS} €
              </div>
            )}
          </div>
          <div className={classes.content}>
            <Header dossier={dossier} />
            <div className={classes.footer}>
              {!['D_EXPIREE', 'D_COMPLET'].includes(dossier.statut) && (
                <Button
                  onClick={() => {
                    setOpen(!open);

                    if (handleUnseen && dossier.unseen) {
                      feathers.service('dossiers').patch(dossier.id, {
                        action: 'unseen',
                      });
                    }
                  }}
                >
                  {t('details')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div className={clsx(classes.open, classes.row)}>
          {dossier.statut.indexOf('D') === 0 ||
          dossier.statut.indexOf('O') === 0 ? (
            <OffreForm
              isFirstOffer
              dossier={dossier}
              renderLeft={e => (
                <div className={classes.left}>
                  <div className={classes.content}>{e}</div>
                </div>
              )}
              renderMain={e => (
                <div className={classes.main}>
                  <div className={classes.content}>{e}</div>
                </div>
              )}
            />
          ) : (
            <Reservation
              dossier={dossier}
              renderLeft={e => (
                <div className={classes.left}>
                  <div className={classes.content}>Coucou</div>
                </div>
              )}
              renderMain={e => (
                <div className={classes.main}>
                  <div className={classes.content}>Hello</div>
                </div>
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Dossier;
