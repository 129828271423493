const Yup = require('yup');
const chambresLitsSupp = require('../../constants/chambres_lits_supp');

const testOffre = require('./testOffre');

const offre = Yup.object({
  chambres: Yup.array()
    .of(
      Yup.object({
        quantite: Yup.number().required(),
        lit_bebe: Yup.mixed().oneOf(chambresLitsSupp).required(),
        lit_appoint: Yup.mixed().oneOf(chambresLitsSupp).required(),
        prix_par_nuit: Yup.mixed().when('quantite', (quantite, s) =>
          quantite !== 0
            ? Yup.array()
                .of(Yup.number().nullable())
                .required()
                .test(
                  'is-not-completely-empty',
                  '${path} doit au moins contenir un tarif',
                  value => value.some(e => e),
                )
            : s,
        ),
      }),
    )
    .required(),
  taxe_sejour: Yup.number().required(),
  prix_lit_appoint: Yup.mixed().test(
    'is-set',
    '${path} doit être renseigné',
    function (value) {
      if (
        this.parent.chambres
          .filter(c => c.quantite)
          .some(c => c.lit_appoint !== 'NON_PROPOSE')
      ) {
        return Number.isInteger(value);
      }

      return true;
    },
  ),
  prix_lit_bebe: Yup.mixed().test(
    'is-set',
    '${path} doit être renseigné',
    function (value) {
      if (
        this.parent.chambres
          .filter(c => c.quantite)
          .some(c => c.lit_bebe !== 'NON_PROPOSE')
      ) {
        return Number.isInteger(value);
      }

      return true;
    },
  ),
  message: Yup.string().max(1000),
})
  .required()
  .test(
    'has-consistent-tarifs',
    'Les tarifs doivent être renseignés pour toutes les chambres',
    testOffre,
  );

module.exports = offre;
