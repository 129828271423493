import React from 'react';
import { useFormikContext, Formik, Form as FormikForm } from 'formik';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const defaultTheme = createMuiTheme();

const useStyles = makeStyles(theme => ({
  form: {
    position: 'relative',
  },
  styled: {
    '& label': {
      display: 'block',
      marginBottom: theme.space.tiny,
      color: theme.color.primary,

      '&:not(:first-child)': {
        marginTop: theme.space.small,
      },
    },
    '& button': {
      marginTop: theme.space.small,
    },
    '& fieldset': {
      padding: theme.space.small,

      '& strong': {
        marginBottom: theme.space.tiny,
        textAlign: 'center',
        display: 'block',
      },
    },
  },
  // dirty: {
  //   position: 'absolute',
  //   top: 10,
  //   right: 10,
  //   height: 10,
  //   width: 10,
  //   borderRadius: '100%',
  //   backgroundColor: theme.color.orange,
  // },
}));

const Form = ({ children, styled, showDirty }) => {
  const classes = useStyles();
  const { dirty } = useFormikContext();
  const { t } = useTranslation('shared');

  return (
    <FormikForm className={clsx(classes.form, styled && classes.styled)}>
      {children}
      {showDirty && dirty && (
        <ThemeProvider theme={defaultTheme}>
          <Snackbar open={true}>
            <Alert variant="filled" severity="warning">
              {t('unsaved-changes')}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      )}
    </FormikForm>
  );
};

Form.Formik = ({ validate, ...props }) => (
  <Formik
    enableReinitialize={true}
    validateOnBlur={false}
    validateOnChange={false}
    validate={values => {
      const errors = validate ? validate(values) : {};

      if (errors._general) {
        window.alert(errors._general);
      }

      return errors;
    }}
    {...props}
  />
);

export default Form;
