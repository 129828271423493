const dayjs = require('dayjs');
const assign = require('lodash/assign');
const getPenaliteApplicable = require('./getPenaliteApplicable');

const isCBConsultable = ({ dossier }) => {
  const parcours_squashed = assign({}, ...dossier.parcours);

  if (dossier.statut.indexOf('RESERVATION') === 0) {
    return true;
  }

  if (dayjs().isBefore(dayjs(parcours_squashed.at).add('7', 'days'))) {
    if (dossier.statut === 'R_TERMINEE') {
      return true;
    }

    const { tarif, etablissement, tarifs_ids } = parcours_squashed;
    const penaliteApplicable = getPenaliteApplicable({
      dossier,
      tarif: etablissement.tarifs[tarifs_ids[tarif]],
    });

    if (penaliteApplicable.applicable) {
      return true;
    }
  }

  return false;
};

module.exports = isCBConsultable;
