import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    background: theme.color.white,
    border: `1px solid ${theme.color.dark}`,
    marginBottom: theme.space.tiny,

    '& button': {
      width: '100%',
    },
  },
  instructions: {
    padding: theme.space.tiny,
    textAlign: 'center',

    '& > h3': {
      textTransform: 'uppercase',
    },
  },
}));

const Block = ({ instructions, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.block}>
      <div className={classes.instructions}>{instructions}</div>
      {children}
    </div>
  );
};

export default Block;
