import React, { useEffect, useState, useCallback } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import Button from 'commons/components/button';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',

    '& > *': {
      marginBottom: theme.space.tiny,
    },
  },
  alert: {
    background: theme.color.red.light,
  },
  countdown: {
    paddingLeft: theme.space.small,
  },
}));

const Dossiers = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation('accueil');
  const [dossiersTimers, setDossiersTimers] = useState([]);
  const history = useHistory();

  const { dossiers } = store;

  const refreshDossiersTimers = useCallback(() => {
    if (!dossiers) return;

    const dossiersTimers = dossiers
      .filter(d => d.connecte)
      .filter(d => dayjs().isBefore(dayjs(d.timer_at)))
      .sort((a, b) => a > b);

    setDossiersTimers(dossiersTimers);
  }, [dossiers]);

  useEffect(() => {
    refreshDossiersTimers();
  }, [dossiers]);

  if (!dossiers) return null;

  const filtered = dossiers.filter(d =>
    ['DEMANDE', 'CONFIRMATION', 'RESERVATION_MODIF_DATES'].includes(d.statut),
  );

  return (
    <div className={classes.wrapper}>
      <Button
        className={filtered.length && classes.alert}
        onClick={() => history.push('/compte/demandes')}
      >
        {t('demandes.demandes', { count: filtered.length })}{' '}
        {dossiersTimers.length !== 0 && (
          <Countdown
            date={dossiersTimers[0].timer_at}
            renderer={({ minutes, seconds }) => (
              <span className={classes.countdown}>
                {zeroPad(minutes)}:{zeroPad(seconds)}
              </span>
            )}
            onComplete={() => window.setTimeout(refreshDossiersTimers, 1000)}
          />
        )}
      </Button>
      <Button onClick={() => history.push('/compte/offre-gratuite')}>
        {t('demandes.offre-gratuite')}
      </Button>
    </div>
  );
};

export default compose(inject('store'), observer)(Dossiers);
