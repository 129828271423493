import React from 'react';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';

import Number from 'commons/components/number';
import Field from 'commons/components/field';
import Table from 'components/table';

import chambresLitsSupp from 'commons/constants/chambres_lits_supp';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    marginRight: theme.space.tiny,
  },
  totalTTC: {
    '& span': {
      margin: 'auto',
    },
  },
}));

const FirstTable = ({ nb_chambres, nb_nuits, etablissement }) => {
  const { t } = useTranslation('dossier');
  const { values } = useFormikContext();
  const classes = useStyles();

  const { chambres, tarifs } = etablissement;

  return (
    <Table className={classes.table}>
      <thead>
        <tr>
          <th>{t('offre.table.chambres')}</th>
          <th>{t('offre.table.quantite')}</th>
          <th>{t('offre.table.tarif')}</th>
          <th>{t('offre.table.prix-par-nuit')}</th>
          <th style={{ width: '8rem' }}>{t('offre.table.lit-bebe')}</th>
          <th style={{ width: '8rem' }}>{t('offre.table.lit-dappoint')}</th>
          <th style={{ width: '8rem' }}>{t('offre.table.total-ttc')}</th>
        </tr>
      </thead>
      <tbody>
        {chambres.map((c, index) => (
          <tr key={index}>
            <td>
              <p>{t(`shared:chambres_labels.${c.label}`)}</p>
              <p>({c.nom})</p>
            </td>
            <td>
              <Field
                as="select"
                name={`chambres.${index}.quantite`}
                to="integer"
              >
                {[
                  ...Array(
                    nb_chambres
                      ? Math.min(chambres[index].quantite, nb_chambres) + 1
                      : chambres[index].quantite + 1,
                  ).keys(),
                ].map(i => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </Field>
            </td>
            <td>
              <Table.List>
                {tarifs.map((t, i) => (
                  <li key={i}>{t.nom}</li>
                ))}
              </Table.List>
            </td>
            <td>
              <Table.List>
                {tarifs.map((t, i) => (
                  <li key={i}>
                    <Field.Currency
                      disabled={!values.chambres[index].quantite}
                      name={`chambres.${index}.prix_par_nuit.${i}`}
                    />
                  </li>
                ))}
              </Table.List>
            </td>
            <td>
              {chambres[index].lit_bebe_disponible ? (
                <Field
                  as="select"
                  name={`chambres.${index}.lit_bebe`}
                  disabled={!values.chambres[index].quantite}
                >
                  {chambresLitsSupp.map(l => (
                    <option key={l} value={l}>
                      {t(`shared:chambres_lits_supp.${l}`)}
                    </option>
                  ))}
                </Field>
              ) : (
                t('offre.table.indisponible')
              )}
            </td>
            <td>
              {chambres[index].lit_appoint_disponible ? (
                <Field
                  as="select"
                  name={`chambres.${index}.lit_appoint`}
                  disabled={!values.chambres[index].quantite}
                >
                  {chambresLitsSupp.map(l => (
                    <option key={l} value={l}>
                      {t(`shared:chambres_lits_supp.${l}`)}
                    </option>
                  ))}
                </Field>
              ) : (
                t('offre.table.indisponible')
              )}
            </td>
            <td>
              <Table.List>
                {tarifs.map((t, i) => (
                  <li key={i} className={classes.totalTTC}>
                    {get(values, `chambres.${index}.prix_par_nuit.${i}`) ? (
                      <Number
                        suffix={' €'}
                        fixedDecimalScale={2}
                        value={
                          (get(values, `chambres.${index}.prix_par_nuit.${i}`) +
                            ({
                              '1_OBLIGATOIRE': 1,
                              '2_OBLIGATOIRE': 2,
                            }[get(values, `chambres.${index}.lit_bebe`)] || 0) *
                              (values.prix_lit_bebe || 0) +
                            ({
                              '1_OBLIGATOIRE': 1,
                              '2_OBLIGATOIRE': 2,
                            }[get(values, `chambres.${index}.lit_appoint`)] ||
                              0) *
                              (values.prix_lit_appoint || 0)) *
                          nb_nuits *
                          values.chambres[index].quantite
                        }
                      />
                    ) : null}
                  </li>
                ))}
              </Table.List>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default FirstTable;
