module.exports = [
  'ascenseur',
  'bagagerie',
  'menage_quotidien',
  'menage_quotidien_en_supplement',
  'linge_et_serviettes',
  'linge_et_serviettes_en_supplement',
  'peignoirs',
  'chaussons',
  'articles_de_toilette',
  'petit_dejeuner_en_chambre',
  'navette_aeroport_en_supplement',
  'photocopies',
  'adaptateurs_prises',
  'wifi_gratuit',
  'réception_24_24',
  'réception_temps_partiel',
  'lobby',
  'concierge',
  'blanchisserie',
  'nettoyage_a_sec',
  'service_d_etage',
  'salle_petit_dejeuner',
  'restaurant',
  'bar',
  'salle_de_reunion',
  'salle_de_sport',
  'piscine_interieure',
  'piscine_exterieure',
  'sauna',
  'hammam',
  'spa',
  'parking_prive',
  'jardin',
  'accessibilite',
  'garde_d_enfants',
  'club_pour_enfants',
  'voiturier',
  'bureau_de_change',
  'billeterie',
  'journaux',
  'jeux_de_societe',
  'livres',
  'consoles',
];
