import React, { useEffect, useRef } from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import i18n from 'i18n';
import ReCaptcha from 'react-google-recaptcha';
import qs from 'query-string';

import Field from 'commons/components/field';
import Button from 'commons/components/button';
import waitFor from 'commons/utils/waitFor';
import resetPasswordSchema from 'commons/schemas/reset_password';

import feathers from 'services/feathers';

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.color.white,
    margin: 'auto',
    maxWidth: '20rem',
    padding: theme.space.small,

    '& input': {
      marginBottom: theme.space.small,
    },

    '& h1': {
      color: theme.color.primary,
      fontStyle: 'italic',
      textAlign: 'center',
      marginBottom: theme.space.small,
      ...theme.font.weight.normal,
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& label': {
      marginBottom: theme.space.tiny,
    },
  },
  '@global': {
    body: {
      background: `${theme.color.blue.light} !important`,
    },
  },
}));

const NouveauMdp = ({ store }) => {
  const { t } = useTranslation('nouveau-mdp');
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const reCaptchaRef = useRef(null);

  const { reset_token, user_id } = qs.parse(location.search);

  useEffect(() => {
    if (store.authentication) {
      history.push('/compte/accueil');
    }
    if (!reset_token || !user_id) {
      history.push('/login');
    }
  }, [store.authentication]);

  return (
    <div className={classes.content}>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={resetPasswordSchema}
        initialValues={{ password: '', reset_token }}
        initialStatus={{ complete: '' }}
        onSubmit={async (values, { setStatus }) => {
          try {
            await reCaptchaRef.current.execute();
            const recaptcha = await waitFor(() =>
              reCaptchaRef.current.getValue(),
            );

            await feathers.service('users').patch(user_id, {
              recaptcha,
              action: 'nouveau-mdp',
              ...values,
            });

            setStatus({ complete: true });
          } catch (e) {
            console.error(e);
            window.alert(t('common:probleme'));
            reCaptchaRef.current.reset();
          }
        }}
      >
        {({ isSubmitting, status, errors }) => {
          if (status.complete) {
            return (
              <>
                <p>{t('nouveau-mdp-confirmation')}</p>
                <a onClick={() => history.push('/login')}>
                  {t('se-connecter')}
                </a>
              </>
            );
          }

          return (
            <Form className={classes.form}>
              <label htmlFor="password">{t('nouveau-mdp')}</label>
              <Field name="password" type="password" />
              <Field name="reset_token" type="hidden" />
              <Button type="submit" loading={isSubmitting}>
                {t('confirmer')}
              </Button>
              <ReCaptcha
                ref={reCaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                hl={i18n.language}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default compose(inject('store'), observer)(NouveauMdp);
