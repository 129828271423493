const Yup = require('yup');

const photoSchema = require('./photo');
const literieSchema = require('./literie');

const chambresLabels = require('../constants/chambres_labels');

const schema = Yup.object({
  nom: Yup.string().required(),
  label: Yup.mixed().oneOf(chambresLabels).required(),
  quantite: Yup.number().required(),
  superficie: Yup.number().required(),
  capacite: Yup.number().required(),
  photo: photoSchema.required(),
  photo_sdb: photoSchema.required(),
  autres_photos: Yup.array().of(photoSchema).min(1).max(4),
  literies: Yup.array().of(literieSchema),
  lit_bebe_disponible: Yup.bool(),
  lit_appoint_disponible: Yup.bool(),
}).required();

module.exports = schema;
