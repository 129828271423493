import React from 'react';
import { useTranslation } from 'react-i18next';

import Viewer from 'components/viewer';

const Demandes = () => {
  const { t } = useTranslation('viewer');
  return (
    <Viewer
      menu={[
        {
          id: 'nouvelles-demandes',
          label: t('menu.nouvelles-demandes'),
          options: {
            query: {
              statut: {
                $in: ['DEMANDE', 'CONFIRMATION', 'RESERVATION_MODIF_DATES'],
              },
              $sort: {
                updated_at: 1,
              },
            },
          },
          stack: true,
        },
        {
          id: 'en-cours',
          label: t('menu.en-cours'),
          options: {
            query: {
              statut: {
                $in: ['OFFRE'],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
        {
          id: 'indisponible',
          label: t('menu.indisponible'),
          options: {
            query: {
              statut: {
                $in: ['D_COMPLET', 'C_COMPLET'],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
        {
          id: 'expire',
          label: t('menu.expire'),
          options: {
            query: {
              statut: {
                $in: ['O_EXPIREE'],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
        {
          id: 'non-traite',
          label: t('menu.non-traite'),
          options: {
            query: {
              statut: {
                $in: [
                  'D_EXPIREE',
                  'C_EXPIREE',
                  'RESERVATION_MODIF_DATES_EXPIREE',
                ],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
      ]}
    />
  );
};

export default Demandes;
