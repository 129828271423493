import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { merge, sortBy } from 'lodash';
import clsx from 'clsx';
import feathers from 'services/feathers';

import Dossier from 'components/dossier';

const useStyles = makeStyles(theme => ({
  menu: {
    color: theme.color.dark,
    '& ul': {
      display: 'flex',
      paddingBottom: theme.space.small,
    },
    '& li': {
      paddingRight: theme.space.medium,
      cursor: 'pointer',
    },
    '& li:first-child': {
      minWidth: '20rem',
    },
  },
  selected: { ...theme.font.weight.bold },
  empty: {
    padding: theme.space.medium,
    color: theme.color.grey.medium,
    textAlign: 'center',
  },
  dossiers: {
    '& > *': {
      marginBottom: theme.space.small,
    },
  },
  pagination: {
    textAlign: 'center',
    padding: `${theme.space.small} 0`,
    color: theme.color.dark,
    ...theme.font.weight.bold,

    '& span': {
      cursor: 'pointer',
      margin: `0 ${theme.space.small}`,

      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));
/*
  handleUnseen: Va modifié le booléen unseen quand on cliquera sur détails
                Va également filtrer count pour ne prendre que les unseen
*/
const Viewer = ({ menu, handleUnseen = false }) => {
  const [dossiers, setDossiers] = useState();
  const [total, setTotal] = useState();
  const [counts, setCounts] = useState();
  const [filter, setFilter] = useState(menu[0].id);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const { t } = useTranslation('viewer');

  const { stack } = menu.find(e => e.id === filter);

  const onUpdate = () => {
    fetchCounts();
    fetchDossiers();
  };

  const fetchDossiers = () => {
    const options = merge(
      {
        query: {
          $skip: page * 10,
        },
      },
      menu.find(e => e.id === filter).options,
    );

    return feathers
      .service('dossiers')
      .find(options)
      .then(e => {
        setDossiers(e.data);
        setTotal(e.total);
      });
  };

  const fetchCounts = () => {
    return Promise.all(
      menu.map(async ({ id }) => {
        const { total } = await feathers.service('dossiers').find(
          merge(
            handleUnseen
              ? {
                  query: {
                    $limit: 0,
                    unseen: true,
                  },
                }
              : {
                  query: {
                    $limit: 0,
                  },
                },
            menu.find(e => e.id === id).options,
          ),
        );

        return [id, total];
      }),
    ).then(results => {
      setCounts(Object.fromEntries(results));
    });
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  useEffect(() => {
    fetchDossiers();
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    setTotal();
    setPage(0);
    fetchDossiers();
  }, [filter]);

  useEffect(() => {
    feathers.service('dossiers').on('patched', onUpdate);
    feathers.service('dossiers').on('created', onUpdate);
    return () => {
      feathers.service('dossiers').off('patched', onUpdate);
      feathers.service('dossiers').off('created', onUpdate);
    };
  }, [filter]);

  if (!counts) {
    return null;
  }

  return (
    <div>
      <div className={classes.menu}>
        <ul>
          {menu.map(({ id, label }) => (
            <li
              key={id}
              className={clsx(filter === id && classes.selected)}
              onClick={() => setFilter(id)}
            >
              {`${label} (${counts[id]})`}
            </li>
          ))}
        </ul>
      </div>
      {dossiers ? (
        dossiers.length ? (
          <div className={classes.dossiers}>
            {(stack
              ? sortBy(dossiers, 'updated_at').slice(0, 1)
              : dossiers
            ).map(d => (
              <Dossier
                key={d.id}
                dossier={d}
                initialOpen={!!stack}
                handleUnseen={handleUnseen}
              />
            ))}
          </div>
        ) : (
          <div className={classes.empty}>{t(`empty.${filter}`)}</div>
        )
      ) : null}
      {!stack && (
        <div className={classes.pagination}>
          {page > 0 && (
            <span onClick={() => setPage(page - 1)}>Page précédente</span>
          )}
          {(page + 1) * 10 < total && (
            <span onClick={() => setPage(page + 1)}>Page suivante</span>
          )}
        </div>
      )}
    </div>
  );
};

export default Viewer;
