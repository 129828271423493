import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/styles';
import feathers from 'services/feathers';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import getTaxeSejour from 'commons/utils/getTaxeSejour';
import offreGratuiteSchema from 'commons/schemas/offreGratuite/form';

import FirstTable from 'components/dossier/offreForm/firstTable';
import SecondTable from 'components/dossier/offreForm/secondTable';

import Form from 'commons/components/form';
import Field from 'commons/components/field';
import Button from 'commons/components/button';

import DemandeTable from './demandeTable';
import LangPicker from './langPicker';
import { setNestedObjectValues } from 'formik';

const useStyles = makeStyles(theme => ({
  btn: {
    background: 'red',
    cursor: 'pointer',
  },
  explication: {
    marginBottom: theme.space.medium,
    marginLeft: theme.space.medium,
  },
  wrapper: {
    display: 'flex',
    marginBottom: theme.space.medium,
  },
  left: {
    width: '16rem',
    alignItems: 'flex-end',
    marginRight: theme.space.small,
    display: 'flex',
    paddingBottom: '5rem',

    '& textarea': {
      border: `1px solid ${theme.color.dark}`,
    },
  },
  right: {
    '& strong': {
      display: 'block',
      marginBottom: theme.space.tiny,
      marginTop: theme.space.small,
    },
  },
  langue: {
    display: 'flex',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& div.react-tel-input': {
      width: 'auto',
    },

    '& div.sms': {
      width: '100%',
      marginLeft: theme.space.tiny,
      marginRight: theme.space.tiny,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',

      '& .fraisSMS': {
        textAlign: 'right',
        marginTop: '.3rem',
        ...theme.font.size.small,
        '& input.frais': {
          backgroundColor: theme.color.yellow.light,
          color: 'black',
          width: '2.5rem',
          border: 'none',
          marginLeft: '0.2rem',
        },
      },
    },

    '& input': {
      width: '100%',
      maxWidth: '12rem',
      flex: 1,
      marginLeft: theme.space.small,
      textAlign: 'left',
      border: `1px solid ${theme.color.dark}`,
    },
  },
  step3: {
    '& img': {
      width: '2rem',
      transform: 'rotate(180deg)',
    },
  },
}));

// On est obligé de faire ça pour s'assurer que le datepicker commence au "aujourd'hui parisien"
const getParisDatetime = () =>
  dayjs(new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

const OffreGratuite = ({ store }) => {
  const { t } = useTranslation('offre_gratuite');
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { email } = qs.parse(location.search);

  const etablissement = store.etablissement;

  return (
    <Form.Formik
      initialValues={{
        // email: etablissement.active ? email || '' : store.user.email,
        tel_mobile: etablissement.active
          ? ''
          : etablissement.nbr_envois_SMS_gratuits < 10
          ? store.etablissement.contact_horeme.tel_mobile
          : '',
        arrivee: getParisDatetime().toDate(),
        depart: getParisDatetime().add(1, 'day').toDate(),
        nb_chambres: 1,
        adultes: 2,
        enfants: 0,
        chambres: etablissement.chambres.map(c => ({
          quantite: 0,
          lit_bebe: 'NON_PROPOSE',
          lit_appoint: 'NON_PROPOSE',
          prix_par_nuit: etablissement.tarifs.map(() => null),
        })),
        taxe_sejour: getTaxeSejour({ etablissement, adultes: 2, enfants: 0 }),
        prix_lit_bebe: etablissement.finance.prix_lit_bebe,
        prix_lit_appoint: etablissement.finance.prix_lit_appoint,
        message: '',
        etablissement_updated_at: etablissement.updated_at,
        lang: 'fr',
      }}
      validationSchema={offreGratuiteSchema}
      validate={values => {
        const errors = {};
        // Comptage des chambres
        const chambresCount = values.chambres.reduce(
          (acc, e) => acc + e.quantite,
          0,
        );
        if (chambresCount < values.nb_chambres) {
          errors._general = t('nombre-chambre-insuffisant');
        }
        return errors;
      }}
      onSubmit={async ({
        lang,
        etablissement_updated_at,
        depart,
        arrivee,
        ...values
      }) => {
        console.log(values);
        try {
          await feathers.service('dossiers').create({
            lang,
            parcours: [
              {
                ...values,
                email:
                  !etablissement.active &
                  (etablissement.nbr_envois_SMS_gratuits < 10)
                    ? store.user.email
                    : '',
                arrivee: dayjs(arrivee).format('YYYY-MM-DD'),
                depart: dayjs(depart).format('YYYY-MM-DD'),
              },
            ],
            offre_gratuite: true,
            etablissement_updated_at,
          });
          if (
            !etablissement.active &
            (etablissement.nbr_envois_SMS_gratuits < 10)
          ) {
            await feathers.service('etablissements').patch(null, {
              action: 'envoi_SMS',
            });
          }
          window.alert(t('envoi_reussi'));
          await history.push(`/`);
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ values }) => (
        <>
          <strong className={classes.explication}>{t('explication')}</strong>
          <div className={classes.wrapper}>
            <div className={classes.left}>
              <Field
                style={{ resize: 'none' }}
                as="textarea"
                rows={10}
                name="message"
                placeholder={t('message')}
              />
            </div>
            <div className={classes.right}>
              <Form>
                <strong style={{ marginTop: 0 }}>{t('step1')}</strong>
                <DemandeTable etablissement={etablissement} />
                <strong>{t('step2')}</strong>
                <FirstTable
                  etablissement={etablissement}
                  nb_nuits={dayjs(values.depart).diff(
                    dayjs(values.arrivee),
                    'day',
                  )}
                />
                <div className={classes.step3}>
                  <strong>{t('step3')}</strong>
                  <img src="/arrow.svg" />
                  <SecondTable />
                </div>
                <div className={classes.langue}>
                  <strong>{t('step4')}</strong>
                  <LangPicker name="lang" />
                </div>
                <div className={classes.footer}>
                  {!etablissement.active && (
                    <div>
                      <p>
                        {t('nbr-envoi-sms-gratuits')} :{' '}
                        {etablissement.nbr_envois_SMS_gratuits}/10
                      </p>
                      <p>
                        {' '}
                        {!etablissement.active &
                        (etablissement.nbr_envois_SMS_gratuits < 10)
                          ? `${t('non-actif')}`
                          : !etablissement.active &
                            (etablissement.nbr_envois_SMS_gratuits >= 10)
                          ? `${t('non-actif-limite')}`
                          : null}
                      </p>
                    </div>
                  )}
                </div>
                <div className={classes.footer}>
                  <strong>{t('step5')}</strong>
                  <div className="sms">
                    <Field.PhoneOffreSMS
                      name="tel_mobile"
                      id="phoneInput"
                      disabled={!etablissement.active}
                      freeSMS={
                        !etablissement.active &
                        (etablissement.nbr_envois_SMS_gratuits < 10)
                      }
                    />
                  </div>
                  <Button
                    type="submit"
                    disabled={
                      !etablissement.enabled &
                        (etablissement.nbr_envois_SMS_gratuits >= 10) ||
                      !etablissement.active &
                        (etablissement.nbr_envois_SMS_gratuits >= 10)
                    }
                  >
                    {t('envoyer')}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(OffreGratuite);
