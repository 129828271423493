import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactCountryFlag from 'react-country-flag';
import dayjs from 'dayjs';

import 'dayjs/locale/fr';

const useStyles = makeStyles(theme => ({
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    fontSize: '2rem',
    display: 'flex',
  },
}));

/*
waiting for https://github.com/isaachinman/next-i18next/pull/569
*/
const LocaleSwitch = ({ i18n }) => {
  const classes = useStyles();

  useEffect(() => {
    dayjs.updateLocale('fr', {
      formats: {
        LTS: 'HH:mm:ss',
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'dddd D MMMM YYYY',
        LLLL: 'dddd D MMMM YYYY à HH:mm',
      },
    });

    dayjs.updateLocale('en', {
      formats: {
        LTS: 'h:mm:ss A',
        LT: 'h:mm A',
        L: 'MM/DD/YYYY',
        LL: 'MMMM D, YYYY',
        LLL: 'dddd, MMMM D, YYYY',
        LLLL: 'dddd, MMMM D, YYYY h:mm A',
      },
    });
  }, []);

  return (
    <button
      className={classes.button}
      type="button"
      onClick={() => {
        const lang = i18n.language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(lang);
        dayjs.locale(lang);
      }}
    >
      {i18n.language && (
        <ReactCountryFlag
          svg
          countryCode={i18n.language === 'en' ? 'GB' : 'FR'}
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      )}
    </button>
  );
};

export default LocaleSwitch;
