const dayjs = require('dayjs');
const { assign } = require('lodash');

const getPenaliteApplicable = require('./getPenaliteApplicable');

const getParisDatetime = () =>
  dayjs(new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

const isActionAvailable = ({ dossier, statut }) => {
  const parcours_squashed = assign({}, ...dossier.parcours);
  const { arrivee_date } = parcours_squashed;

  if (statut === 'R_ANNULEE_NON_PRESENTATION') {
    if (
      getParisDatetime().isBefore(dayjs(arrivee_date).add(1, 'day')) ||
      getParisDatetime().isAfter(dayjs(arrivee_date).add(2, 'days'))
    ) {
      return false;
    }
  }

  if (['R_ANNULEE_CLIENT', 'RESERVATION_CB_INVALIDE'].includes(statut)) {
    if (getParisDatetime().isAfter(dayjs(arrivee_date).add(1, 'day'))) {
      return false;
    }
  }

  if (statut === 'RESERVATION_MODIF_DATES') {
    const parcours_squashed = assign({}, ...dossier.parcours);
    const { tarif, etablissement, tarifs_ids } = parcours_squashed;

    const { applicable } = getPenaliteApplicable({
      dossier,
      tarif: etablissement.tarifs[tarifs_ids[tarif]],
    });
    if (applicable) {
      return false;
    }
  }

  if (statut === 'R_ANNULEE_CB_INVALIDE') {
    if (getParisDatetime().isAfter(dayjs(arrivee_date).add(1, 'day'))) {
      return false;
    }
  }

  return true;
};

module.exports = isActionAvailable;
