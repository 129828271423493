module.exports = [
  'chambre_simple',
  'chambre_simple_superieure',
  'chambre_simple_avec_balcon',
  'chambre_simple_avec_terrasse',
  'chambre_simple_vue_sur_ville',
  'chambre_simple_vue_sur_cour',
  'chambre_simple_vue_sur_jardin',
  'chambre_simple_vue_sur_mer',
  'chambre_simple_vue_sur_piscine',
  'chambre_simple_vue_sur_lac',
  'chambre_simple_vue_sur_riviere',
  'chambre_simple_vue_sur_montagne',
  'chambre_double',
  'chambre_double_superieure',
  'chambre_double_avec_balcon',
  'chambre_double_avec_terrasse',
  'chambre_double_vue_sur_ville',
  'chambre_double_vue_sur_cour',
  'chambre_double_vue_sur_jardin',
  'chambre_double_vue_sur_mer',
  'chambre_double_vue_sur_piscine',
  'chambre_double_vue_sur_lac',
  'chambre_double_vue_sur_riviere',
  'chambre_double_vue_sur_montagne',
  'chambre_triple',
  'chambre_triple_superieure',
  'chambre_triple_avec_balcon',
  'chambre_triple_avec_terrasse',
  'chambre_triple_vue_sur_ville',
  'chambre_triple_vue_sur_cour',
  'chambre_triple_vue_sur_jardin',
  'chambre_triple_vue_sur_mer',
  'chambre_triple_vue_sur_piscine',
  'chambre_triple_vue_sur_lac',
  'chambre_triple_vue_sur_riviere',
  'chambre_triple_vue_sur_montagne',
  'chambre_quadruple',
  'chambre_quadruple_superieure',
  'chambre_quadruple_avec_balcon',
  'chambre_quadruple_avec_terrasse',
  'chambre_quadruple_vue_sur_ville',
  'chambre_quadruple_vue_sur_cour',
  'chambre_quadruple_vue_sur_jardin',
  'chambre_quadruple_vue_sur_mer',
  'chambre_quadruple_vue_sur_piscine',
  'chambre_quadruple_vue_sur_lac',
  'chambre_quadruple_vue_sur_riviere',
  'chambre_quadruple_vue_sur_montagne',
  'chambre_familiale',
  'chambre_familiale_superieure',
  'chambre_familiale_avec_balcon',
  'chambre_familiale_avec_terrasse',
  'chambre_familiale_vue_sur_ville',
  'chambre_familiale_vue_sur_cour',
  'chambre_familiale_vue_sur_jardin',
  'chambre_familiale_vue_sur_mer',
  'chambre_familiale_vue_sur_piscine',
  'chambre_familiale_vue_sur_lac',
  'chambre_familiale_vue_sur_riviere',
  'chambre_familiale_vue_sur_montagne',
  'chambre_standard',
  'chambre_standard_avec_balcon',
  'chambre_standard_avec_terrasse',
  'chambre_standard_vue_sur_ville',
  'chambre_standard_vue_sur_cour',
  'chambre_standard_vue_sur_jardin',
  'chambre_standard_vue_sur_mer',
  'chambre_standard_vue_sur_piscine',
  'chambre_standard_vue_sur_lac',
  'chambre_standard_vue_sur_riviere',
  'chambre_standard_vue_sur_montagne',
  'chambre_classique',
  'chambre_classique_avec_balcon',
  'chambre_classique_avec_terrasse',
  'chambre_classique_vue_sur_ville',
  'chambre_classique_vue_sur_cour',
  'chambre_classique_vue_sur_jardin',
  'chambre_classique_vue_sur_mer',
  'chambre_classique_vue_sur_piscine',
  'chambre_classique_vue_sur_lac',
  'chambre_classique_vue_sur_riviere',
  'chambre_classique_vue_sur_montagne',
  'chambre_superieure',
  'chambre_superieure_avec_balcon',
  'chambre_superieure_avec_terrasse',
  'chambre_superieure_vue_sur_ville',
  'chambre_superieure_vue_sur_cour',
  'chambre_superieure_vue_sur_jardin',
  'chambre_superieure_vue_sur_mer',
  'chambre_superieure_vue_sur_piscine',
  'chambre_superieure_vue_sur_lac',
  'chambre_superieure_vue_sur_riviere',
  'chambre_superieure_vue_sur_montagne',
  'chambre_prestige',
  'chambre_prestige_avec_balcon',
  'chambre_prestige_avec_terrasse',
  'chambre_prestige_vue_sur_ville',
  'chambre_prestige_vue_sur_cour',
  'chambre_prestige_vue_sur_jardin',
  'chambre_prestige_vue_sur_mer',
  'chambre_prestige_vue_sur_piscine',
  'chambre_prestige_vue_sur_lac',
  'chambre_prestige_vue_sur_riviere',
  'chambre_prestige_vue_sur_montagne',
  'chambre_executive',
  'chambre_executive_avec_balcon',
  'chambre_executive_avec_terrasse',
  'chambre_executive_vue_sur_ville',
  'chambre_executive_vue_sur_cour',
  'chambre_executive_vue_sur_jardin',
  'chambre_executive_vue_sur_mer',
  'chambre_executive_vue_sur_piscine',
  'chambre_executive_vue_sur_lac',
  'chambre_executive_vue_sur_riviere',
  'chambre_executive_vue_sur_montagne',
  'chambre_deluxe',
  'chambre_deluxe_avec_balcon',
  'chambre_deluxe_avec_terrasse',
  'chambre_deluxe_vue_sur_ville',
  'chambre_deluxe_vue_sur_cour',
  'chambre_deluxe_vue_sur_jardin',
  'chambre_deluxe_vue_sur_mer',
  'chambre_deluxe_vue_sur_piscine',
  'chambre_deluxe_vue_sur_lac',
  'chambre_deluxe_vue_sur_riviere',
  'chambre_deluxe_vue_sur_montagne',
  'suite',
  'suite_avec_balcon',
  'suite_avec_terrasse',
  'suite_vue_sur_ville',
  'suite_vue_sur_cour',
  'suite_vue_sur_jardin',
  'suite_vue_sur_mer',
  'suite_vue_sur_piscine',
  'suite_vue_sur_lac',
  'suite_vue_sur_riviere',
  'suite_vue_sur_montagne',

  'suite_familiale',
  'suite_familiale_avec_balcon',
  'suite_familiale_avec_terrasse',
  'suite_familiale_vue_sur_ville',
  'suite_familiale_vue_sur_cour',
  'suite_familiale_vue_sur_jardin',
  'suite_familiale_vue_sur_mer',
  'suite_familiale_vue_sur_piscine',
  'suite_familiale_vue_sur_lac',
  'suite_familiale_vue_sur_rivizre',
  'suite_familiale_vue_sur_montagne',

  'suite_superieure',
  'suite_superieure_avec_balcon',
  'suite_superieure_avec_terrasse',
  'suite_superieure_vue_sur_ville',
  'suite_superieure_vue_sur_cour',
  'suite_superieure_vue_sur_jardin',
  'suite_superieure_vue_sur_mer',
  'suite_superieure_vue_sur_piscine',
  'suite_superieure_vue_sur_lac',
  'suite_superieure_vue_sur_riviere',
  'suite_superieure_vue_sur_montagne',
  'suite_prestige',
  'suite_prestige_avec_balcon',
  'suite_prestige_avec_terrasse',
  'suite_prestige_vue_sur_ville',
  'suite_prestige_vue_sur_cour',
  'suite_prestige_vue_sur_jardin',
  'suite_prestige_vue_sur_mer',
  'suite_prestige_vue_sur_piscine',
  'suite_prestige_vue_sur_lac',
  'suite_prestige_vue_sur_riviere',
  'suite_prestige_vue_sur_montagne',
  'suite_junior',
  'suite_junior_avec_balcon',
  'suite_junior_avec_terrasse',
  'suite_junior_vue_sur_ville',
  'suite_junior_vue_sur_cour',
  'suite_junior_vue_sur_jardin',
  'suite_junior_vue_sur_mer',
  'suite_junior_vue_sur_piscine',
  'suite_junior_vue_sur_lac',
  'suite_junior_vue_sur_riviere',
  'suite_junior_vue_sur_montagne',
  'suite_deluxe',
  'suite_deluxe_avec_balcon',
  'suite_deluxe_avec_terrasse',
  'suite_deluxe_vue_sur_ville',
  'suite_deluxe_vue_sur_cour',
  'suite_deluxe_vue_sur_jardin',
  'suite_deluxe_vue_sur_mer',
  'suite_deluxe_vue_sur_piscine',
  'suite_deluxe_vue_sur_lac',
  'suite_deluxe_vue_sur_riviere',
  'suite_deluxe_vue_sur_montagne',
  'suite_galerie',
  'suite_galerie_avec_balcon',
  'suite_galerie_avec_terrasse',
  'suite_galerie_vue_sur_ville',
  'suite_galerie_vue_sur_cour',
  'suite_galerie_vue_sur_jardin',
  'suite_galerie_vue_sur_mer',
  'suite_galerie_vue_sur_piscine',
  'suite_galerie_vue_sur_lac',
  'suite_galerie_vue_sur_riviere',
  'suite_galerie_vue_sur_montagne',
  'suite_signature',
  'suite_signature_avec_balcon',
  'suite_signature_avec_terrasse',
  'suite_signature_vue_sur_ville',
  'suite_signature_vue_sur_cour',
  'suite_signature_vue_sur_jardin',
  'suite_signature_vue_sur_mer',
  'suite_signature_vue_sur_piscine',
  'suite_signature_vue_sur_lac',
  'suite_signature_vue_sur_riviere',
  'suite_signature_vue_sur_montagne',
  'suite_presidentielle',
  'suite_presidentielle_avec_terrasse',
  'penthouse',
  'penthouse_avec_terrasse',
  'studio',
  'studio_avec_balcon',
  'studio_avec_terrasse',
  'studio_vue_sur_ville',
  'studio_vue_sur_cour',
  'studio_vue_sur_jardin',
  'studio_vue_sur_mer',
  'studio_vue_sur_piscine',
  'studio_vue_sur_lac',
  'studio_vue_sur_riviere',
  'studio_vue_sur_montagne',
  'studio_superieur',
  'studio_superieur_avec_balcon',
  'studio_superieur_avec_terrasse',
  'studio_superieur_vue_sur_ville',
  'studio_superieur_vue_sur_cour',
  'studio_superieur_vue_sur_jardin',
  'studio_superieur_vue_sur_mer',
  'studio_superieur_vue_sur_piscine',
  'studio_superieur_vue_sur_lac',
  'studio_superieur_vue_sur_rivière',
  'studio_superieur_vue_sur_montagne',

  'appartement',
  'appartement_avec_balcon',
  'appartement_avec_terrasse',
  'appartement_vue_sur_ville',
  'appartement_vue_sur_cour',
  'appartement_vue_sur_jardin',
  'appartement_vue_sur_mer',
  'appartement_vue_sur_piscine',
  'appartement_vue_sur_lac',
  'appartement_vue_sur_riviere',
  'appartement_vue_sur_montagne',
  'appartement_1_chambre',
  'appartement_1_chambre_avec_balcon',
  'appartement_1_chambre_avec_terrasse',
  'appartement_1_chambre_vue_sur_ville',
  'appartement_1_chambre_vue_sur_cour',
  'appartement_1_chambre_vue_sur_jardin',
  'appartement_1_chambre_vue_sur_mer',
  'appartement_1_chambre_vue_sur_piscine',
  'appartement_1_chambre_vue_sur_lac',
  'appartement_1_chambre_vue_sur_rivière',
  'appartement_1_chambre_vue_sur_montagne',

  'appartement_2_chambres',
  'appartement_2_chambres_avec_balcon',
  'appartement_2_chambres_avec_terrasse',
  'appartement_2_chambres_vue_sur_ville',
  'appartement_2_chambres_vue_sur_cour',
  'appartement_2_chambres_vue_sur_jardin',
  'appartement_2_chambres_vue_sur_mer',
  'appartement_2_chambres_vue_sur_piscine',
  'appartement_2_chambres_vue_sur_lac',
  'appartement_2_chambres_vue_sur_rivière',
  'appartement_2_chambres_vue_sur_montagne',

  'appartement_3_chambres',
  'appartement_3_chambres_avec_balcon',
  'appartement_3_chambres_avec_terrasse',
  'appartement_3_chambres_vue_sur_ville',
  'appartement_3_chambres_vue_sur_cour',
  'appartement_3_chambres_vue_sur_jardin',
  'appartement_3_chambres_vue_sur_mer',
  'appartement_3_chambres_vue_sur_piscine',
  'appartement_3_chambres_vue_sur_lac',
  'appartement_3_chambres_vue_sur_rivière',
  'appartement_3_chambres_vue_sur_montagne',

  'appartement_standard',
  'appartement_standard_avec_balcon',
  'appartement_standard_avec_terrasse',
  'appartement_standard_vue_sur_ville',
  'appartement_standard_vue_sur_cour',
  'appartement_standard_vue_sur_jardin',
  'appartement_standard_vue_sur_mer',
  'appartement_standard_vue_sur_piscine',
  'appartement_standard_vue_sur_lac',
  'appartement_standard_vue_sur_rivière',
  'appartement_standard_vue_sur_montagne',

  'appartement_supérieur',
  'appartement_supérieur_avec_balcon',
  'appartement_supérieur_avec_terrasse',
  'appartement_supérieur_vue_sur_ville',
  'appartement_supérieur_vue_sur_cour',
  'appartement_supérieur_vue_sur_jardin',
  'appartement_supérieur_vue_sur_mer',
  'appartement_supérieur_vue_sur_piscine',
  'appartement_supérieur_vue_sur_lac',
  'appartement_supérieur_vue_sur_rivière',
  'appartement_supérieur_vue_sur_montagne',

  'appartement_prestige',
  'appartement_prestige_avec_balcon',
  'appartement_prestige_avec_terrasse',
  'appartement_prestige_vue_sur_ville',
  'appartement_prestige_vue_sur_cour',
  'appartement_prestige_vue_sur_jardin',
  'appartement_prestige_vue_sur_mer',
  'appartement_prestige_vue_sur_piscine',
  'appartement_prestige_vue_sur_lac',
  'appartement_prestige_vue_sur_rivière',
  'appartement_prestige_vue_sur_montagne',

  'appartement_deluxe',
  'appartement_deluxe_avec_balcon',
  'appartement_deluxe_avec_terrasse',
  'appartement_deluxe_vue_sur_ville',
  'appartement_deluxe_vue_sur_cour',
  'appartement_deluxe_vue_sur_jardin',
  'appartement_deluxe_vue_sur_mer',
  'appartement_deluxe_vue_sur_piscine',
  'appartement_deluxe_vue_sur_lac',
  'appartement_deluxe_vue_sur_rivière',
  'appartement_deluxe_vue_sur_montagne',

  'villa',
  'villa_1_chambre',
  'villa_2_chambres',
  'villa_3_chambres',
  'villa_4_chambres',
  'villa_5_chambres',
  'villa_supérieure',
  'villa_supérieure_avec_piscine',
  'villa_supérieure_vue_sur_mer',
  'villa_prestige',
  'villa_prestige_avec_piscine',
  'villa_prestige_vue_sur_mer',
  'villa_deluxe',
  'villa_deluxe_avec_piscine',
  'villa_deluxe_vue_sur_mer',

  'villa_avec_piscine',
  'villa_vue_sur_mer',

  'bungalow',
  'bungalow_1_chambre',
  'bungalow_2_chambres',
  'bungalow_3_chambres',
  'bungalow_supérieur',
  'bungalow_supérieur_vue_sur_mer',
  'bungalow_prestige',
  'bungalow_prestige_vue_sur_mer',
  'bungalow_deluxe',
  'bungalow_deluxe_vue_sur_mer',
  'bungalow_avec_piscine',
  'bungalow_vue_sur_mer',
];
