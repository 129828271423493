import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { CountryDropdown } from 'react-country-region-selector';

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
    minHeight: '1.5rem',

    '&[disabled]': {
      background: theme.color.grey.light,
    },
  },
  error: {
    ...theme.error,
  },
}));

const CountryField = ({ className, name, disabled, ...props }) => {
  const { t } = useTranslation('shared');
  const classes = useStyles();

  const { values, setFieldValue, errors, status } = useFormikContext();

  return (
    <CountryDropdown
      style={{ height: '1.5rem' }}
      defaultOptionLabel={t('choisissez-pays')}
      value={get(values, name)}
      onChange={value => setFieldValue(name, value)}
      className={clsx(
        classes.input,
        className,
        get(errors, name) != null && classes.error,
      )}
      disabled={disabled || (status && status.disabled)}
      {...props}
    />
  );
};

export default CountryField;
