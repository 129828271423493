import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import Messagerie from '../messagerie';

const useStyles = makeStyles(theme => ({
  messagerie: {
    padding: theme.space.tiny,
    textAlign: 'center',
    background: theme.color.blue.light,
    border: `1px solid ${theme.color.dark}`,
    marginBottom: theme.space.tiny,

    '& h2': {
      textTransform: 'uppercase',
      ...theme.font.size.medium,
      textAlign: 'center',
      marginBottom: theme.space.small,
    },
  },
}));

const MessagerieBlock = ({ dossier }) => {
  const classes = useStyles();
  const { t } = useTranslation('dossier');

  return (
    <div className={classes.messagerie}>
      <h2>{t('reservation.historique_messages')}</h2>
      <Messagerie dossier={dossier} />
    </div>
  );
};

export default MessagerieBlock;
