import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
  },
}));

const Infos = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation('etablissement');
  const {
    etablissement: { nom, adresse, categorie, classement, site_internet },
  } = store;

  return (
    <div>
      <h2>{t('presentation.etablissement')}</h2>
      <div className={classes.wrapper}>
        <strong>{nom}</strong>
        <p>{adresse}</p>
        <p>
          {t(`shared:categorie.${categorie}`)}{' '}
          {t(`shared:classement.${classement}`)}
        </p>
        <p>{site_internet}</p>
      </div>
    </div>
  );
};

export default compose(inject('store'), observer)(Infos);
