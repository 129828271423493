import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'commons/components/button';
import Modal from 'commons/components/modal';

import ChambreForm from './chambreForm';
import Liste from './liste';
import ServicesForm from './servicesForm';

const Chambres = () => {
  const { t } = useTranslation('etablissement');

  return (
    <>
      <div>
        <h2>{t('chambres.form.ajouter_une_chambre')}</h2>
        <Modal
          renderContent={close => <ChambreForm onSuccess={close} />}
          renderButton={open => (
            <Button onClick={open}>{t('common:ajouter')}</Button>
          )}
        />
      </div>
      <Liste />
      <ServicesForm />
    </>
  );
};

export default Chambres;
