import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';

const Admin = ({ store }) => {
  if (store.user.type !== 'ADMIN') {
    return null;
  }

  return (
    <div>
      <h3>Admin Only</h3>

      {!store.etablissement.active ? (
        !store.etablissement.complete ? (
          "L'établissement n'a pas encore complété ses informations"
        ) : !store.etablissement.code_ok ? (
          "L'établissement n'a pas encore renseigné le code d'activation"
        ) : (
          <button
            onClick={() => {
              if (window.confirm('Etes vous sur ?')) {
                feathers
                  .service('etablissements')
                  .patch(store.etablissement.id, {
                    action: 'validate-inscription',
                  });
              }
            }}
          >
            Valider l'inscription de l'établissement
          </button>
        )
      ) : store.etablissement.enabled ? (
        <button
          onClick={() => {
            if (window.confirm('Etes vous sur ?')) {
              feathers.service('etablissements').patch(store.etablissement.id, {
                action: 'admin',
                enabled: false,
                nbr_envois_SMS_gratuits: 10,
              });
            }
          }}
        >
          Désactiver l'établissement
        </button>
      ) : (
        <button
          onClick={() => {
            if (window.confirm('Etes vous sur ?')) {
              feathers.service('etablissements').patch(store.etablissement.id, {
                action: 'admin',
                enabled: true,
              });
            }
          }}
        >
          Ré-activer l'établissement
        </button>
      )}
    </div>
  );
};

export default compose(inject('store'), observer)(Admin);
