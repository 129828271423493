import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  box: {
    height: 100,
    width: 100,
    border: `2px solid ${theme.color.blue.light}`,
    borderRadius: '10%',
    overflow: 'hidden',
    margin: theme.space.tiny,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const Box = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.box, className)} {...props}>
      {children}
    </div>
  );
};

export default Box;
