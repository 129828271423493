import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';

import etablissementSchema from 'commons/schemas/etablissement';

import Field from 'commons/components/field';
import Button from 'commons/components/button';
import Form from 'commons/components/form';
import { notify } from 'commons/components/notifier';

const useStyles = makeStyles(theme => ({
  explication: {
    fontStyle: 'italic',
  },
}));

const ContactClientForm = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;
  const classes = useStyles();

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'contact_client')}
      initialValues={etablissement.contact_client}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'contact_client', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form styled showDirty>
          <h2>{t('presentation.contact_client')}</h2>
          <p className={classes.explication}>
            {t('presentation.infos-visibles')}
          </p>
          <label htmlFor="tel">{t('presentation.tel')}</label>
          <Field.Phone name="tel" />
          <label htmlFor="email">{t('presentation.email')}</label>
          <Field type="email" name="email" />
          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(ContactClientForm);
