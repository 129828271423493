import React from 'react';
import clsx from 'clsx';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.color.dark,
  },
}));

const defaultTheme = createMuiTheme();

const Icon = ({ icon, className }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <ThemeProvider theme={defaultTheme}>{icon}</ThemeProvider>
    </div>
  );
};

export default Icon;
