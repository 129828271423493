import React from 'react';
import { Trans } from 'react-i18next';
import Modal from 'commons/components/modal';

import useStyles from './useStyles';

const Popup2 = () => {
  const classes = useStyles();

  return (
    <Modal
      initial={true}
      renderContent={() => (
        <div className={classes.wrapper}>
          <Trans i18nKey="etablissement:popups.2">
            <p>Vous venez de compléter votre établissement!</p>
            <ul>
              <li>
                <strong>Commencez à tester l’envoi d’offres gratuites</strong>{' '}
                sur votre propre adresse email.{' '}
                <strong>
                  Grace à ces offres vous pouvez vous entrainer à gérer des
                  réservations
                </strong>{' '}
                en tant qu’établissement et même vous mettre à la place de vos
                clients (pour réserver, modifier et évaluer). En haut à droite,
                à côté du drapeau la « Notice » vous aidera à utiliser votre
                Extranet.
              </li>
              <li>
                Regardez la fiche de votre établissement en cliquant sur : «
                Lien vers la fiche de l’établissement » . Celle-ci sera visible
                sur
                <a href="www.horeme.com">horeme.com</a> qu’après notre
                validation.
              </li>
              <li>
                Améliorez votre établissement en ajoutant l’ensemble de vos
                chambres, conditions tarifaires, photos, etc. - vous serez déjà
                prêts à répondre à de vraies demandes!
              </li>
              <li>
                Validez le mandat de prélèvement GoCardLess que nous allons vous
                envoyer par email (utile uniquement pour les réservations faites
                via notre site internet : 1 à 2 EUR par nuit et par personne)
              </li>
            </ul>
            <p>
              <strong>
                Vous allez bientôt recevoir un code d’activation par voie
                postale,
              </strong>
              dès que vous le rentrerez sur votre Extranet (onglet « Compte »),
              nous vérifierons votre fiche et activerons votre établissement,
              alors :
            </p>
            <ul>
              <li>
                vous pourrez gagner du temps et augmenter les chances de
                réservation en envoyant en quelques secondes seulement des
                offres claires à n’importe quel client qui vous demande un prix
                par email ou téléphone : pour rappel, aucune commission ne sera
                prélevée sur toutes ces réservations !
                <strong>C’est totalement gratuit !</strong>
              </li>
              <li>
                votre établissement sera visible sur www.horeme.com
                <strong>(tout en 1 seule page!)</strong> et vous pourrez
                recevoir des demandes de nouveaux clients : sur ces réservations
                nous facturerons
                <strong>
                  1 EUR HT par personne et par nuit pour les montants inférieurs
                  à 100 EUR/nuit et 2 EUR HT par personne et par nuit pour tous
                  les montants à partir de 100EUR/nuit et c’est tout!
                </strong>
                Si le client ne réserve pas suite à une offre envoyée ou s’il
                annule gratuitement, cela ne vous coutera évidemment strictement
                rien.
              </li>
              <li>
                vous pourrez continuer de modifier librement votre établissement
              </li>
              <li>
                tous vos dossiers de tests (demandes et réservations) seront
                effacés, votre Extranet deviendra réel, avec de vraies
                réservations!
              </li>
            </ul>
          </Trans>
        </div>
      )}
    />
  );
};

export default Popup2;
