module.exports = [
  'visa',
  'mastercard',
  'amex',
  'jcb',
  'discover',
  'dinersclub',
  'maestro',
  'unionpay',
  'especes',
  'cheques-vacances',
  'virement',
];
