import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import feathers from 'services/feathers';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Number from 'commons/components/number';
import Table from 'components/table';

const useStyles = makeStyles(theme => ({
  wrapper: {
    textAlign: 'center',
    display: 'flex',
  },
  left: {
    margin: `0 ${theme.space.small}`,
  },
  total: {
    marginTop: theme.space.tiny,
    ...theme.font.weight.bold,
  },
  green: {
    color: theme.color.green.dark,
  },
  red: {
    color: theme.color.red.dark,
  },
  empty: {
    textAlign: 'center',
    margin: '0 8rem',
  },
}));

const Commissions = ({ date }) => {
  const { t } = useTranslation('horeme');
  const classes = useStyles();

  const start = dayjs(date).startOf('month');
  const end = dayjs(date).endOf('month');
  const [dossiers, setDossiers] = useState();

  const fetch = async ({ list = [] } = {}) => {
    return feathers
      .service('dossiers')
      .find({
        query: {
          $skip: list.length,
          depart: {
            $gte: start.format('YYYY-MM-DD'),
            $lte: end.format('YYYY-MM-DD'),
          },
          statut: {
            $in: [
              'R_ANNULEE_CLIENT',
              'R_ANNULEE_CB_INVALIDE',
              'R_ANNULEE_NON_PRESENTATION',
              'R_ANNULEE_MODIF_DATES',
              'R_TERMINEE',
            ],
          },
          $select: [
            'id',
            'shortid',
            'commission',
            'prenom',
            'nom',
            'arrivee',
            'depart',
            'offre_gratuite',
            'CB_non_debitee',
          ],
          $sort: {
            depart: 1,
          },
        },
      })
      .then(async ({ total, data }) => {
        const newList = [...list, ...data];
        const rest =
          total > newList.length ? await fetch({ list: newList }) : [];

        return [...data, ...rest];
      });
  };

  useEffect(() => {
    setDossiers(null);
  }, [date]);

  useEffect(() => {
    if (dossiers === null) {
      fetch().then(setDossiers);
    }
  }, [dossiers]);

  if (!dossiers) return null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.left}>
        {dossiers.length !== 0 && (
          <Table>
            <thead>
              <tr>
                <th>{t('id')}</th>
                <th>{t('name')}</th>
                <th>{t('arrivee')}</th>
                <th>{t('depart')}</th>
                <th />
                <th>{t('commission')}</th>
              </tr>
            </thead>
            <tbody>
              {dossiers.map(d => (
                <tr>
                  <td>{d.shortid}</td>
                  <td>
                    {d.prenom} {d.nom}
                  </td>
                  <td>{dayjs(d.arrivee, 'YYYY-MM-DD').format('LL')}</td>
                  <td>{dayjs(d.depart, 'YYYY-MM-DD').format('LL')}</td>
                  <td
                    className={clsx(
                      d.offre_gratuite
                        ? classes.green
                        : d.CB_non_debitee
                        ? classes.red
                        : null,
                    )}
                  >
                    {d.offre_gratuite
                      ? t('offre_SMS')
                      : d.CB_non_debitee
                      ? t('CB_non_debitee')
                      : null}
                  </td>
                  <td
                    className={clsx(
                      d.offre_gratuite
                        ? classes.green
                        : d.CB_non_debitee
                        ? classes.red
                        : null,
                    )}
                  >
                    <Number
                      suffix={' €'}
                      fixedDecimalScale={2}
                      value={d.commission}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        {dossiers.length === 0 && (
          <div className={classes.empty}>Pas de dossier ce mois -ci</div>
        )}
      </div>
      <div className={classes.right}>
        <div>{t('reservations', { count: dossiers.length })}</div>
        <div className={classes.total}>
          Total :{' '}
          <Number
            suffix={' €'}
            fixedDecimalScale={2}
            value={dossiers.reduce((acc, e) => {
              return acc + e.commission;
            }, 0)}
          />
        </div>
      </div>
    </div>
  );
};

export default Commissions;
