import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import feathers from 'services/feathers';
import isActionAvailable from 'commons/utils/isActionAvailable';
import getPenaliteApplicable from 'commons/utils/getPenaliteApplicable';
import isCBConsultable from 'commons/utils/isCBConsultable';

import Button from 'commons/components/button';

import ConsulterCarte from './consulterCarte';
import Block from './block';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& button': {
      width: '100%',
      marginTop: theme.space.tiny,
    },
  },
  disabled: {
    color: theme.color.grey.medium,
    cursor: 'initial',
    pointerEvents: 'none',
  },
}));

const Reservation = ({ dossier, store }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();
  const history = useHistory();

  const { tarif, tarifs_ids, etablissement } = dossier.parcours_squashed;
  const penaliteApplicable = getPenaliteApplicable({
    dossier,
    tarif: etablissement.tarifs[tarifs_ids[tarif]],
  });

  const actions =
    dossier.statut === 'RESERVATION_MODIF_DATES'
      ? {
          CONSULTER_CARTE: isCBConsultable({ dossier }),
        }
      : {
          RESERVATION_CB_INVALIDE:
            dossier.statut.indexOf('RESERVATION') === 0 &&
            dossier.statut !== 'RESERVATION_CB_INVALIDE',
          R_ANNULEE_NON_PRESENTATION: isActionAvailable({
            dossier,
            statut: 'R_ANNULEE_NON_PRESENTATION',
          }),
          R_ANNULEE_CB_INVALIDE:
            dossier.statut === 'RESERVATION_CB_INVALIDE_ANNULABLE' &&
            isActionAvailable({
              dossier,
              statut: 'R_ANNULEE_CB_INVALIDE',
            }),
          CONSULTER_CARTE: isCBConsultable({ dossier }),
        };

  return (
    <div className={classes.wrapper}>
      {dossier.statut === 'RESERVATION_MODIF_DATES' ? (
        <>
          <Block
            instructions={
              <>
                <h3>
                  {t(
                    'reservation.actions.reservation.modifs-dates.accepter.title',
                  )}
                </h3>
                <p>
                  {t(
                    'reservation.actions.reservation.modifs-dates.accepter.paragraphe_1',
                  )}
                  <strong>
                    {t(
                      'reservation.actions.reservation.modifs-dates.accepter.paragraphe_1_gras',
                    )}
                  </strong>
                </p>
                <p>
                  {t(
                    'reservation.actions.reservation.modifs-dates.accepter.paragraphe_2',
                  )}
                </p>
              </>
            }
          >
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    t(
                      'reservation.actions.reservation.modifs-dates.accepter.confirm',
                    ),
                  )
                ) {
                  feathers.service('dossiers').patch(dossier.id, {
                    action: 'RESERVATION_MODIF_DATES_OK',
                  });
                }
              }}
            >
              {t(
                'reservation.actions.reservation.modifs-dates.accepter.button',
              )}
            </Button>
          </Block>
          <Block
            instructions={
              <>
                <h3>
                  {t(
                    'reservation.actions.reservation.modifs-dates.refuser.title',
                  )}
                </h3>
                <p>
                  {t(
                    'reservation.actions.reservation.modifs-dates.refuser.paragraphe_1',
                  )}
                </p>
              </>
            }
          >
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    dossier.parcours_squashed.annulation_auto
                      ? t(
                          'reservation.actions.reservation.modifs-dates.refuser.confirm-annulation_auto',
                        )
                      : t(
                          'reservation.actions.reservation.modifs-dates.refuser.confirm',
                        ),
                  )
                ) {
                  feathers.service('dossiers').patch(dossier.id, {
                    action: 'RESERVATION_MODIF_DATES_NOT_OK',
                  });
                }
              }}
            >
              {t('reservation.actions.reservation.modifs-dates.refuser.button')}
            </Button>
          </Block>
        </>
      ) : dossier.statut.indexOf('RESERVATION') === 0 ? (
        <>
          <Button
            className={!actions.R_ANNULEE_NON_PRESENTATION && classes.disabled}
            onClick={() => {
              if (
                window.confirm(
                  t('reservation.actions.reservation.non-presentation.confirm'),
                )
              ) {
                feathers
                  .service('dossiers')
                  .patch(dossier.id, { action: 'R_ANNULEE_NON_PRESENTATION' });
              }
            }}
          >
            {t('reservation.actions.reservation.non-presentation.button')}
          </Button>
          <Button
            className={!actions.R_ANNULEE_CB_INVALIDE && classes.disabled}
            onClick={() => {
              if (
                window.confirm(
                  t('reservation.actions.reservation.annuler.confirm'),
                )
              ) {
                feathers
                  .service('dossiers')
                  .patch(dossier.id, { action: 'R_ANNULEE_CB_INVALIDE' });
              }
            }}
          >
            {t('reservation.actions.reservation.annuler.button')}
          </Button>
          <Button
            className={!actions.RESERVATION_CB_INVALIDE && classes.disabled}
            onClick={() => {
              if (
                window.confirm(
                  t('reservation.actions.reservation.signaler-carte.confirm'),
                )
              ) {
                feathers
                  .service('dossiers')
                  .patch(dossier.id, { action: 'RESERVATION_CB_INVALIDE' });
              }
            }}
          >
            {t('reservation.actions.reservation.signaler-carte.button')}
          </Button>
        </>
      ) : null}
      {dossier.statut !== 'RESERVATION_MODIF_DATES' &&
        store.user.type !== 'ADMIN' && (
          <ConsulterCarte
            className={!actions.CONSULTER_CARTE && classes.disabled}
            dossier={dossier}
          />
        )}
      {dossier.statut.indexOf('R_') === 0 &&
      penaliteApplicable.applicable &&
      store.user.type === 'ADMIN' &&
      !dossier.CB_non_debitee ? (
        <Button
          onClick={() => {
            if (
              window.confirm(
                t('reservation.actions.reservation.CB_non_debitee.confirm'),
              )
            ) {
              feathers.service('dossiers').patch(dossier.id, {
                action: 'CB_non_debitee',
              });
            }
          }}
        >
          {t('reservation.actions.reservation.CB_non_debitee.label')}
        </Button>
      ) : null}
    </div>
  );
};

export default compose(inject('store'), observer)(Reservation);
