import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import isEtablissementComplete from 'commons/utils/isEtablissementComplete';

import Popup1 from 'components/signup/popup1';
import Popup2 from 'components/signup/popup2';

import Presentation from './pages/presentation';
import Infos from './pages/informations';
import Finance from './pages/finance';
import Services from './pages/services';
import Chambres from './pages/chambres';
import Contrats from './pages/contrats';
import Tarifs from './pages/tarifs';
import Photos from './pages/photos';
import LinkIcon from './linkIcon';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  menu: {
    minWidth: '20rem',
    padding: theme.space.medium,
    paddingLeft: 0,
    '& li': {
      ...theme.font.size.medium,
      paddingBottom: theme.space.tiny,
    },
  },
  selected: {
    color: theme.color.primary,
  },
  page: {
    minWidth: 0,
    flex: 1,
    '& > *': {
      backgroundColor: theme.color.white,
      padding: theme.space.medium,
      marginBottom: theme.space.small,
    },
    '& > *:last-child': {
      marginBottom: '10rem',
    },
    '& h2': {
      marginBottom: theme.space.small,
      marginLeft: theme.space.small,
      ...theme.font.weight.normal,
    },
  },
  '@global': {
    body: {
      background: `${theme.color.blue.light} !important`,
    },
  },
}));

const Etablissement = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const prefix = '/compte/etablissement';
  const classes = useStyles();

  const missings = isEtablissementComplete({
    etablissement: store.etablissement,
  });

  const { complete, code_ok } = store.etablissement;

  return (
    <>
      {!complete && <Popup1 />}
      {complete && !code_ok && <Popup2 />}
      <div className={classes.wrapper}>
        <nav className={classes.menu}>
          <ul>
            <li>
              <NavLink
                to={`${prefix}/presentation`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon
                    missings={missings}
                    sections={['langues', 'gestion']}
                  />
                )}
                {t('menu.presentation')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/informations`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon missings={missings} sections={['infos']} />
                )}
                {t('menu.informations')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/finance`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon
                    missings={missings}
                    sections={['finance', 'moyens_paiement']}
                  />
                )}
                {t('menu.finance')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/services`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon missings={missings} sections={['services']} />
                )}
                {t('menu.services')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/chambres`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon
                    missings={missings}
                    sections={['chambres', 'chambres_services']}
                  />
                )}
                {t('menu.chambres')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/photos`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon
                    missings={missings}
                    sections={['photo', 'autres_photos']}
                  />
                )}
                {t('menu.photos')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/tarifs`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon missings={missings} sections={['tarifs']} />
                )}
                {t('menu.tarifs')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${prefix}/contrats`}
                activeClassName={classes.selected}
              >
                {!complete && (
                  <LinkIcon missings={missings} sections={['contrats']} />
                )}
                {t('menu.contrats')}
              </NavLink>
            </li>
          </ul>
        </nav>
        <div className={classes.page}>
          <Switch>
            <Route path={`${prefix}/presentation`} component={Presentation} />
            <Route path={`${prefix}/informations`} component={Infos} />
            <Route path={`${prefix}/finance`} component={Finance} />
            <Route path={`${prefix}/services`} component={Services} />
            <Route path={`${prefix}/chambres`} component={Chambres} />
            <Route path={`${prefix}/photos`} component={Photos} />
            <Route path={`${prefix}/contrats`} component={Contrats} />
            <Route path={`${prefix}/tarifs`} component={Tarifs} />
            <Redirect exact from={prefix} to={`${prefix}/presentation`} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default compose(inject('store'), observer)(Etablissement);
