import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useTranslation } from 'react-i18next';

import Modal from 'commons/components/modal';
import Button from 'commons/components/button';

import feathers from 'services/feathers';

const ConnecteModal = ({ store }) => {
  const { t } = useTranslation();

  const { etablissement } = store;

  if (!etablissement) return null;

  if (etablissement.connecte_statut) {
    return (
      <Modal
        closable={false}
        open={true}
        renderContent={close => (
          <div>
            <p>{t('connecte-modal.texte')}</p>
            <Button
              onClick={async () => {
                await feathers
                  .service('etablissements')
                  .patch(etablissement.id, { action: 'reconnecte' });
                close();
              }}
            >
              {t('connecte-modal.se-reconnecter')}
            </Button>
          </div>
        )}
      />
    );
  }
};

export default compose(inject('store'), observer)(ConnecteModal);
