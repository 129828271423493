import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReCaptcha from 'react-google-recaptcha';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import i18n from 'i18n';
import * as Yup from 'yup';

import Field from 'commons/components/field';
import Button from 'commons/components/button';
import waitFor from 'commons/utils/waitFor';

import feathers from 'services/feathers';

const useStyles = makeStyles(theme => ({
  form: {
    '& form': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& input': {
      width: 'initial',
      margin: theme.space.small,
    },
  },
}));

const EmailForm = ({ close }) => {
  const { t } = useTranslation('login');
  const reCaptchaRef = useRef(null);
  const classes = useStyles();

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object({
        email: Yup.string().email().required(),
      })}
      initialValues={{ email: '' }}
      initialStatus={{ complete: false }}
      onSubmit={async (values, { setStatus }) => {
        try {
          await reCaptchaRef.current.execute();
          const recaptcha = await waitFor(() =>
            reCaptchaRef.current.getValue(),
          );

          await feathers
            .service('users')
            .patch(null, { recaptcha, action: 'mdp-oublie', ...values });
          setStatus({ complete: true });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
          reCaptchaRef.current.reset();
        }
      }}
    >
      {({ isSubmitting, status }) => {
        if (status.complete) {
          return (
            <>
              <p>{t('mdp-oublie-confirmation')}</p>
              <a onClick={close}>{t('common:fermer')}</a>
            </>
          );
        }

        return (
          <div className={classes.form}>
            <Form>
              <label htmlFor="email">{t('email')}</label>
              <Field name="email" />
              <Button type="submit" loading={isSubmitting}>
                {t('reinitialiser-mdp')}
              </Button>
              <ReCaptcha
                ref={reCaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                hl={i18n.language}
              />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default EmailForm;
