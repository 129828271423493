import React from 'react';
import feathers from 'services/feathers';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import Popup3 from 'components/signup/popup3';

import ConfirmationCode from './confirmationCode';
import NouveauMdp from './nouveauMdp';
import Admin from './admin';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& > div': {
      border: `1px solid ${theme.color.primary}`,
      marginBottom: theme.space.small,
      padding: theme.space.tiny,
    },
    '& h2': {
      marginBottom: theme.space.tiny,
    },
  },
  green: {
    color: theme.color.green.dark,
  },
  red: {
    color: theme.color.red.dark,
  },
}));

const Compte = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation('compte');

  const { code_ok, active } = store.etablissement;

  return (
    <>
      {code_ok && !active && <Popup3 />}
      <div className={classes.wrapper}>
        <h2>{t('gestion')}</h2>
        <div>
          <button onClick={() => feathers.logout()}>
            {t('se-deconnecter')}
          </button>
        </div>
        <ConfirmationCode />
        <NouveauMdp />
        <div>
          <p
            className={clsx(
              store.etablissement.enabled ? classes.green : classes.red,
            )}
          >
            {t('etablissement')} :{' '}
            {store.etablissement.enabled ? t('activated') : t('deactivated')}
          </p>
        </div>
        <Admin />
      </div>
    </>
  );
};

export default compose(inject('store'), observer)(Compte);
