import React, { useEffect, useState } from 'react';
import feathers from 'services/feathers';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles';
import EVALUATION from 'commons/constants/evaluation';

const useStyles = makeStyles(theme => ({
  evaluations: {
    maxWidth: '30rem',
    margin: 'auto',

    '& > li': {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.space.small,
      padding: theme.space.small,
      border: `1px solid ${theme.color.primary}`,

      '& > div:first-child': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  nom: {
    ...theme.font.weight.bold,
    ...theme.font.size.medium,
    marginBottom: theme.space.tiny,
  },
  moyenne: {
    color: theme.color.primary,
  },
  commentaire: {
    marginTop: theme.space.tiny,
  },
  notes: {
    color: theme.color.primary,
    marginLeft: theme.space.small,
  },
  nonNote: {
    fontStyle: 'italic',
    color: theme.color.grey.medium,
  },
  date: {
    fontStyle: 'italic',
    color: theme.color.grey.medium,
    marginTop: theme.space.tiny,
    textAlign: 'right',
  },
  empty: {
    color: theme.color.grey.dark,
    margin: 'auto',
  },
}));

const Evaluations = () => {
  const { t } = useTranslation('evaluations');
  const classes = useStyles();
  const [evaluations, setEvaluations] = useState(null);
  const [total, setTotal] = useState(null);

  const loadMore = () =>
    feathers
      .service('evaluations')
      .find({
        query: {
          $skip: evaluations ? evaluations.length : 0,
          $sort: {
            created_at: -1,
          },
          $limit: 10,
        },
      })
      .then(({ total, data }) => {
        setTotal(total);
        setEvaluations([...(evaluations || []), ...data]);
      });

  useEffect(() => {
    loadMore();
  }, []);

  if (!evaluations || evaluations.length === 0)
    return <p className={classes.empty}>{t('empty')}</p>;

  return (
    <ul className={classes.evaluations}>
      {evaluations.map(
        (
          {
            created_at,
            dossier,
            moyenne,
            emplacement,
            proprete,
            confort,
            equipement,
            personnel,
            commentaire,
          },
          index,
        ) => (
          <li>
            <div>
              <div className={classes.nom}>
                {dossier.prenom} {dossier.nom}
              </div>
              <div className={classes.moyenne}>
                {moyenne &&
                  (moyenne === -1
                    ? t('non-note')
                    : `${moyenne.toFixed(2)} / 10`)}
              </div>
            </div>
            <div className={classes.notes}>
              <ul>
                <li>
                  {t('emplacement')} :{' '}
                  {emplacement !== -1 ? (
                    `${emplacement * 2} / 10`
                  ) : (
                    <span className={classes.nonNote}>{t('non-note')}</span>
                  )}
                </li>
                <li>
                  {t('proprete')} :{' '}
                  {proprete !== -1 ? (
                    `${proprete * 2} / 10`
                  ) : (
                    <span className={classes.nonNote}>{t('non-note')}</span>
                  )}
                </li>
                <li>
                  {t('confort')} :{' '}
                  {confort !== -1 ? (
                    `${confort * 2} / 10`
                  ) : (
                    <span className={classes.nonNote}>{t('non-note')}</span>
                  )}
                </li>
                <li>
                  {t('equipement')} :{' '}
                  {equipement !== -1 ? (
                    `${equipement * 2} / 10`
                  ) : (
                    <span className={classes.nonNote}>{t('non-note')}</span>
                  )}
                </li>
                <li>
                  {t('personnel')} :{' '}
                  {personnel !== -1 ? (
                    `${personnel * 2} / 10`
                  ) : (
                    <span className={classes.nonNote}>{t('non-note')}</span>
                  )}
                </li>
              </ul>
            </div>
            <div className={classes.commentaire}>{commentaire}</div>
            <div className={classes.date}>
              {dayjs(created_at).format('LLLL')}
            </div>
          </li>
        ),
      )}
      {evaluations.length < total && (
        <button onClick={() => loadMore()}>{t('common:load-more')}</button>
      )}
    </ul>
  );
};

export default Evaluations;
