import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';

import Form from 'commons/components/form';
import Field from 'commons/components/field';
import Button from 'commons/components/button';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& input': {
      width: 'auto',
      margin: `${theme.space.tiny} 0`,
      display: 'block',
    },
  },
}));

const ConfirmationCode = ({ store }) => {
  const { t } = useTranslation('compte');
  const classes = useStyles();

  if (store.etablissement.code_ok) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Form.Formik
        initialValues={{
          code: '',
        }}
        onSubmit={async values => {
          try {
            await feathers
              .service('etablissements')
              .patch(store.etablissement.id, {
                action: 'confirm_code',
                ...values,
              });
          } catch (e) {
            console.error(e);
            window.alert(t('error'));
          }
        }}
      >
        {({ values }) => (
          <Form>
            {t('code-activation')}
            <Field name="code" />
            <Button disabled={!values.code} type="submit">
              {t('common:valider')}
            </Button>
          </Form>
        )}
      </Form.Formik>
    </div>
  );
};

export default compose(inject('store'), observer)(ConfirmationCode);
