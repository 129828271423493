import React from 'react';
import { useFormikContext } from 'formik';
import ReactCountryFlag from 'react-country-flag';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  picker: {
    marginLeft: theme.space.medium,
    marginBottom: theme.space.small,

    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const LangPicker = ({ name }) => {
  const test = useFormikContext();
  const classes = useStyles();

  const { values, setFieldValue } = test;

  return (
    <ReactCountryFlag
      onClick={() => {
        setFieldValue(name, values[name] === 'fr' ? 'en' : 'fr');
      }}
      svg
      countryCode={values[name] === 'fr' ? 'FR' : 'GB'}
      className={classes.picker}
      style={{
        width: '2rem',
        height: '2rem',
      }}
    />
  );
};

export default LangPicker;
