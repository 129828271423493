import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Icon from 'commons/components/icon';

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'inline',
    height: '1rem',

    '& svg': {
      height: '1rem',
      position: 'relative',
      top: '0.25rem',
    },
  },
  green: {
    '& svg': {
      fill: theme.color.green.dark,
    },
  },
  red: {
    '& svg': {
      fill: theme.color.red.dark,
    },
  },
}));

const LinkIcon = ({ missings, sections }) => {
  const classes = useStyles();
  const checked = missings.every(s => !sections.includes(s.split('.')[0]));

  return checked ? (
    <Icon className={clsx(classes.icon, classes.green)} icon={<DoneIcon />} />
  ) : (
    <Icon className={clsx(classes.icon, classes.red)} icon={<CloseIcon />} />
  );
};

export default LinkIcon;
