import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import * as Yup from 'yup';

import feathers from 'services/feathers';

import Button from 'commons/components/button';
import Form from 'commons/components/form';
import Field from 'commons/components/field';
import { notify } from 'commons/components/notifier';

import etablissementSchema from 'commons/schemas/etablissement';

const Taxes = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'finance')}
      initialValues={etablissement.finance}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'finance', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form styled showDirty>
          <h2>{t('finance.parametres')}</h2>
          <Field.Percent name="tva" label={t('finance.tva')} />
          <Field.Currency
            name="taxe_sejour_adulte"
            label={t('finance.taxe_sejour_adulte')}
          />
          <Field.Currency
            name="taxe_sejour_enfant"
            label={t('finance.taxe_sejour_enfant')}
          />
          <Field.Currency
            name="prix_lit_bebe"
            label={t('finance.prix_lit_bebe')}
          />
          <Field.Currency
            name="prix_lit_appoint"
            label={t('finance.prix_lit_appoint')}
          />
          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(Taxes);
