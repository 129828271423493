import React from 'react';
import { useTranslation } from 'react-i18next';

import Viewer from 'components/viewer';

const Notifications = () => {
  const { t } = useTranslation('viewer');
  return (
    <Viewer
      handleUnseen={true}
      menu={[
        {
          id: 'nouvelles-reservations-acceptees',
          label: t('menu.nouvelles-reservations-acceptees'),
          options: {
            query: {
              statut: {
                $in: ['RESERVATION'],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
        {
          id: 'reservations-modifiees',
          label: t('menu.reservations-modifiees'),
          options: {
            query: {
              statut: {
                $in: [
                  'RESERVATION_INFOS_MODIFIEES',
                  'RESERVATION_CB_MODIFIEE',
                  'RESERVATION_CB_INVALIDE',
                  'RESERVATION_MODIF_DATES_OK',
                  'RESERVATION_MODIF_DATES_NOT_OK',
                  'RESERVATION_MODIF_DATES_EXPIREE',
                ],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
        {
          id: 'reservations-annulees',
          label: t('menu.reservations-annulees'),
          options: {
            query: {
              statut: {
                $in: [
                  'R_ANNULEE_CLIENT',
                  'R_ANNULEE_CB_INVALIDE',
                  'R_ANNULEE_NON_PRESENTATION',
                  'R_ANNULEE_MODIF_DATES',
                ],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
        {
          id: 'reservations-annulables',
          label: t('menu.reservations-annulables'),
          options: {
            query: {
              statut: {
                $in: ['RESERVATION_CB_INVALIDE_ANNULABLE'],
              },
              $sort: {
                updated_at: -1,
              },
            },
          },
        },
      ]}
    />
  );
};

export default Notifications;
