const waitFor = async condition => {
  let intervalId;
  const observe = new Promise(function (resolve, reject) {
    setInterval(() => {
      try {
        const value = condition();
        if (value) {
          resolve(value);
        }
      } catch (e) {
        reject(e);
      }
    }, 30);
  }).finally(() => clearInterval(intervalId));

  return Promise.race([
    observe,
    new Promise(function (resolve, reject) {
      setTimeout(() => reject('waitFor timeout reached'), 60 * 1000);
    }),
  ]);
};

export default waitFor;
