import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.space.small,
  },
}));

const Historique = ({ dossier }) => {
  const classes = useStyles();
  const { t } = useTranslation('dossier');

  return (
    <>
      <h2 className={classes.title}>Historique</h2>
      <ul>
        {dossier.parcours.map((step, index) => (
          <li>
            {dayjs(step.at).format('LLLL')} -{' '}
            {t(`common:statut.${step.statut}`)}{' '}
            {(index === 0 ||
              [
                'RESERVATION_MODIF_DATES',
                'RESERVATION_MODIF_DATES_OK',
              ].includes(step.statut)) &&
              ` - ${t('common:du')} ${dayjs(
                step.new_arrivee ||
                  step.arrivee ||
                  dossier.parcours_squashed.arrivee,
                'YYYY-MM-DD',
              ).format('L')} ${t('common:au')} ${dayjs(
                step.new_depart ||
                  step.depart ||
                  dossier.parcours_squashed.depart,
                'YYYY-MM-DD',
              ).format('L')}`}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Historique;
