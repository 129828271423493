import { decorate, observable, toJS } from 'mobx';
import feathers from 'services/feathers';

class Store {
  authentication = null;
  user = null;
  etablissement = null;
  dossiers = null;
  evaluations = null;

  constructor() {
    feathers.on('authenticated', async () => {
      try {
        this.authentication = await feathers.get('authentication');
        this.user = this.authentication.user;
        this.etablissement = await this.fetchEtablissement();
        this.dossiers = await this.fetchDossiers();
        this.evaluations = await this.fetchEvaluations();
      } catch (e) {
        feathers.logout();
      }
    });

    feathers.on('logout', () => {
      this.authentication = false;
      this.user = null;
      this.etablissement = null;
      this.dossiers = null;
      this.evaluations = null;
    });

    feathers.service('etablissements').on('patched', async () => {
      this.etablissement = await feathers.service('etablissements').get(null);
    });

    feathers.service('dossiers').on('patched', this.handleDossierEvent);

    feathers.service('dossiers').on('created', this.handleDossierEvent);

    feathers.service('evaluations').on('created', this.handleEvaluationsEvent);

    feathers.reAuthenticate().catch(e => {
      this.authentication = false;
    });
  }

  handleDossierEvent = async () => {
    this.dossiers = await this.fetchDossiers();
  };

  handleEvaluationsEvent = async () => {
    this.evaluations = await this.fetchEvaluations();
  };

  fetchDossiers = async () => {
    try {
      let skip = 0,
        total;
      const result = [];
      do {
        const r = await feathers.service('dossiers').find({
          query: {
            $select: [
              'connecte',
              'timer_at',
              'statut',
              'created_at',
              'unseen',
              'arrivee',
            ],
            $limit: 10,
            $skip: skip,
            $sort: {
              updated_at: -1, // Pas terrible mais il semble y avoir un problème si on l'omet
            },
          },
        });
        total = r.total;
        result.push(...r.data);
        skip += 10;
      } while (skip < total);

      return result;
    } catch (e) {}
  };

  fetchEvaluations = async () => {
    try {
      const { data } = await feathers.service('evaluations').find({
        query: {
          $limit: 5,
          $sort: {
            created_at: -1,
          },
        },
      });

      return data;
    } catch (e) {}
  };

  fetchEtablissement = async () => {
    try {
      const etablissement = this.user.etablissement_id
        ? await feathers.service('etablissements').get(null)
        : null;
      return etablissement;
    } catch (e) {}
  };
}

decorate(Store, {
  authentication: observable,
  user: observable,
  etablissement: observable,
  dossiers: observable,
  evaluations: observable,
});

const store = new Store();

export default store;
