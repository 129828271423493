import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';

import Imgix from 'services/imgix';
import Tarif from 'commons/components/tarif';
import Button from 'commons/components/button';
import Modal from 'commons/components/modal';
import feathers from 'services/feathers';

import Form from './form';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.space.medium,

    '& h2': {
      ...theme.font.weight.normal,
      textAlign: 'center',
      display: 'block',
      marginBottom: theme.space.small,
    },
  },
  nom: {},
  details: {
    display: 'flex',

    '& ul': {
      marginLeft: theme.space.small,
    },

    '& li': {
      marginBottom: theme.space.tiny,
    },
  },
  photos: {
    display: 'flex',
    margin: `${theme.space.tiny} 0`,

    '& img': {
      display: 'block',
      marginRight: theme.space.tiny,
    },
  },
  button: {
    padding: '0.5rem 1rem',
    marginLeft: 'auto',
  },
  actions: {
    '& > *': {
      marginRight: theme.space.tiny,
    },
  },
  red: {
    background: theme.color.red.dark,
  },
}));

const Liste = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;
  const classes = useStyles();

  return (
    <>
      {etablissement.tarifs.map((tarif, index) => (
        <div className={classes.item} key={index}>
          <h2>{tarif.nom}</h2>
          <Tarif tarif={tarif} />
          <div className={classes.actions}>
            <Modal
              renderContent={close => (
                <Form onSuccess={close} tarifIndex={index} />
              )}
              renderButton={open => (
                <Button onClick={open}>{t('common:editer')}</Button>
              )}
            />
            {etablissement.tarifs.length > 1 && (
              <Button
                className={classes.red}
                onClick={async () => {
                  if (window.confirm(t('common:etes-vous-sur'))) {
                    await feathers.service('etablissements').patch(null, {
                      action: 'tarifs',
                      array: {
                        action: 'remove',
                        index,
                      },
                    });
                  }
                }}
              >
                {t('common:supprimer')}
              </Button>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default compose(inject('store'), observer)(Liste);
