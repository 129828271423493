import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';

import getOffreTotal from 'commons/utils/getOffreTotal';
import getOffreTotalHT from 'commons/utils/getOffreTotalHT';
import getTva from 'commons/utils/getTva';
import literie_constants from 'commons/constants/literie';

import Tarif from 'commons/components/tarif';
import Number from 'commons/components/number';

const useStyles = makeStyles(theme => ({
  wrapper: {
    flex: 1,
    padding: theme.space.tiny,
    textAlign: 'center',
    background: theme.color.blue.light,
    border: `1px solid ${theme.color.dark}`,

    '& ul': {
      width: '100%',
    },

    '& h2': {
      textTransform: 'uppercase',
      ...theme.font.size.medium,
      textAlign: 'center',
      marginBottom: theme.space.small,
    },
  },
  chambres: {
    marginTop: theme.space.small,
    '& li': {
      marginBottom: theme.space.tiny,
    },
  },
  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  literie: {
    fontStyle: 'italic',
  },
  total: {
    marginTop: theme.space.tiny,

    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
    },

    '& li:not(:first-child)': {
      fontStyle: 'italic',
    },
  },
  date: {
    ...theme.font.weight.bold,
    marginBottom: theme.space.tiny,
  },
  prixParNuit: {
    fontStyle: 'italic',
    margin: 0,
    textAlign: 'right',
    marginBottom: theme.space.medium,
  },
}));

const Conditions = ({ className, dossier }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();

  const { nb_nuits } = dossier;

  const {
    etablissement,
    chambres_ids,
    selection,
    tarifs_ids,
    tarif,
    depart,
    arrivee,
    chambres,
    taxe_sejour,
    prix_lit_appoint,
    prix_lit_bebe,
  } = dossier.parcours_squashed;

  const getLiterieListe = ({ literie, literies }) => {
    const [a, b, c] = literie.split('-').map(e => parseInt(e));
    const literie_data = literies[a];
    const result = [];

    literie_constants
      .filter(e => literie_data[e] !== 0)
      .forEach(e => {
        result.push(
          `${literie_data[e]} ${t(`shared:literie.${e}`, {
            count: literie_data[e],
          })}`,
        );
      });

    if (b !== 0) {
      result.push(`${b} ${t('reservation.conditions.lit-appoint')}`);
    }
    if (c !== 0) {
      result.push(`${c} ${t('reservation.conditions.lit-bebe')}`);
    }

    return result;
  };

  const total = getOffreTotal({
    ...dossier.parcours_squashed,
    nb_nuits: dossier.nb_nuits,
  });
  const totalHT = getOffreTotalHT({
    ...dossier.parcours_squashed,
    nb_nuits: dossier.nb_nuits,
  });

  return (
    <div className={clsx(className, classes.wrapper)}>
      <h2>{t('reservation.conditions.titre')}</h2>

      <div className={classes.date}>
        {t('reservation.conditions.arrivee')}{' '}
        {dayjs(arrivee, 'YYYY-MM-DD').format('LLL')}
      </div>
      <div className={classes.date}>
        {t('reservation.conditions.depart')}{' '}
        {dayjs(depart, 'YYYY-MM-DD').format('LLL')}
      </div>
      <ul className={classes.chambres}>
        {selection.map(({ chambre, literie, quantite }) => {
          const chambre_data = etablissement.chambres[chambres_ids[chambre]];
          const [literieIndex, nbLitAppoint, nbLitBebe] = literie
            .split('-')
            .map(e => parseInt(e));

          return (
            <li>
              <div className={classes.label}>
                <div>
                  <strong>{quantite}</strong>{' '}
                  {t(`shared:chambres_labels.${chambre_data.label}`)} (
                  <strong>{chambre_data.nom}</strong>)
                </div>
                <div>
                  <Number
                    suffix={' €'}
                    fixedDecimalScale={2}
                    value={
                      (chambres[chambres_ids[chambre]].prix_par_nuit[
                        tarifs_ids[tarif]
                      ] +
                        nbLitAppoint * prix_lit_appoint +
                        nbLitBebe * prix_lit_bebe) *
                      quantite
                    }
                  />
                </div>
              </div>
              <div className={classes.literie}>
                {getLiterieListe({
                  literie,
                  literies: chambre_data.literies,
                }).join(' + ')}
              </div>
            </li>
          );
        })}
      </ul>
      <p className={classes.prixParNuit}>
        {t('reservation.conditions.prix-par-nuit')}
      </p>
      <Tarif tarif={etablissement.tarifs[tarifs_ids[tarif]]} />
      <ul className={classes.total}>
        <li>
          <div>{t('reservation.conditions.total-ttc')}</div>
          <div>
            <strong>
              <Number suffix={' €'} fixedDecimalScale={2} value={total} />
            </strong>
          </div>
        </li>
        <li>
          <div>
            {t('reservation.conditions.dont-tva')}{' '}
            <Number
              suffix={' %'}
              decimalScale={1}
              value={etablissement.finance.tva}
            />
          </div>

          <div>
            <Number
              suffix={' €'}
              fixedDecimalScale={2}
              value={getTva({
                totalHT,
                taxe_sejour,
                tva: etablissement.finance.tva,
              })}
            />
          </div>
        </li>
        <li>
          <div>{t('reservation.conditions.dont-taxe-sejour')}</div>
          <div>
            <Number
              suffix={' €'}
              fixedDecimalScale={2}
              value={taxe_sejour * nb_nuits}
            />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Conditions;
