import React, { useEffect } from 'react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { withFormik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import Button from 'commons/components/button';
import Field from 'commons/components/field';

import feathers from 'services/feathers';
import MotDePasseOublie from './motDePasseOublie';

const useStyles = makeStyles(theme => ({
  content: {
    backgroundColor: theme.color.white,
    margin: 'auto',
    maxWidth: '20rem',
    padding: theme.space.small,

    '& input': {
      marginBottom: theme.space.small,
    },

    '& h1': {
      color: theme.color.primary,
      fontStyle: 'italic',
      textAlign: 'center',
      marginBottom: theme.space.small,
      ...theme.font.weight.normal,
    },
    '& h1 strong': {
      fontFamily: 'Calibri, sans-serif',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& label': {
      marginBottom: theme.space.tiny,
    },
  },
  '@global': {
    body: {
      background: `${theme.color.blue.light} !important`,
    },
  },
}));

const Login = ({ store, isSubmitting }) => {
  const { t } = useTranslation('login');
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (store.authentication) {
      history.push('/compte/accueil');
    }
  }, [store.authentication]);

  useEffect(() => {
    const { activation_token, id } = qs.parse(location.search);
    if (activation_token && id) {
      feathers
        .service('users')
        .patch(id, { action: 'activate', activation_token })
        .then(history.push('/login'));
    }
  }, []);

  return (
    <div className={classes.content}>
      <h1>
        {t('bienvenue-sur')} <strong>HoReMe</strong>
      </h1>
      <Form className={classes.form}>
        <label htmlFor="email">{t('email')}</label>
        <Field name="email" />
        <label htmlFor="password">{t('mot-de-passe')}</label>
        <Field name="password" type="password" />
        <Button type="submit" loading={isSubmitting}>
          {t('se-connecter')}
        </Button>
      </Form>
      <MotDePasseOublie />
    </div>
  );
};

export default compose(
  inject('store'),
  withFormik({
    mapPropsToValues: () => ({
      email: '',
      password: '',
    }),

    handleSubmit: async (values, { setSubmitting, setStatus }) => {
      try {
        await feathers.authenticate({
          strategy: 'local',
          email: values.email,
          password: values.password,
        });
      } catch (e) {
        console.error(e);
        window.alert(
          'Identifiants incorrects, veuillez vérifier votre email et mot de passe.',
        );
      }
    },

    displayName: 'LoginForm',
  }),
  observer,
)(Login);
