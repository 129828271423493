import React from 'react';
import feathers from 'services/feathers';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { Trans, useTranslation } from 'react-i18next';

import Button from 'commons/components/button';
import Modal from 'commons/components/modal';

import useStyles from './useStyles';

const Popup4 = ({ store }) => {
  const classes = useStyles();
  const { t } = useTranslation('etablissement');

  if (store.user.type === 'ADMIN') {
    return null;
  }

  return (
    <Modal
      closable={false}
      initial={true}
      onClose={() => {
        feathers
          .service('etablissements')
          .patch(store.etablissement.id, { action: 'active_popup_seen' });
      }}
      renderContent={close => (
        <div className={classes.wrapper}>
          <Trans i18nKey="etablissement:popups.4">
            <p>Cher Partenaire !</p>
            <p>Nous sommes ravis de vous compter parmi nous!</p>
            <p>
              <strong>
                Votre Extranet vient de passer en mode réel, maintenant :
              </strong>
            </p>
            <ul>
              <li>
                vous pouvez gagner du temps et augmenter les chances de
                réservations en envoyant en quelques secondes seulement des
                offres claires à n’importe quel client qui vous demande un prix
                par email ou téléphone : pour rappel, aucune commission ne sera
                prélevée sur toutes ces réservations !{' '}
                <strong>C'est totalement gratuit !</strong>
              </li>
              <li>
                votre établissement est visible sur{' '}
                <a href="www.horeme.com">www.horeme.com</a> (
                <strong>tout en 1 seule page!</strong>) et vous pouvez recevoir
                des demandes de nouveaux clients : sur ces réservations nous
                facturerons{' '}
                <strong>
                  1 EUR HT par personne et par nuit pour les montants inférieurs
                  à 100 EUR/nuit et 2 EUR HT par personne et par nuit pour tous
                  les montants à partir de 100EUR/nuit et c’est tout!
                </strong>{' '}
                Si le client ne réserve pas suite à une offre envoyée ou s’il
                annule gratuitement, cela ne vous coutera évidemment strictement
                rien.
              </li>
              <li>
                vous pouvez continuer de modifier librement votre établissement
                (descriptif, conditions tarifaires, photos, etc.)
              </li>
              <li>
                vos dossiers tests (demandes et réservations faites via des
                offres gratuites ont été supprimés), vous pouvez cependant
                continuer à en créer de nouveaux pour vous entrainer!
              </li>
            </ul>
          </Trans>
          <Button
            onClick={() => {
              feathers
                .service('etablissements')
                .patch(store.etablissement.id, { action: 'active_popup_seen' });
              close();
            }}
            className={classes.button}
          >
            {t('popups.compris')}
          </Button>
        </div>
      )}
    />
  );
};

export default compose(inject('store'), observer)(Popup4);
