import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'commons/components/button';
import Modal from 'commons/components/modal';

import Form from './form';
import Liste from './liste';

const Tarifs = () => {
  const { t } = useTranslation('etablissement');
  // const { etablissement } = store;

  return (
    <>
      <div>
        <h2>{t('tarifs.form.ajouter_un_tarif')}</h2>
        <Modal
          renderContent={close => <Form onSuccess={close} />}
          renderButton={open => (
            <Button onClick={open}>{t('common:ajouter')}</Button>
          )}
        />
      </div>
      <Liste />
    </>
  );
};

export default Tarifs;
