import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import feathers from 'services/feathers';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  wrapper: {
    '& h2': {
      marginBottom: theme.space.small,
    },
    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      background: theme.color.dark,
      color: theme.color.white,
      marginBottom: theme.space.tiny,
      cursor: 'pointer',

      '& *': {
        margin: theme.space.tiny,
      },
    },
  },
}));

const getParisDatetime = () =>
  dayjs(new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

const Planning = () => {
  const classes = useStyles();
  const { t } = useTranslation('accueil');
  const [dossiers, setDossiers] = useState(null);
  const history = useHistory();

  const fetchDossiers = useCallback(() =>
    feathers
      .service('dossiers')
      .find({
        query: {
          arrivee: getParisDatetime().format('YYYY-MM-DD'),
          $limit: 50, // Limit max
          statut: {
            $in: [
              'RESERVATION',
              'RESERVATION_MODIF_DATES',
              'RESERVATION_CB_INVALIDE',
              'RESERVATION_CB_INVALIDE_ANNULABLE',
              'RESERVATION_MODIF_DATES',
              'RESERVATION_MODIF_DATES_OK',
              'RESERVATION_MODIF_DATES_NOT_OK',
              'RESERVATION_MODIF_DATES_EXPIREE',
              'RESERVATION_CB_MODIFIEE',
              'RESERVATION_INFOS_MODIFIEES',
            ],
          },
        },
      })
      .then(({ data }) => {
        setDossiers(data);
      }),
  );

  useEffect(() => {
    fetchDossiers();
    const id = setInterval(fetchDossiers, 6 * 3600 * 1000); // Run every 6 hours
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      <h2>
        {t('planning.arrivees')} {dossiers && `(${dossiers.length})`}
      </h2>
      {dossiers ? (
        dossiers.length ? (
          <ul>
            {dossiers.map(({ statut, parcours_squashed }) => (
              <li onClick={() => history.push('/compte/reservations')}>
                <div style={{ width: '12rem' }}>
                  {t(`common:statut_short.${statut}`)}
                </div>
                <div style={{ width: '12rem' }}>
                  {parcours_squashed.coordonnees.nom}{' '}
                  {parcours_squashed.coordonnees.prenom}
                </div>
                <div>
                  {dayjs(parcours_squashed.arrivee).format('L')} -{' '}
                  {dayjs(parcours_squashed.depart).format('L')}
                </div>
                <div>
                  {t('planning.adultes', { count: parcours_squashed.adultes })},{' '}
                  {t('planning.enfants', { count: parcours_squashed.enfants })}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>{t('planning.aucun')}</p>
        )
      ) : null}
    </div>
  );
};

export default Planning;
