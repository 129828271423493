import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, useFormikContext } from 'formik';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import types from 'commons/constants/literie';
import Field from 'commons/components/field';

const useStyles = makeStyles(theme => ({
  fieldWrapper: {
    '& label': {
      display: 'inline-block',
      marginLeft: theme.space.small,
    },
  },
  field: {
    width: theme.space.medium,
  },
  literie: {
    margin: theme.space.small,
    padding: theme.space.small,
    border: `1px solid ${theme.color.primary}`,
  },
  error: {
    border: `1px solid ${theme.color.red.dark}`,
    '& input': {
      color: theme.color.red.dark,
      border: `1px solid ${theme.color.red.dark}`,
    },
  },
  grise: {
    color: theme.color.grey.medium,
  },
  notice: {
    color: theme.color.grey.dark,
  },
}));

const LiteriesField = ({ name }) => {
  const { values, errors } = useFormikContext();
  const { t } = useTranslation('etablissement');
  const classes = useStyles();

  return (
    <>
      <label>{t('chambres.form.literie.literie')}</label>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <>
            {values[name].map((configuration, index) => (
              <div
                key={index}
                className={clsx(
                  classes.literie,
                  get(errors, [name, index]) && classes.error,
                )}
              >
                {types.map(type => (
                  <div className={classes.fieldWrapper}>
                    <Field.Number
                      key={index + type}
                      className={clsx(
                        classes.field,
                        !values[name][index][type] && classes.grise,
                      )}
                      decimalScale={0}
                      name={`${name}.${index}.${type}`}
                    />
                    <label htmlFor={`${name}.${index}.${type}`}>
                      {t(`shared:literie.${type}`)}
                    </label>
                  </div>
                ))}

                {values[name].length > 1 && (
                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    {t('chambres.form.literie.retirer')}
                  </button>
                )}
              </div>
            ))}
            <button
              type="button"
              onClick={() => arrayHelpers.push(newLiterie())}
            >
              {t('chambres.form.literie.ajouter')}
            </button>
            <div className={classes.notice}>
              {t('chambres.form.literie.notice')}
            </div>
          </>
        )}
      />
    </>
  );
};

export const newLiterie = () =>
  types.reduce((acc, e) => {
    acc[e] = 0;
    return acc;
  }, {});

export default LiteriesField;
