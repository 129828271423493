import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';

import Form from 'commons/components/form';
import { notify } from 'commons/components/notifier';

import photoSchema from 'commons/schemas/photo';

import feathers from 'services/feathers';

import Photo from 'components/photo';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
  },
  notice: {
    color: theme.color.grey.dark,
  },
}));

const Photos = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();
  const { etablissement } = store;

  return (
    <>
      <div>
        <h2>{t('photos.photo')}</h2>
        <p className={classes.notice}>{t('photos.photo_notice')}</p>
        <Form.Formik
          validationSchema={photoSchema}
          initialValues={{ photo: etablissement.photo }}
          onSubmit={async values => {
            await feathers
              .service('etablissements')
              .patch(null, { action: 'photo', data: values.photo });
            notify({
              message: t('common:infos-succes'),
              variant: 'success',
            });
          }}
        >
          {({ submitForm }) => (
            <Form>
              <Photo.Field name="photo" onSuccess={id => submitForm()} />
            </Form>
          )}
        </Form.Formik>
      </div>
      <div>
        <h2>{t('photos.autres_photos')}</h2>
        <p className={classes.notice}>{t('photos.autres_photos_min_max')}</p>
        <div className={classes.row}>
          <Form.Formik
            validationSchema={photoSchema}
            initialValues={{ photo: null }}
            onSubmit={async (values, { resetForm }) => {
              try {
                await feathers.service('etablissements').patch(null, {
                  action: 'autres_photos',
                  array: {
                    action: 'push',
                    data: values.photo,
                  },
                });
              } finally {
                resetForm();
              }
            }}
          >
            {({ submitForm }) => (
              <Photo.Field name="photo" onSuccess={id => submitForm()} />
            )}
          </Form.Formik>
          <Photo.Gallery
            photos={etablissement.autres_photos}
            allowDelete
            onDelete={async index => {
              if (!window.confirm('Etes vous sur ?')) return;

              await feathers.service('etablissements').patch(null, {
                action: 'autres_photos',
                array: {
                  action: 'remove',
                  index,
                },
              });
              notify({
                message: t('common:infos-succes'),
                variant: 'success',
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default compose(inject('store'), observer)(Photos);
