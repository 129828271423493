import React, { useState } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.space.medium,
    ...theme.break.md({
      padding: theme.space.tiny,
    }),
  },
}));

const defaultTheme = createMuiTheme();

const Modal = ({
  renderButton,
  renderContent,
  initial = false,
  closable = true,
  open,
  onClose = () => null,
  ...props
}) => {
  const classes = useStyles();
  const [o, setOpen] = useState(initial);

  return (
    <>
      {renderButton && renderButton(() => setOpen(true))}
      <ThemeProvider theme={defaultTheme}>
        <MuiDialog
          open={open || o}
          onClose={() => {
            if (closable) {
              setOpen(false);
              onClose();
            }
          }}
          {...props}
        >
          <MuiDialogContent className={classes.root}>
            {renderContent(() => setOpen(false))}
          </MuiDialogContent>
        </MuiDialog>
      </ThemeProvider>
    </>
  );
};

export default Modal;
