const Yup = require('yup');
const etablissementSchema = require('./index');

const schema = Yup.object({
  langues: Yup.array().of(Yup.string()).min(1).required(),
  gestion: Yup.reach(etablissementSchema, 'gestion').required(),
  infos: Yup.reach(etablissementSchema, 'infos').required(),
  finance: Yup.reach(etablissementSchema, 'finance').required(),
  chambres: Yup.reach(etablissementSchema, 'chambres').required(),
  tarifs: Yup.reach(etablissementSchema, 'tarifs').required(),
  photo: Yup.reach(etablissementSchema, 'photo').required(),
  autres_photos: Yup.reach(etablissementSchema, 'autres_photos').required(),
  infos: Yup.reach(etablissementSchema, 'infos').required(),
  moyens_paiement: Yup.reach(etablissementSchema, 'moyens_paiement')
    .required()
    .test('is-complete', '${path} is complete', value => {
      return Object.values(value).some(e => !!e);
    }),
  services: Yup.reach(etablissementSchema, 'services')
    .required()
    .test('is-complete', '${path} is complete', value => {
      return Object.values(value).some(e => !!e);
    }),
  chambres_services: Yup.reach(etablissementSchema, 'chambres_services')
    .required()
    .test('is-complete', '${path} is complete', value => {
      return Object.values(value).some(e => e !== 'none');
    }),
  contrats: Yup.boolean()
    .required()
    .test('is-complete', '${path} is complete', value => value === true),
});

module.exports = schema;
