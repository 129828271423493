import React from 'react';
import { useFormikContext } from 'formik';
import { withTheme } from '@material-ui/core/styles';
import { get } from 'lodash';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneField = ({ name, disabled, theme, label }) => {
  const { values, setFieldValue, errors, status } = useFormikContext();

  const errorStyles =
    get(errors, name) != null
      ? {
          ...theme.error,
          border: '2px solid red',
        }
      : {};

  const disabledStyles =
    status && status.disabled
      ? {
          background: theme.card ? theme.card.color.light : theme.color.white,
        }
      : {};

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <PhoneInput
        containerStyle={{ height: '1.5rem' }}
        inputStyle={{
          height: '1.5rem',
          width: '100%',
          fontSize: '0.8rem',
          ...errorStyles,
          ...disabledStyles,
        }}
        buttonStyle={{ ...errorStyles }}
        placeholder=""
        country={'fr'}
        value={get(values, name)}
        inputProps={{ id: name }}
        onChange={value => {
          setFieldValue(name, value);
        }}
        disabled={disabled || (status && status.disabled)}
      />
    </>
  );
};

export default withTheme(PhoneField);
