import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { useFormikContext } from 'formik';

import Imgix from 'services/imgix';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';

import feathers from 'services/feathers';

import Box from './box';

const defaultTheme = createMuiTheme();
const useStyles = makeStyles(theme => ({
  boxContainer: {
    position: 'relative',

    '&:hover $delete': {
      display: 'flex',
    },
  },
  delete: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    background: theme.color.white,
    borderRadius: '100%',
    ...theme.shadow[1],
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.4rem',
    cursor: 'pointer',

    '& svg': {
      ...theme.font.size.medium,
      fill: theme.color.red.dark,
    },
  },
  wrapper: {
    display: 'inline-flex',
    flexWrap: 'wrap',
  },
}));

const Gallery = ({ photos, allowDelete, onDelete }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className={classes.wrapper}>
        {photos.map((photo, index) => (
          <div className={classes.boxContainer} key={photo}>
            <Box>
              <Imgix
                photo={photo}
                width={100}
                imgixParams={{ ar: '1:1', fit: 'crop' }}
              />
            </Box>
            {allowDelete && (
              <div className={classes.delete} onClick={() => onDelete(index)}>
                <DeleteIcon />
              </div>
            )}
          </div>
        ))}
      </div>
    </ThemeProvider>
  );
};

export default Gallery;
