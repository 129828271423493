import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import Table from 'components/table';

const getParisDatetime = () =>
  dayjs(new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

const useStyles = makeStyles(theme => ({
  placeholder: {
    padding: theme.space.small,
    textAlign: 'center',
    background: theme.color.white,
    border: `1px solid ${theme.color.dark}`,
  },
  wrapper: {
    '& table': {
      width: '100%',
    },

    '& td': {
      padding: '0.2rem',
    },

    '& tr': {
      '& td:not(:first-child)': {
        background: theme.color.white,
        width: '50%',
        textAlign: 'center',
      },
    },

    '& table:first-child': {
      '& thead': {
        textTransform: 'uppercase',
      },
    },

    '& table:not(:first-child)': {
      '& thead': {
        fontStyle: 'italic',
      },
    },

    '& thead': {
      textAlign: 'center',
    },
  },
}));

const Coordonnees = ({ className, dossier }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();

  if (dossier.statut === 'CONFIRMATION') {
    return (
      <div className={clsx(className, classes.placeholder)}>
        {t('reservation.recapitulatif.placeholder')}
      </div>
    );
  }

  if (
    getParisDatetime().isAfter(
      dayjs(dossier.parcours_squashed.depart_date).add(7, 'days'),
    )
  ) {
    return null;
  }

  const {
    principal,
    coordonnees,
    facturation,
    creneau,
  } = dossier.parcours_squashed;

  console.log(principal);

  // Cas où les coordonnées sont obfusquées
  if (!coordonnees) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <Table>
        <thead>
          <tr>
            <td colSpan={2}>{t('reservation.recapitulatif.client.title')}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('reservation.recapitulatif.client.pays')}</td>
            <td>{coordonnees.pays}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.client.nom')}</td>
            <td>{coordonnees.nom}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.client.prenom')}</td>
            <td>{coordonnees.prenom}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.client.email')}</td>
            <td>{dossier.email}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.client.tel_mobile')}</td>
            <td>{formatPhoneNumberIntl('+' + coordonnees.tel_mobile)}</td>
          </tr>
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <td colSpan={2}>
              {t('reservation.recapitulatif.client-principal.title')}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('reservation.recapitulatif.client-principal.nom')}</td>
            <td>{principal.nom}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.client-principal.prenom')}</td>
            <td>{principal.prenom}</td>
          </tr>
          <tr>
            <td>
              {t('reservation.recapitulatif.client-principal.tel_mobile')}
            </td>
            <td>{formatPhoneNumberIntl('+' + principal.tel_mobile)}</td>
          </tr>
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <td colSpan={2}>
              {t('reservation.recapitulatif.facturation.title')}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('reservation.recapitulatif.facturation.nom')}</td>
            <td>{facturation.nom}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.facturation.adresse')}</td>
            <td>{facturation.adresse}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.facturation.code_postal')}</td>
            <td>{facturation.code_postal}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.facturation.ville')}</td>
            <td>{facturation.ville}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.facturation.pays')}</td>
            <td>{facturation.pays}</td>
          </tr>
          <tr>
            <td>{t('reservation.recapitulatif.facturation.numero_tva')}</td>
            <td>{facturation.numero_tva}</td>
          </tr>
        </tbody>
      </Table>
      <Table>
        <thead>
          <tr>
            <td colSpan={2}>{t('reservation.recapitulatif.creneau.title')}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('reservation.recapitulatif.creneau.creneau')}</td>
            <td>{creneau ? dayjs(creneau).format('HH:mm') : null}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default Coordonnees;
