const Yup = require('yup');
const types = require('../constants/literie');

const literie = Yup.object(
  types.reduce((acc, e) => {
    acc[e] = Yup.number().max(10).required();
    return acc;
  }, {}),
).test('is-not-empty', '${path} is empty', value =>
  Object.values(value).some(e => !!e),
);

module.exports = literie;
