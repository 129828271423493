import React, { useEffect } from 'react';
import theme from 'commons/theme';
import { ThemeProvider } from '@material-ui/styles';
import store from 'store';
import { compose } from 'recompose';
import { observer, inject, Provider } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import i18n from 'i18n';
import dayjs from 'dayjs';

import Globals from 'commons/components/globals';
import Notifier from 'commons/components/notifier';

import ConnecteModal from 'components/connecteModal';
import Header from 'components/header';
import AdminBar from 'components/adminBar';
import Popup4 from 'components/signup/popup4';

import Login from 'pages/login';
import NouveauMdp from 'pages/nouveauMdp';
import Accueil from 'pages/accueil';
import Demandes from 'pages/demandes';
import Reservations from 'pages/reservations';
import Notifications from 'pages/notifications';
import Etablissement from 'pages/etablissement';
import OffreGratuite from 'pages/offreGratuite';
import Evaluations from 'pages/evaluations';
import Compte from 'pages/compte';
import Horeme from 'pages/horeme';

import './fonts.css';

const useStyles = makeStyles(theme => ({
  layout: {
    padding: `0 ${theme.space.medium}`,

    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.space.medium,
  },
  '@global': {
    body: {
      background: theme.color.white,
    },
  },
}));

const App = ({ store }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      store.authentication === false &&
      location.pathname !== '/nouveau-mot-de-passe' &&
      location.pathname !== '/login'
    ) {
      history.push('/login');
    }
  }, [store.authentication, location.pathname]);

  useEffect(() => {
    // Utile pour l'initialisation, ensuite LocaleSwitch prend le relai
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    if (
      store.etablissement &&
      !store.etablissement.active &&
      ['/compte/etablissement', '/compte/parametres'].every(
        p => location.pathname.indexOf(p) !== 0,
      )
    ) {
      history.push('/compte/etablissement');
    }
  }, [store.etablissement]);

  if (store.authentication == null) {
    return null;
  }

  return (
    <>
      {store.user && store.user.type === 'ADMIN' && <AdminBar />}
      <Header />
      <div className={classes.layout}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/nouveau-mot-de-passe" component={NouveauMdp} />
          {store.etablissement && store.dossiers && (
            <Switch>
              <Route path="/compte/accueil" component={Accueil} />
              <Route path="/compte/demandes" component={Demandes} />
              <Route path="/compte/reservations" component={Reservations} />
              <Route path="/compte/notifications" component={Notifications} />
              <Route path="/compte/etablissement" component={Etablissement} />
              <Route path="/compte/offre-gratuite" component={OffreGratuite} />
              <Route path="/compte/evaluations" component={Evaluations} />
              <Route path="/compte/horeme" component={Horeme} />
              <Route path="/compte/parametres" component={Compte} />
              <Redirect
                exact
                from="/"
                to={
                  store.etablissement.active
                    ? '/compte/accueil'
                    : '/compte/etablissement'
                }
              />
            </Switch>
          )}
        </Switch>
      </div>
      {store.etablissement &&
        store.etablissement.active &&
        !store.etablissement.active_popup_seen && <Popup4 />}
    </>
  );
};

const DecoratedApp = compose(inject('store'), observer)(App);

const AppWithProviders = () => (
  <ThemeProvider theme={theme('extranet')}>
    <Globals />
    <Notifier />
    <Provider store={store}>
      <ConnecteModal />
      <Router>
        <DecoratedApp />
      </Router>
    </Provider>
  </ThemeProvider>
);

export default AppWithProviders;
