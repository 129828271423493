import React from 'react';
import { useTranslation } from 'react-i18next';

import Viewer from 'components/viewer';

const Reservations = () => {
  const { t } = useTranslation('viewer');
  return (
    <Viewer
      menu={[
        {
          id: 'reservations-actives',
          label: t('menu.reservations-actives'),
          options: {
            query: {
              statut: {
                $in: [
                  'RESERVATION',
                  'RESERVATION_INFOS_MODIFIEES',
                  'RESERVATION_CB_MODIFIEE',
                  'RESERVATION_CB_INVALIDE',
                  'RESERVATION_CB_INVALIDE_ANNULABLE',
                  'RESERVATION_MODIF_DATES_OK',
                  'RESERVATION_MODIF_DATES_NOT_OK',
                  'RESERVATION_MODIF_DATES_EXPIREE',
                ],
              },
              $sort: {
                arrivee: 1,
              },
            },
          },
        },
        {
          id: 'reservations-terminees',
          label: t('menu.reservations-terminees'),
          options: {
            query: {
              statut: {
                $in: ['R_TERMINEE'],
              },
              $sort: {
                arrivee: -1,
              },
            },
          },
        },
      ]}
    />
  );
};

export default Reservations;
