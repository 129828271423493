import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import LocaleSwitch from './localeSwitch';

const useStyles = makeStyles(theme => ({
  header: {
    background: theme.color.dark,
    color: theme.color.white,
  },
  content: {
    ...theme.content,
    position: 'relative',
    '& nav': {
      background: theme.color.dark,
      padding: '0.5rem 0',
    },
    '& ul': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& li': {
      display: 'flex',
      margin: '0 1rem',
      ...theme.font.weight.bold,
      ...theme.font.size.small,
    },
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '4rem',
    textAlign: 'center',
    paddingTop: '1rem',
    cursor: 'pointer',
    fontFamily: 'Calibri, sans-serif',

    '& img': {
      marginRight: 'auto',
      marginLeft: '1rem',

      height: '100%',
    },
  },
  localeSwitch: {
    position: 'absolute',
    top: '1rem',
    right: '3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
  subtitle: {
    color: theme.color.grey.medium,
    ...theme.font.size.small,
  },
}));

const Header = ({ className, items, i18n, onLogoClick, notice }) => {
  const classes = useStyles();
  // console.log('i18n.language', i18n.language);
  const logoSrc = i18n.language === 'fr' ? '/logo_fr.png' : '/logo_en.png';
  // console.log('???', logoSrc);
  return (
    <>
      <header className={clsx(className, classes.header)}>
        <div className={classes.content}>
          <div className={classes.logo} onClick={onLogoClick}>
            {process.browser && <img src={logoSrc} />}
          </div>

          <div className={classes.localeSwitch}>
            {notice && notice}
            <LocaleSwitch i18n={i18n} />
          </div>
          <nav>
            <ul>
              {items.map((i, index) => (
                <li key={index}>{i}</li>
              ))}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
