const Yup = require('yup');

const photoSchema = require('../photo');
const chambreSchema = require('../chambre');
const tarifSchema = require('../tarif');

const SERVICES = require('../../constants/services');
const CHAMBRES_SERVICES = require('../../constants/chambres_services');
const MOYENS_PAIEMENT = require('../../constants/moyens_paiement');

const schema = Yup.object({
  photo: photoSchema,
  langues: Yup.array().of(Yup.string()).max(10),
  description: Yup.object({
    fr: Yup.string().max(300).required(),
    en: Yup.string().max(300).required(),
  }).required(),
  finance: Yup.object({
    tva: Yup.number().max(100).required(),
    taxe_sejour_adulte: Yup.number().required(),
    taxe_sejour_enfant: Yup.number().required(),
    prix_lit_bebe: Yup.number().nullable(),
    prix_lit_appoint: Yup.number().nullable(),
  }).required(),
  contact_horeme: Yup.object({
    nom: Yup.string().required(),
    prenom: Yup.string().required(),
    fonction: Yup.string().required(),
    email: Yup.string().required(),
    email_enabled: Yup.boolean(),
    tel_mobile: Yup.string().required(),
    tel_fixe: Yup.string().required(),
  }).required(),
  contact_client: Yup.object({
    email: Yup.string().required(),
    tel: Yup.string().required(),
  }).required(),
  nbr_envois_SMS_gratuits: Yup.number().integer().min(0).max(10).required(),
  gestion: Yup.object({
    no_pms: Yup.bool(),
    pms_details: Yup.string().when('no_pms', {
      is: false,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    no_cm: Yup.bool(),
    cm_details: Yup.string().when('no_cm', {
      is: false,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  }).required(),
  infos: Yup.object({
    check_in: Yup.string().required(),
    check_out: Yup.string().required(),
    enfants: Yup.string().required(),
    animaux: Yup.string().required(),
    stationnement: Yup.string().required(),
    heure_arrivee_necessaire: Yup.string().required(),
    fetes: Yup.string().required(),
    ouverture: Yup.string().required(),
    depot_garantie: Yup.string().required(),
  }),
  autres_photos: Yup.array().of(photoSchema).min(3).max(30),
  services: Yup.object(
    SERVICES.reduce((acc, e) => {
      acc[e] = Yup.bool().required();
      return acc;
    }, {}),
  ).required(),
  moyens_paiement: Yup.object(
    MOYENS_PAIEMENT.reduce((acc, e) => {
      acc[e] = Yup.bool().required();
      return acc;
    }, {}),
  ).required(),
  chambres_services: Yup.object(
    CHAMBRES_SERVICES.reduce((acc, e) => {
      acc[e] = Yup.lazy(value =>
        typeof value === 'string'
          ? Yup.string().required()
          : Yup.array().of(Yup.number()),
      );
      return acc;
    }, {}),
  ).required(),
  chambres: Yup.array(chambreSchema).min(1).required(),
  tarifs: Yup.array(tarifSchema).min(1).required(),
});

module.exports = schema;
