import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'collapse',
    '& input': {
      textAlign: 'center',
    },

    '& thead': {
      backgroundColor: theme.color.blue.dark,
    },
    '& th': {
      padding: theme.space.tiny,
      backgroundColor: theme.color.blue.dark,
      ...theme.font.weight.normal,
    },
    '& tbody': {
      backgroundColor: theme.color.blue.medium,
    },

    '& tr': {
      border: `1px solid ${theme.color.blue.dark}`,
    },

    '& td': {
      padding: theme.space.tiny,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '& li': {
      minHeight: '2rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const Table = ({ children, className }) => {
  const classes = useStyles();

  return <table className={clsx(className, classes.table)}>{children}</table>;
};

const List = ({ children, className }) => {
  const classes = useStyles();

  return <ul className={clsx(className, classes.list)}>{children}</ul>;
};

Table.List = List;

export default Table;
