import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';
import getOffreTotal from 'commons/utils/getOffreTotal';
import getOffreTotalHT from 'commons/utils/getOffreTotalHT';
import getPenaliteApplicable from 'commons/utils/getPenaliteApplicable';
import getPenalite from 'commons/utils/getPenalite';
import Number from 'commons/components/number';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
  green: {
    color: theme.color.green.dark,
  },
  red: {
    color: theme.color.red.dark,
  },
  statut: {
    color: theme.color.red.dark,
    textTransform: 'uppercase',
    ...theme.font.size.medium,
    paddingTop: theme.space.small,
  },
  raye: {
    textDecoration: 'line-through',
    '& *': {
      textDecoration: 'line-through',
    },
  },
  enfants: {
    fontStyle: 'italic',
    color: theme.color.grey.dark,
  },
}));

const ERROR_STATUTS = [
  'D_EXPIREE',
  'D_COMPLET',
  'O_EXPIREE',
  'C_EXPIREE',
  'C_COMPLET',
  'R_ANNULEE_CLIENT',
  'R_ANNULEE_CB_INVALIDE',
  'R_ANNULEE_NON_PRESENTATION',
  'R_ANNULEE_MODIF_DATES',
];

const Header = ({ dossier }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();

  const [current] = dossier.parcours.slice(-1);

  let total, remboursable, penalite, penaliteApplicable;

  const {
    tarif,
    tarifs_ids,
    etablissement,
    new_arrivee,
    nb_nuits,
    new_depart,
    new_nb_nuits,
    adultes,
    enfants,
    nb_chambres,
    enfants_details,
  } = dossier.parcours_squashed;

  const { depart, arrivee } = dossier;

  if (dossier.statut.indexOf('C') === 0 || dossier.statut.indexOf('R') === 0) {
    total = getOffreTotal({
      ...dossier.parcours_squashed,
    });

    remboursable = etablissement.tarifs[tarifs_ids[tarif]].remboursable;
  }

  if (dossier.statut.indexOf('R') === 0) {
    const totalHT = getOffreTotalHT({
      ...dossier.parcours_squashed,
    });

    penalite = getPenalite({
      tarif: etablissement.tarifs[tarifs_ids[tarif]],
      totalHT,
      nb_nuits: dossier.nb_nuits,
    });

    penaliteApplicable = getPenaliteApplicable({
      dossier,
      tarif: etablissement.tarifs[tarifs_ids[tarif]],
    });
  }

  const isModifDates = dossier.statut === 'RESERVATION_MODIF_DATES';

  return (
    <>
      <table
        className={clsx(
          classes.table,
          ERROR_STATUTS.includes(current.statut) && classes.raye,
        )}
      >
        <tbody>
          <tr>
            <td colSpan={2}>{t('header.label.personnes')}</td>
            <td>{t('header.label.arrivee')}</td>
            <td>{t('header.label.depart')}</td>
            <td>{t('header.label.nuits')}</td>
            <td>{t('header.label.chambres')}</td>
            <td>{t('header.label.tarif')}</td>
            <td>{t('header.label.montant-total')}</td>
            <td>{t('header.label.reception')}</td>
          </tr>
          <tr>
            <td>{t('header.adultes', { count: adultes })}</td>
            <td>{t('header.enfants', { count: enfants })}</td>
            <td>
              <span className={clsx(isModifDates && classes.raye)}>
                {dayjs(arrivee, 'YYYY-MM-DD').format('LLL')}
              </span>
              {isModifDates && (
                <>
                  <br />
                  <span className={classes.red}>
                    {dayjs(new_arrivee, 'YYYY-MM-DD').format('LLL')}
                  </span>
                </>
              )}
            </td>
            <td>
              <span className={clsx(isModifDates && classes.raye)}>
                {dayjs(depart, 'YYYY-MM-DD').format('LLL')}
              </span>
              {isModifDates && (
                <>
                  <br />
                  <span className={classes.red}>
                    {dayjs(new_depart, 'YYYY-MM-DD').format('LLL')}
                  </span>
                </>
              )}
            </td>
            <td>
              <span className={clsx(isModifDates && classes.raye)}>
                {nb_nuits}
              </span>
              {isModifDates && (
                <>
                  <br />
                  <span className={classes.red}>{new_nb_nuits}</span>
                </>
              )}
            </td>
            <td>{nb_chambres}</td>
            <td>
              {remboursable
                ? t('header.modifiable')
                : remboursable === false
                ? t('header.non_remboursable')
                : null}
            </td>
            <td>
              {total && (
                <>
                  <span className={clsx(isModifDates && classes.raye)}>
                    <Number suffix={' €'} fixedDecimalScale={2} value={total} />
                  </span>
                  {isModifDates && (
                    <>
                      <br />
                      <span className={classes.red}>
                        <Number
                          suffix={' €'}
                          fixedDecimalScale={2}
                          value={getOffreTotal({
                            ...dossier.parcours_squashed,
                            nb_nuits: new_nb_nuits,
                          })}
                        />
                      </span>
                    </>
                  )}
                </>
              )}
            </td>
            {dossier.connecte ? (
              <td className={classes.green}>{t('common:connecte')}</td>
            ) : (
              <td className={classes.red}>{t('common:deconnecte')}</td>
            )}
          </tr>
          {enfants_details && enfants_details.length != 0 && (
            <tr>
              <td />
              <td>
                <ul className={classes.enfants}>
                  {enfants_details.map(e => (
                    <li>
                      {t('header.age', { count: e.age })} -{' '}
                      {t(`header.${e.literie}`)}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {ERROR_STATUTS.includes(current.statut) && (
        <div className={classes.statut}>
          {t(`header.statut.${current.statut}`)}
          {dossier.statut.indexOf('R') === 0 && (
            <>
              <br />
              {penaliteApplicable.applicable
                ? t(`header.penalite`, { penalite })
                : t(`header.pas-penalite`)}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
