import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';
import { xor } from 'lodash';

import * as Yup from 'yup';

import feathers from 'services/feathers';

import etablissementSchema from 'commons/schemas/etablissement';

import Button from 'commons/components/button';
import Form from 'commons/components/form';
import Field from 'commons/components/field';
import { notify } from 'commons/components/notifier';

import CHAMBRES_SERVICES from 'commons/constants/chambres_services';

const useStyles = makeStyles(theme => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.space.tiny,

    '& strong': {
      flex: 1,
    },
    '& label': {
      display: 'inline',
    },
    '& input': {
      width: 'auto',
      margin: theme.space.tiny,
    },
  },

  choice: {
    display: 'inline',
    marginLeft: theme.space.small,
  },

  chambres: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: theme.space.medium,
    marginRight: theme.space.medium,

    '& label': {
      display: 'inline',
      marginLeft: theme.space.small,
    },
    '& input': {
      width: 'auto',
      margin: theme.space.tiny,
    },
  },

  notice: {
    marginTop: theme.space.small,
    marginBottom: theme.space.tiny,
    color: theme.color.primary,
    fontStyle: 'italic',
  },
  spacer: {
    paddingBottom: theme.space.small,
  },
}));

const ServicesForm = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;
  const classes = useStyles();

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'chambres_services')}
      initialValues={etablissement.chambres_services}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers.service('etablissements').patch(null, {
            action: 'chambres_services',
            ...values,
          });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue, errors }) => (
        <Form styled showDirty>
          <h2>{t('chambres.form.equipements')}</h2>
          <div>
            {CHAMBRES_SERVICES.map(e => (
              <>
                <div key={e}>
                  {e === 'douche' && (
                    <div className={classes.notice}>
                      {t('chambres.form.equipements_notice.douche')}
                    </div>
                  )}
                  <div className={classes.field}>
                    <strong>{t(`shared:chambres_services.${e}`)}</strong>
                    <div className={classes.choice}>
                      <label htmlFor={`${e}-all`}>
                        {t('chambres.services.all')}
                      </label>
                      <Field
                        id={`${e}-all`}
                        type="radio"
                        name={e}
                        value="all"
                      />
                    </div>
                    <div className={classes.choice}>
                      <label htmlFor={`${e}-none`}>
                        {t('chambres.services.none')}
                      </label>
                      <Field
                        id={`${e}-none`}
                        type="radio"
                        name={e}
                        value="none"
                      />
                    </div>
                    <div className={classes.choice}>
                      <label htmlFor={`${e}-select`}>
                        {t('chambres.services.select')}
                      </label>
                      <Field
                        id={`${e}-select`}
                        type="radio"
                        name={e}
                        checked={!['all', 'none'].includes(values[e])}
                      />
                    </div>
                  </div>

                  {!['all', 'none'].includes(values[e]) && (
                    <div className={classes.chambres}>
                      {etablissement.chambres.map(({ nom }, i) => (
                        <div key={nom}>
                          <label htmlFor={`${e}-${i}`}>{nom}</label>
                          <Field
                            type="checkbox"
                            id={`${e}-${i}`}
                            checked={values[e].includes(i)}
                            onChange={() =>
                              setFieldValue(e, xor(values[e], [i]))
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {['douche_baignoire'].includes(e) && (
                  <div className={classes.spacer} />
                )}
              </>
            ))}
          </div>

          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(ServicesForm);
