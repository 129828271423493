import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18n';
import dayjs from 'dayjs';

import etablissementSchema from 'commons/schemas/etablissement';
import Field from 'commons/components/field';
import Button from 'commons/components/button';
import Form from 'commons/components/form';
import { notify } from 'commons/components/notifier';

const useStyles = makeStyles(theme => ({
  choiceField: {
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      display: 'flex',
      marginLeft: theme.space.medium,
      alignItems: 'center',
      '& input': {
        width: '3rem',
      },
      '& label': {
        margin: `${theme.space.tiny} 0  !important`,
      },
    },
  },
}));

const InfosForm = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();
  const { etablissement } = store;

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'infos')}
      initialValues={{
        ...etablissement.infos,
        check_in: etablissement.infos.check_in
          ? dayjs(etablissement.infos.check_in, 'HH:mm').toDate()
          : null,
        check_out: etablissement.infos.check_out
          ? dayjs(etablissement.infos.check_out, 'HH:mm').toDate()
          : null,
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers.service('etablissements').patch(null, {
            action: 'infos',
            ...values,
            check_in: values.check_in
              ? dayjs(values.check_in).format('HH:mm')
              : null,
            check_out: values.check_out
              ? dayjs(values.check_out).format('HH:mm')
              : null,
          });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Form styled showDirty>
          <h2>{t('infos.title')}</h2>
          <label htmlFor="check_in">{t('infos.check_in')}</label>
          <Field.Date
            name="check_in"
            showTimeSelect
            showTimeSelectOnly
            locale={i18n.language}
          />
          <label htmlFor="check_out">{t('infos.check_out')}</label>
          <Field.Date
            name="check_out"
            showTimeSelect
            showTimeSelectOnly
            locale={i18n.language}
          />
          <label>{t('infos.heure_arrivee_necessaire.label')}</label>
          <div className={classes.choiceField}>
            <div>
              <Field
                id="heure_arrivee_necessaire-oui"
                type="radio"
                name="heure_arrivee_necessaire"
                value="oui"
              />
              <label htmlFor="heure_arrivee_necessaire-oui">
                {t('shared:infos.heure_arrivee_necessaire.oui')}
              </label>
            </div>
            <div>
              <Field
                id="heure_arrivee_necessaire-non"
                type="radio"
                name="heure_arrivee_necessaire"
                value="non"
              />
              <label htmlFor="heure_arrivee_necessaire-non">
                {t('shared:infos.heure_arrivee_necessaire.non')}
              </label>
            </div>
          </div>
          <label>{t('infos.enfants.label')}</label>
          <div className={classes.choiceField}>
            <div>
              <Field
                id="enfants-bienvenus"
                type="radio"
                name="enfants"
                value="bienvenus"
              />
              <label htmlFor="enfants-bienvenus">
                {t('shared:infos.enfants.bienvenus')}
              </label>
            </div>
            <div>
              <Field
                id="enfants-sans-enfant"
                type="radio"
                name="enfants"
                value="sans-enfant"
              />
              <label htmlFor="enfants-sans-enfant">
                {t('shared:infos.enfants.sans-enfant')}
              </label>
            </div>
          </div>
          <label>{t('infos.animaux.label')}</label>
          <div className={classes.choiceField}>
            <div>
              <Field
                id="animaux-interdits"
                type="radio"
                name="animaux"
                value="interdits"
              />
              <label htmlFor="animaux-interdits">
                {t('shared:infos.animaux.interdits')}
              </label>
            </div>
            <div>
              <Field
                id="animaux-autorises"
                type="radio"
                name="animaux"
                value="autorises"
              />
              <label htmlFor="animaux-autorises">
                {t('shared:infos.animaux.autorises')}
              </label>
            </div>
          </div>
          <label>{t('infos.stationnement.label')}</label>
          <div className={classes.choiceField}>
            {[
              'parking-prive-gratuit-reservation',
              'parking-prive-gratuit',
              'parking-prive-payant-reservation',
              'parking-prive-payant',
              'parking-public',
              'stationnement-rue',
            ].map(e => (
              <div>
                <Field
                  id={`stationnement-${e}`}
                  type="radio"
                  name="stationnement"
                  value={e}
                />
                <label htmlFor={`stationnement-${e}`}>
                  {t(`shared:infos.stationnement.${e}`)}
                </label>
              </div>
            ))}
          </div>
          <label>{t('infos.fetes.label')}</label>
          <div className={classes.choiceField}>
            <div>
              <Field
                id="fetes-autorisees"
                type="radio"
                name="fetes"
                value="autorisees"
              />
              <label htmlFor="fetes-autorisees">
                {t('shared:infos.fetes.autorisees')}
              </label>
            </div>
            <div>
              <Field
                id="fetes-interdites"
                type="radio"
                name="fetes"
                value="interdites"
              />
              <label htmlFor="fetes-interdites">
                {t('shared:infos.fetes.interdites')}
              </label>
            </div>
          </div>
          <label htmlFor="ouverture">{t('infos.ouverture.label')}</label>
          <Field id="ouverture" name="ouverture" />
          <label>{t('infos.depot_garantie.label')}</label>
          <div className={classes.choiceField}>
            <div>
              <Field
                id="depot_garantie-oui"
                type="radio"
                name="depot_garantie"
                value="oui"
              />
              <label htmlFor="depot_garantie-oui">
                {t('shared:infos.depot_garantie.oui')}
              </label>
            </div>
            <div>
              <Field
                id="depot_garantie-non"
                type="radio"
                name="depot_garantie"
                value="non"
              />
              <label htmlFor="depot_garantie-non">
                {t('shared:infos.depot_garantie.non')}
              </label>
            </div>
          </div>
          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(InfosForm);
