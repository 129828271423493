import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import feathers from 'services/feathers';

import Button from 'commons/components/button';
import Modal from 'commons/components/modal';

import getOffreTotalHT from 'commons/utils/getOffreTotalHT';
import getPenalite from 'commons/utils/getPenalite';

import OffreForm from '../../offreForm';

import Block from './block';

const useStyles = makeStyles(theme => ({
  offreForm: {
    display: 'flex',
  },
  left: {
    minWidth: '15rem',
    alignSelf: 'flex-end',
    marginBottom: '4rem',
  },
}));

const Confirmation = ({ dossier }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();

  const { tarif, tarifs_ids, etablissement } = dossier.parcours_squashed;

  const totalHT = getOffreTotalHT({
    ...dossier.parcours_squashed,
    nb_nuits: dossier.nb_nuits,
  });

  const penalite = getPenalite({
    tarif: etablissement.tarifs[tarifs_ids[tarif]],
    totalHT,
    nb_nuits: dossier.nb_nuits,
  });

  const remboursable = etablissement.tarifs[tarifs_ids[tarif]].remboursable;

  return (
    <>
      <Block
        instructions={
          <>
            <h3>{t('reservation.actions.confirmation.accepte.titre')}</h3>
            <p>
              {t(
                'reservation.actions.confirmation.accepte.vous-aurez-lobligation',
              )}
              <strong>
                {t('reservation.actions.confirmation.accepte.dans-la-chambre')}
              </strong>
            </p>
            <p>
              {t('reservation.actions.confirmation.accepte.en-cas-dindispo')}
            </p>
            <p>
              {remboursable
                ? t(
                    'reservation.actions.confirmation.accepte.modification-ou-non-present-remboursable',
                    {
                      penalite,
                    },
                  )
                : t(
                    'reservation.actions.confirmation.accepte.modification-ou-non-present',
                    {
                      penalite,
                    },
                  )}
              <strong>{` ${penalite} EUR `}</strong>
              {t('reservation.actions.confirmation.accepte.de-penalite')}
            </p>
            <p>
              {t(
                'reservation.actions.confirmation.accepte.en-acceptant-la-reserv',
              )}
            </p>
          </>
        }
      >
        <Button
          onClick={() => {
            if (
              window.confirm(
                t('reservation.actions.confirmation.accepte.confirm'),
              )
            ) {
              feathers
                .service('dossiers')
                .patch(dossier.id, { action: 'RESERVATION' });
            }
          }}
        >
          {t('reservation.actions.confirmation.accepte.button')}
        </Button>
      </Block>
      <Block
        instructions={
          <>
            <h3>{t('reservation.actions.confirmation.autre-offre.titre')}</h3>
            <p>
              {t(
                'reservation.actions.confirmation.autre-offre.le-client-devra',
              )}
            </p>
          </>
        }
      >
        <Modal
          maxWidth="xl"
          renderButton={open => (
            <Button onClick={open}>
              {t('reservation.actions.confirmation.autre-offre.button')}
            </Button>
          )}
          renderContent={close => (
            <div className={classes.offreForm}>
              <OffreForm
                dossier={dossier}
                renderLeft={e => <div className={classes.left}>{e}</div>}
                renderMain={e => <div className={classes.main}>{e}</div>}
              />
            </div>
          )}
        />
      </Block>
      <Block
        instructions={
          <>
            <h3>{t('reservation.actions.confirmation.refus.titre')}</h3>
            <p>{t('reservation.actions.confirmation.refus.aucun-acces')}</p>
          </>
        }
      >
        <Button
          onClick={() => {
            if (
              window.confirm(
                t('reservation.actions.confirmation.refus.confirm'),
              )
            ) {
              feathers
                .service('dossiers')
                .patch(dossier.id, { action: 'C_COMPLET' });
            }
          }}
        >
          {t('reservation.actions.confirmation.refus.button')}
        </Button>
      </Block>
    </>
  );
};

export default Confirmation;
