module.exports = [
  'salle_de_bains_privative',
  'douche',
  'baignoire',
  'douche_baignoire',
  'wifi_gratuit',
  'climatisation',
  'grande_fenetre',
  'petite_fenetre',
  'balcon',
  'terrasse',
  'pas_fenetre',
  'vue_ville',
  'vue_cour',
  'vue_jardin',
  'vue_mer',
  'vue_lac_riviere',
  'vue_piscine',
  'vue_montagne',
  'aucune_vue',
  'chambre_non_fumeur',
  'chauffage',
  'coin_salon',
  'tv',
  'telephone',
  'ipad',
  'armoire',
  'dressing',
  'coffre_fort',
  'bureau',
  'mini_bar',
  'cheminee',
  'mobilier_exterieur',
  'sauna',
  'jacuzzi',
  'bidet',
  'bouilloire',
  'machine_a_cafe',
  'micro_ondes',
  'refrigerateur',
  'grille_pain',
  'cuisine_equipee',
  'lave_vaisselle',
  'lave_linge',
  'seche_linge',
  'ventilateur',
  'smartphone',
  'fer_a_repasser',
  'seche_cheveux',
  'parapluie',
  'choix_doreiller',
  'grand_lit_double',
  'lits_jumeaux',
  'lit_bebe_sur_demande',
];
