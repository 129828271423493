import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';

import feathers from 'services/feathers';

import Button from 'commons/components/button';
import Form from 'commons/components/form';
import Field from 'commons/components/field';
import { notify } from 'commons/components/notifier';

import etablissementSchema from 'commons/schemas/etablissement';
import MOYENS_PAIEMENT from 'commons/constants/moyens_paiement';

const useStyles = makeStyles(theme => ({
  options: {
    columns: 2,

    '& span': {
      display: 'flex',
      alignItems: 'center',

      '& label': {
        display: 'inline',
        margin: `${theme.space.tiny} !important`,
        flex: 1,
      },
      '& input': {
        width: 'auto',
      },
    },
  },
}));

const MoyensPaiement = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const { etablissement } = store;
  const classes = useStyles();

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'moyens_paiement')}
      initialValues={etablissement.moyens_paiement}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'moyens_paiement', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form styled showDirty>
          <h2>{t('finance.moyens_paiement')}</h2>

          <div className={classes.options}>
            {MOYENS_PAIEMENT.map(e => (
              <span key={e}>
                <Field type="checkbox" name={e} />
                <label htmlFor={e}>{t(`shared:moyens_paiement.${e}`)}</label>
              </span>
            ))}
          </div>

          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(MoyensPaiement);
