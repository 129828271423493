import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import { makeStyles } from '@material-ui/core/styles';

import Form from 'commons/components/form';
import Field from 'commons/components/field';
import Button from 'commons/components/button';
import { notify } from 'commons/components/notifier';

import chambreSchema from 'commons/schemas/chambre';
import chambresLabels from 'commons/constants/chambres_labels';

import Photo from 'components/photo';

import feathers from 'services/feathers';

import LiteriesField, { newLiterie } from './literiesField';

const useStyles = makeStyles(theme => ({
  button: { display: 'block' },
  notice: {
    color: theme.color.grey.dark,
    marginTop: theme.space.tiny,
    marginLeft: theme.space.tiny,
  },
}));

const ChambreForm = ({ store, onSuccess = () => null, chambreIndex }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();

  const etablissement = store.etablissement;

  return (
    <Form.Formik
      validationSchema={chambreSchema}
      initialValues={
        etablissement.chambres[chambreIndex] || {
          nom: '',
          quantite: null,
          superficie: null,
          capacite: null,
          photo: '',
          photo_sdb: '',
          autres_photos: [],
          literies: [newLiterie()],
        }
      }
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers.service('etablissements').patch(null, {
            action: 'chambres',
            array: etablissement.chambres[chambreIndex]
              ? {
                  action: 'edit',
                  data: values,
                  index: chambreIndex,
                }
              : {
                  action: 'push',
                  data: values,
                },
          });

          resetForm();
          onSuccess();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form styled showDirty={chambreIndex != null}>
          <Field name="nom" label={t('chambres.form.nom')} />
          <Field label={t('chambres.form.label')} as="select" name="label">
            <option value={null}>{t('common:choisir')}</option>
            {chambresLabels.map(e => (
              <option value={e}>{t(`shared:chambres_labels.${e}`)}</option>
            ))}
          </Field>
          <label htmlFor="quantite">{t('chambres.form.quantite')}</label>
          <Field.Number suffix={t('chambres.chambres')} name="quantite" />
          <label htmlFor="superficie">{t('chambres.form.superficie')}</label>
          <Field.Number suffix=" m²" name="superficie" />
          <label htmlFor="capacite">{t('chambres.form.capacite')}</label>
          <Field.Number suffix={t('chambres.personnes')} name="capacite" />
          <label htmlFor="capacite">{t('chambres.form.photo')}</label>
          <p className={classes.notice}>{t('chambres.form.photo_notice')}</p>
          <Photo.Field name="photo" />
          <label htmlFor="capacite">{t('chambres.form.autres_photos')}</label>
          <p className={classes.notice}>
            {t('chambres.form.autres_photos_notice')}
          </p>
          {values.autres_photos.length < 4 && (
            <Photo.Field name="autres_photos" array />
          )}
          <Photo.Gallery
            photos={values.autres_photos}
            allowDelete
            onDelete={index => {
              const nextValue = values.autres_photos.slice();
              nextValue.splice(index, 1);
              setFieldValue('autres_photos', nextValue);
            }}
          />
          <label htmlFor="capacite">{t('chambres.form.photo_sdb')}</label>
          <p className={classes.notice}>
            {t('chambres.form.photo_sdb_notice')}
          </p>
          <Photo.Field name="photo_sdb" />
          <LiteriesField name="literies" />
          <label>{t('chambres.form.lit_bebe_disponible')}</label>
          <Field type="checkbox" name="lit_bebe_disponible" />
          <label>{t('chambres.form.lit_appoint_disponible')}</label>
          <Field type="checkbox" name="lit_appoint_disponible" />
          <Button
            type="submit"
            loading={isSubmitting}
            className={classes.button}
          >
            {t('common:valider')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(ChambreForm);
