import React from 'react';
import Field from 'commons/components/field';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { useFormikContext } from 'formik';

import Table from 'components/table';

const useStyles = makeStyles(theme => ({
  table: {
    marginLeft: 'auto',
  },
}));

const SecondTable = ({ dossier }) => {
  const { t } = useTranslation('dossier');
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <Table className={classes.table}>
      <thead>
        <tr>
          <th style={{ width: '8rem' }}>{t('offre.second-table.lit_bebe')}</th>
          <th style={{ width: '8rem' }}>
            {t('offre.second-table.lit_appoint')}
          </th>
          <th style={{ width: '8rem' }}>
            {t('offre.second-table.taxe_sejour')}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: '8rem' }}>
            <Field.Currency
              disabled={values.chambres
                .filter(c => c.quantite)
                .every(c => c.lit_bebe == 'NON_PROPOSE')}
              name="prix_lit_bebe"
            />
          </td>
          <td style={{ width: '8rem' }}>
            <Field.Currency
              name="prix_lit_appoint"
              disabled={values.chambres
                .filter(c => c.quantite)
                .every(c => c.lit_appoint == 'NON_PROPOSE')}
            />
          </td>
          <td style={{ width: '8rem' }}>
            <Field.Currency name="taxe_sejour" />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default SecondTable;
