import React from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@material-ui/core';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    color: ({ disabled }) =>
      disabled ? theme.color.grey.medium : theme.color.white,
    background:
      theme.app === 'extranet' ? theme.color.dark : theme.color.primary,
    textTransform: 'uppercase',
    display: 'inline-block',
    minHeight: '2rem',
    cursor: 'pointer',
    padding: '1rem 1.5rem',
    border: 0,

    '& hover': {
      color: theme.color.yellow.dark,
    },
  },
  loading: {
    padding: '0.76rem 1.5rem',
  },
  progress: {
    color: theme.color.white,
  },
}));

const defaultTheme = createMuiTheme();

/**
 * Enabled props
 * @property {bool} [link] - use <a> with <Button> styles
 */
const Button = ({ children, className, link, loading, disabled, ...props }) => {
  const classes = useStyles({ disabled });

  const Tag = link ? 'a' : 'button';

  return (
    <Tag
      {...props}
      disabled={loading || disabled}
      className={clsx(classes.button, loading && classes.loading, className)}
    >
      {loading ? (
        <ThemeProvider theme={defaultTheme}>
          <CircularProgress
            classes={{ colorPrimary: classes.progress }}
            size="1.2rem"
          />
        </ThemeProvider>
      ) : (
        children
      )}
    </Tag>
  );
};

export default Button;
