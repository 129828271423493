import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';
import * as Yup from 'yup';

import etablissementSchema from 'commons/schemas/etablissement';

import Field from 'commons/components/field';
import Button from 'commons/components/button';
import Form from 'commons/components/form';
import { notify } from 'commons/components/notifier';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.color.red.dark,
  },
}));

const DescriptionForm = ({ store }) => {
  const { t } = useTranslation('etablissement');
  const classes = useStyles();
  const { etablissement } = store;

  return (
    <Form.Formik
      validationSchema={Yup.reach(etablissementSchema, 'description')}
      initialValues={etablissement.description}
      validateOnChange={true}
      onSubmit={async (values, { resetForm }) => {
        try {
          await feathers
            .service('etablissements')
            .patch(null, { action: 'description', ...values });

          resetForm();
          notify({
            message: t('common:infos-succes'),
            variant: 'success',
          });
        } catch (e) {
          console.error(e);
          window.alert(t('common:probleme'));
        }
      }}
    >
      {({ isSubmitting, errors, values }) => (
        <Form styled showDirty>
          <h2>{t('presentation.description.title')}</h2>
          {Object.keys(errors).length ? (
            <p className={classes.error}>
              {t('presentation.description.error')}
            </p>
          ) : null}
          <label htmlFor="email">{t('presentation.description.fr')}</label>
          <Field name="fr" />
          <label htmlFor="email">{t('presentation.description.en')}</label>
          <Field name="en" />

          <Button type="submit" loading={isSubmitting}>
            {t('common:sauvegarder')}
          </Button>
        </Form>
      )}
    </Form.Formik>
  );
};

export default compose(inject('store'), observer)(DescriptionForm);
