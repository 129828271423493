const getPenalite = ({ tarif, totalHT, nb_nuits }) => {
  if (tarif.penalite === 'PREMIERE_NUIT') {
    return totalHT / nb_nuits;
  }

  if (tarif.penalite === 'POURCENTAGE') {
    return (totalHT * tarif.penalite_pourcentage) / 100;
  }

  return null;
};

module.exports = getPenalite;
