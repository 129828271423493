import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  wrapper: {
    '& ul': {
      listStyle: 'inside',
    },
    '& ol': {
      listStyleType: 'decimal',
    },
    '& li': {
      marginTop: theme.space.tiny,
      marginBottom: theme.space.tiny,
    },

    '& br': {
      marginBottom: theme.space.tiny,
    },

    '& a': {
      color: theme.color.primary,
    },

    '& em': {
      color: theme.color.primary,
    },
  },
  icon: {
    display: 'inline',
    height: '1rem',

    '& svg': {
      height: '1rem',
      position: 'relative',
      top: '0.25rem',
    },
  },
  green: {
    '& svg': {
      fill: theme.color.green.dark,
    },
  },
  button: {
    margin: 'auto',
    marginTop: theme.space.small,
    display: 'block',
  },
}));
