import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import Field from '../../../commons/components/field';
import fr from 'date-fns/locale/fr';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('fr', fr);

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
  },
}));

const DatePickerField = ({
  name,
  onChange = () => null,
  showTimeSelectOnly,
  className,
  label,
  ...props
}) => {
  const { values, setFieldValue, errors, handleChange } = useFormikContext();
  const classes = useStyles();

  const dateFormat = showTimeSelectOnly
    ? { en: 'h:mm aa', fr: 'HH:mm' }[props.locale]
    : {
        en: 'MM/dd/yyyy',
        fr: 'dd/MM/yyyy',
      }[props.locale];

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <DatePicker
        wrapperClassName={clsx(className, classes.input)}
        name={name}
        popperPlacement="bottom"
        customInput={<Field />}
        selected={
          typeof values[name] === 'string'
            ? new Date(values[name])
            : values[name] || null
        }
        showTimeSelectOnly={showTimeSelectOnly}
        onChange={val => {
          setFieldValue(name, new Date(val));
          onChange(new Date(val));
        }}
        dateFormat={dateFormat}
        {...props}
      />
    </>
  );
};

export default DatePickerField;
