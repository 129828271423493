import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Cards from 'react-credit-cards';

import 'react-credit-cards/es/styles-compiled.css';

import Form from 'commons/components/form';
import Field from 'commons/components/field';
import Modal from 'commons/components/modal';
import Button from 'commons/components/button';

import feathers from 'services/feathers';

const useStyles = makeStyles(theme => ({
  form: {
    '& form': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& input': {
      width: 'auto',
      margin: theme.space.small,
    },
  },
  flip: {
    display: 'block',
    margin: `${theme.space.small} auto 0 auto`,
  },
}));

const ConsulterCarte = ({ dossier, className }) => {
  const [coords, setCoords] = useState(null);
  const [flip, setFlip] = useState(false);
  const classes = useStyles();

  const { t } = useTranslation('dossier');

  useEffect(() => {
    let id;
    if (coords) {
      id = window.setTimeout(() => {
        setCoords(null);
        setFlip(false);
      }, 60 * 1000);
    }
    return () => {
      window.clearTimeout(id);
    };
  }, [coords]);

  return (
    <Modal
      maxWidth="xl"
      onClose={e => {
        setCoords(null);
        setFlip(false);
      }}
      renderButton={open => (
        <Button onClick={open} className={className}>
          {t('reservation.actions.reservation.consulter-carte.button')}
        </Button>
      )}
      renderContent={close => {
        if (coords) {
          return (
            <div>
              <Cards
                cvc={coords.cvc}
                expiry={coords.expiration.split(' ').join('')}
                focused={flip ? 'cvc' : ''}
                name={coords.nom}
                number={coords.numero}
              />
              <button onClick={() => setFlip(!flip)} className={classes.flip}>
                {t('reservation.actions.reservation.consulter-carte.flip')}
              </button>
            </div>
          );
        }

        return (
          <div>
            <Form.Formik
              initialValues={{ password: '' }}
              onSubmit={async values => {
                try {
                  const { coords } = await feathers
                    .service('coords_bancaires')
                    .get(dossier.coord_bancaires_id, { query: values });
                  setCoords(coords);
                } catch (e) {
                  console.error(e);
                  window.alert(
                    t('reservation.actions.reservation.consulter-carte.error'),
                  );
                }
              }}
            >
              {({ values, errors, isSubmitting }) => (
                <div className={classes.form}>
                  <Form>
                    <Field
                      label={t(
                        'reservation.actions.reservation.consulter-carte.password',
                      )}
                      name="password"
                      type="password"
                    />
                    <Button type="submit" loading={isSubmitting}>
                      {t(
                        'reservation.actions.reservation.consulter-carte.button',
                      )}
                    </Button>
                  </Form>
                </div>
              )}
            </Form.Formik>
          </div>
        );
      }}
    />
  );
};

export default ConsulterCarte;
