import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import i18n from 'i18n';
import Button from 'commons/components/button';
import feathers from 'services/feathers';

const Contrats = ({ store }) => {
  const { t } = useTranslation('etablissement');

  return (
    <>
      <div>
        <h2>{t('contrats.conditions_generales')}</h2>
        <a download href={`/docs/cgu_${i18n.language}.pdf`}>
          <Button>{t('contrats.telecharger')}</Button>
        </a>
      </div>
      <div>
        <h2>{t('contrats.contrat')}</h2>
        <a download href={`/docs/contrat_${i18n.language}.pdf`}>
          <Button>{t('contrats.telecharger')}</Button>
        </a>
      </div>
      <div>
        <Button
          disabled={store.etablissement.contrats}
          onClick={() =>
            feathers.service('etablissements').patch(null, {
              action: 'contrats',
            })
          }
        >
          {store.etablissement.contrats
            ? t('contrats.accepted')
            : t('contrats.button')}
        </Button>
      </div>
    </>
  );
};

export default compose(inject('store'), observer)(Contrats);
