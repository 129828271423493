const theme = app => ({
  app,
  color: {
    dark: '#375D9C',
    primary: '#4577CE',
    blue: {
      light: '#D9E1F1',
      medium: '#d9e1f2',
      dark: '#B4C6E7',
    },
    yellow: {
      light: '#FFF2CC',
      medium: '#FFC000',
      dark: '#FFC000',
    },
    orange: '#ff8400',
    red: {
      light: '#FF0000',
      dark: '#c00000',
    },
    green: {
      lighter: '#6FDC22',
      light: '#5ca32c',
      dark: '#548235',
    },
    white: 'white',
    black: 'black',
    grey: {
      light: '#ededed',
      medium: '#BFBFBF',
      dark: '#808080',
      darker: '#333',
    },
  },
  content: {
    maxWidth: 1400,
    margin: 'auto',
    padding: '0 3rem',

    '@media only screen and (max-width: 1400px)': {
      padding: '0 1.5rem',
    },
  },
  font: {
    weight: {
      normal: {
        fontWeight: 500,
      },
      bold: {
        fontWeight: 700,
      },
    },
    size: {
      tiny: {
        fontSize: '0.6rem',
      },
      small: {
        fontSize: '0.8rem',
      },
      medium: {
        fontSize: '0.9rem',
      },
      large: {
        fontSize: '1.05rem',
      },
      big: {
        fontSize: '1.2rem',
      },
      huge: {
        fontSize: '2rem',
      },
    },
  },
  space: {
    tiny: '0.5rem',
    small: '1rem',
    medium: '2rem',
    // big: '1.2rem',
  },
  radius: {
    1: {
      borderRadius: '7px',
    },
  },
  shadow: {
    1: {
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
  },
  error: {
    boxShadow: '0 1px 3px rgba(255,0,0,0.12), 0 1px 2px rgba(255,0,0,0.24)',
    border: `2px solid red !important`,
  },
  crossed: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 99,
      background:
        'linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), red 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%),linear-gradient(to top right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) calc(50% - 0.8px),red 50%,rgba(0,0,0,0) calc(50% + 0.8px),rgba(0,0,0,0) 100%)',
    },
  },
  paper: {
    sm: '12.5rem',
    md: '16rem',
    lg: '22rem',
  },
  break: {
    values: {
      xs: 320,
      sm: 600,
      md: 900,
      ml: 1200,
      lg: 1400,
      xl: 1920,
    },
    xs: e => ({
      '@media only screen and (max-width: 320px)': e,
    }),
    sm: e => ({
      '@media only screen and (max-width: 600px)': e,
    }),
    md: e => ({
      '@media only screen and (max-width: 960px)': e,
    }),
    ml: e => ({
      '@media only screen and (max-width: 1200px)': e,
    }),
    lg: e => ({
      '@media only screen and (max-width: 1400px)': e,
    }),
    xl: e => ({
      '@media only screen and (max-width: 1920px)': e,
    }),
  },
  scrollbars: {
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      height: 8,
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      'border-radius': 5,
      'background-color': 'rgba(0, 0, 0, .5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
});

module.exports = theme;
