import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import Dossiers from './dossiers';
import Planning from './planning';
import LastEvaluations from './lastEvaluations';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  message: {
    color: theme.color.green.dark,
    height: '2rem',
    fontStyle: 'italic',
    textAlign: 'center',
    ...theme.font.size.medium,
    marginBottom: theme.space.medium,
  },
  messageConnecte: {
    marginBottom: theme.space.medium,

    '& p': {
      margin: 0,
      marginBottom: theme.space.small,
      color: theme.color.green.dark,
      ...theme.font.weight.bold,
    },
  },
  dossiers: {},
  arrivees: { flex: 1, textAlign: 'center' },
}));

const Accueil = () => {
  const classes = useStyles();
  const { t } = useTranslation('accueil');

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.dossiers}>
          <div className={classes.message}>{t('messages.demandes')}</div>
          <Dossiers />
          <LastEvaluations />
        </div>
        <div className={classes.arrivees}>
          <div className={classes.messageConnecte}>
            <p>{t('messages.restez')}</p>
            <p>{t('messages.connecte')}</p>
          </div>
          <Planning />
        </div>
      </div>
    </>
  );
};

export default Accueil;
