import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@global': {
    html: {
      boxSizing: 'border-box',
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    body: {
      'font-family': 'Arial, sans-serif',
      ...theme.font.size.small,
      minWidth: 360,

      '& > *': {
        whiteSpace: 'pre-line',
      },
    },
    'h1, h2, h3': {
      margin: 0,
    },
    h1: {},
    h2: {},
    'ul, ol': {
      paddingLeft: 0,
      margin: 0,
      textAlign: 'left',
      display: 'inline-block',
    },
    ul: {
      listStyle: 'none',
    },
    ol: {
      listStylePosition: 'inside',
    },
    a: {
      textDecoration: 'none',
      color: 'inherit',
      cursor: 'pointer',
    },
    fieldset: {
      padding: 0,
      margin: 0,
      border: 0,
    },
    'input, textarea': {
      padding: 0,
    },

    // Arrondis tous les boutons
    button: {
      ...theme.radius[1],
    },

    // Fix un bug entre le zIndex des modals et celui du adresseField
    '.pac-container': {
      zIndex: 10000,
    },

    '@media print': {
      body: { zoom: 0.6 },
    },
  },
}));

const Globals = () => {
  useStyles();
  return null;
};

export default Globals;
