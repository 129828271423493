import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'recompose';
import feathers from 'services/feathers';

const AdminBar = ({ store }) => {
  const [etablissements, setEtablissements] = useState(null);
  useEffect(() => {
    feathers
      .service('etablissements')
      .find({
        query: {
          $select: ['id', 'nom'],
        },
      })
      .then(setEtablissements);
  }, []);

  if (!etablissements) {
    return null;
  }

  return (
    <select
      value={store.user.etablissement_id}
      onChange={async e => {
        await feathers.service('users').patch(store.user.id, {
          action: 'admin',
          etablissement_id: e.target.value,
        });
        window.location.reload();
      }}
    >
      <option value={null} disabled={store.user.etablissement_id}>
        Sélectionnez un établissement
      </option>
      {etablissements.map(({ nom, id }) => (
        <option value={id}>{nom}</option>
      ))}
    </select>
  );
};

export default compose(inject('store'), observer)(AdminBar);
