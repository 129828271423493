import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import getPenaliteApplicable from 'commons/utils/getPenaliteApplicable';

import Coordonnees from './coordonnees';
import Conditions from './conditions';
import Actions from './actions';

import MessagerieBlock from './messagerieBlock';

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderTop: `1px solid ${theme.color.blue.dark}`,
    width: '100%',
    display: 'flex',
    padding: theme.space.tiny,
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  column: {
    width: `calc(18rem - 2 * ${theme.space.tiny})`,
    flexShrink: 0,
  },
  columnLarge: {
    width: `calc(28rem - 2 * ${theme.space.tiny})`,
  },
  actions: {
    width: `calc(18rem - 2 * ${theme.space.tiny})`,
  },
  recap: {
    marginBottom: theme.space.tiny,
  },
}));

const Reservation = ({ dossier }) => {
  const classes = useStyles();

  const { tarif, tarifs_ids, etablissement } = dossier.parcours_squashed;
  const penaliteApplicable = getPenaliteApplicable({
    dossier,
    tarif: etablissement.tarifs[tarifs_ids[tarif]],
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.column}>
        {dossier.statut.indexOf('R_ANNULEE') === 0 &&
        !penaliteApplicable.applicable ? null : (
          <Coordonnees
            className={clsx(classes.column, classes.recap)}
            dossier={dossier}
          />
        )}
        {dossier.statut === 'CONFIRMATION' && (
          <MessagerieBlock dossier={dossier} />
        )}
      </div>

      <div className={classes.columnLarge}>
        <Conditions dossier={dossier} />
      </div>
      <div className={classes.actions}>
        {dossier.statut !== 'CONFIRMATION' && (
          <MessagerieBlock dossier={dossier} />
        )}

        <Actions dossier={dossier} />
      </div>
    </div>
  );
};

export default Reservation;
