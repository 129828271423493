import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'commons/components/modal';

import useStyles from './useStyles';

const Popup3 = () => {
  const classes = useStyles();
  const { t } = useTranslation('etablissement');

  return (
    <Modal
      initial={true}
      renderContent={() => (
        <div className={classes.wrapper}>
          <Trans i18nKey="etablissement:popups.3">{t('popups.3')}</Trans>
        </div>
      )}
    />
  );
};

export default Popup3;
