import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import Commissions from './commissions';
import { useTranslation } from 'react-i18next';

const getParisDatetime = () =>
  dayjs(new Date().toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& h2': {
      margin: `${theme.space.small} 0`,
      textTransform: 'uppercase',
    },
  },
}));

const Horeme = () => {
  const [date, setDate] = useState(getParisDatetime().toDate());
  const classes = useStyles();
  const { t } = useTranslation('horeme');

  return (
    <>
      <div className={classes.label}>
        {t('pour_toute_question')} :{' '}
        <a href="mailto:hotel@horeme.com">hotel@horeme.com</a>
      </div>
      <div className={classes.wrapper}>
        <DatePicker
          selected={date}
          onChange={setDate}
          dateFormat="MM/yyyy"
          showMonthYearPicker
        />
        <h2>{dayjs(date).format('MMMM YYYY')}</h2>
        <Commissions date={date} />
      </div>
    </>
  );
};

export default Horeme;
